.hero-banner {
    padding: 60px 0;
    overflow: hidden;

    &--column {
        max-width: 33.75rem;
        margin: 0 auto;
    }

    &--container {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        margin-right: auto;
        margin-left: auto;
    }

    &--title {
        font-weight: 400;
        font-size: 45px;
        line-height: 54px;
    }

    &--subheading {
        font-family: $font-family;
        font-weight: 300;
        font-size: 25px;
        line-height: 44px;
    }

    &--button {
        max-width: 250px;
    }

    &--img {
        aspect-ratio: 1.167;
    }

    @include media-breakpoint-down(md) {
        &--image {
            padding: 0;

            img {
                width: 100%;
            }
        }
    }

    @include media-breakpoint-up(md) {
        min-height: 432px;

        &--column {
            max-width: unset;
        }

        &--container {
            max-width: 45rem;
        }

        &--title {
            font-size: 55px;
            line-height: 66px;
        }

        &--subheading {
            font-size: 23px;
            line-height: 35px;
        }

        &--content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    @include media-breakpoint-up(lg) {
        &--container {
            max-width: 60rem;
        }
    }

    @include media-breakpoint-up(xl) {
        &--container {
            max-width: 75rem;
        }
    }
}
.openhouse .hero-banner--button {
    color: #fff !important;
    min-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}