$card-tag-height: 32px;

.home-carousel {
    overflow: hidden;
    padding: 1.75rem 0;

    .container {
        padding: 0;
    }

    img {
        aspect-ratio: 1.167;
    }

    .item-content div {
        padding-left: 1.25rem;
    }

    &--blur {
        display: none;
    }

    &--cta {
        width: 1000px;
        padding: 0;
        left: -50%;
        top: 0;
        z-index: 0;
        position: absolute;
        height: 1000px;
        background: map-get($colours, grey);

        &__subtext {
            font-weight: 500;
            line-height: 24px;
        }

        &__text {
            p {
                margin: 0;
            }
        }

        &__inner {
            z-index: 1;
            position: relative;
            background: map-get($colours, purple);
            border-radius: 10px;

            &-bg {
                top: 0;
                left: -50%;
                width: 1000%;
                height: 100%;
                z-index: -1;
            }

            .h1 {
                margin: 0;
            }
        }
    }

    &--carousel {
        & > div {
            height: 100%;

            & > div {
                height: 100%;

                &:first-of-type {
                    .home-carousel--carousel__item {
                        margin-left: 0;
                    }
                }
            }
        }

        &__item {
            margin-right: 27px;
            direction: ltr !important;
            transition: all .2s;

            &-text {
                line-height: 26px;
            }

            .card-header {
                margin-bottom: 18px;
            }

            &:hover {
                transform: scale(1.015);
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding: 3.75rem 0;

        min-height: 550px;

        .container {
            padding-right: 1.25rem;
            padding-left: 1.25rem;
        }

        .item-content {
            margin-top: auto;
            margin-bottom: auto !important;
            padding-right: 0;
            padding-left: 0;
        }

        &--carousel {
            overflow: visible !important;
            height: 100%;
            max-width: 45rem;

            &__container {
                position: relative;
            }
        }

        &--blur {
            display: block;
            opacity: 0.5;
            position: absolute;
            right: 100%;
            // the additional height and negative top accounts for scale effect on hover - stops cards going outside of blur
            height: calc(100% + 44px);
            top: -40px;
            width: 100%;
            background: map-get($colours, grey);
            filter: blur(2px);
            pointer-events: none;
        }

        &--title {
            font-size: 48px;
            line-height: 64px;
        }

        &--cta {
            top: -$card-tag-height;
            left: unset;
            background: map-get($colours, grey);

            &__inner {
                max-width: 275px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &--carousel {
            max-width: 60rem;
        }
    }

    @include media-breakpoint-up(xl) {
        &--carousel {
            max-width: 75rem;
        }
    }
}


.carousel-indicators {
    bottom: -40px;

    li {
        background-color: lightgray;
    }

    li.active {
        background-color: darkgrey;
    }
}