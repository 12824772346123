.order-grid {
    &__item {
        padding: 20px 0;
        border-top: 1px solid map-get($colours, grey4);
        display: flex;
        flex: 0 0 100%;
        justify-content: space-between;
        flex-wrap: wrap;

        &:last-child {
            border-bottom: 1px solid map-get($colours, grey4);
        }
    }

    &__content {
        flex: 0 0 100%;

        h2, .h2 {
            margin-bottom: .8rem;
        }

        .copy {
            width: 40%;
        }
    }

    &__cta {
        display: flex;
        align-items: center;
        flex: 0 0 100%;
    }

    @include media-breakpoint-up(md) {
        &__content {
            flex: 0 0 60%;
        }

        &__cta {
            justify-content: flex-end;
            flex: 0 0 40%;
        }
    }
}
