img {
    max-width: 100%;
    object-fit: cover;

    &.animate {
        -webkit-animation-duration: 1.2s;
        animation-duration: 1.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;

        &-fade-right {
            -webkit-animation-name: fadeInRight;
            animation-name: fadeInRight;
        }
    }
}

.media-block {
    &--embed {
        iframe {
            width: 100%;
        }
    }
}

.image-and-text {
    iframe {
        width: 100%;
        height: 100%;
    }
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(320px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(320px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}