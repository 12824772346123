.form {
    &-field {
        display: block;
        padding: 12px;
        border: none;
        font-family: $font-family;
        font-size: 18px;
        color: map-get($colours, black);
        background-color: map-get($colours, white);
        border: 1px solid map-get($colours, grey3);
        border-radius: 8px;
        position: relative;

        &--selectwrapper {
            padding: 0;
            border: 0;
        }

        &:disabled {
            opacity: 0.8;
            background: #E7E7E7;
        }

        &-label {
            font-size: 18px;
            display: block;
            margin-bottom: .5rem;

            &--small {
                font-size: 16px;
                font-weight: 300;
            }
        }

        &__dropdown {
            position: relative;
            display: block;

            input.form-field {
                &:hover {
                    cursor: pointer;
                }
            }

            &-items {
                display: none;
                position: absolute;
                top: 100%;
                width: 100%;
                background: map-get($colours, white);
                z-index: 10;
                border: 1px solid map-get($colours, grey3);
                border-top-color: white;
                overflow: hidden scroll;
                max-height: 250px;
            }

            &-arrow {
                background: map-get($colours, grey5);
                position: absolute;
                right: 1px;
                width: 49px;
                height: calc(100% - 2px);
                top: 1px;
                transition: all .2s;
                display: flex;
                align-items: center;
                justify-content: center;
                pointer-events: none;
                right: 0.05rem;

                &-wrap {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    border-radius: .7rem;
                    pointer-events: none;
                }

                svg {
                    color: map-get($colours, grey3);
                    fill: map-get($colours, grey3);
                }
            }

            &-item {
                padding: 0.75rem;
                display: block;
                color: map-get($colours, black);
                transition: 0s;
                font-size: 18px;
                font-family: $font-family;

                &:hover {
                    background: map-get($colours, purple);
                    color: map-get($colours, white);
                }
            }

            &.open {
                input.form-field {
                    border-bottom: 1px solid map-get($colours, white);
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            &.open &-items {
                display: block;
            }

            &.open &-arrow {
                background: map-get($colours, white);

                svg {
                    transform: rotateZ(180deg);
                }
            }
        }
    }

    &--dark &-field {
        background-color: white;
    }

    &-subfields {
        border-left: 4px solid #D8D8D8;
    }

    input:not(:checked) ~ &-subfields {
        display: none;
    }

    &__checkbox {
        display: flex;
        //margin: 1rem 0;
        align-items: center;
        position: relative;

        &--tooltip {
            display: flex;

            &:hover {
                cursor: pointer;
            }

            &-popup {
                background: map-get($colours, white);
                color: map-get($colours, black);
                padding: 20px;
                display: none;
                position: absolute;
                left: 0;
                bottom: calc(100% + 20px);
                border-radius: 10px;
                box-shadow: 0px 5px 25px rgba(212, 217, 232, 0.7);
                max-width: 100%;
                width: 500px;
                z-index: 1;
            }

            svg:active + &-popup, svg:hover + &-popup, svg.active + &-popup, svg:focus + &-popup {
                display: block;
            }
        }
    }

    &__checkbox input {
        @include visually-hidden;
    }

    &__checkbox input:checked ~ label:before {
        content: url('/assets/images/tick.svg');
        background-color: map-get($colours, white);
        border: 1px solid map-get($colours, black);
    }

    &__checkbox label {
        display: inline-block;
        position: relative;
        padding-left: calc(24px + 1rem);
        font-size: map-get($font-sizes, small);
        color: $black;
        cursor: pointer;
        line-height: 24px;
    }

    &__checkbox--white label {
        color: $white;
    }

    &__checkbox label:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        color: $black;
        background-color: $white;
        height: 24px;
        width: 24px;
        border: 1px solid map-get($colours, black);
        border-radius: 5px;
        text-align: center;
    }

    &-date {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        label {
            flex: 0 0 100%;
        }

        .form-field {
            text-align: center;
            flex: 0 0 calc(33% - 5px);
        }

        @include media-breakpoint-up(lg) {
            justify-content: flex-start;

            .form-field {
                max-width: 80px;
                margin-right: 5px;
            }
        }
    }

    &-address-locator {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        label {
            flex: 0 0 100%;
        }

        .form-field, .button {
            flex: 0 0 48%
        }

        @include media-breakpoint-down(xs) {
            .button {
                padding: .25rem .5rem;
                font-size: 14px;
            }
        }
    }

    &-explanatory-text {
        font-size: 16px;
        line-height: 20px;
        display: block;
        margin-top: 10px;
    }

    &-password {
        position: relative;

        input[type=text] + &-icon {
            color: map-get($colours, purple2);
            fill: map-get($colours, purple2);
        }

        &-icon {
            position: absolute;
            height: 100%;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 0;
            top: 0;
            color: map-get($colours, grey4);
            fill: map-get($colours, grey4);

            svg {
                width: 30px;
                height: 30px;
                pointer-events: none;
            }
        }
    }

    &__contact {
        .form-field {
            &-label {
                font-size: 16px;
            }
        }
    }
}

input[type="text"],
input[type="email"],
select,
textarea,
input[type="number"]{
    -moz-appearance: none;
	-webkit-appearance: none;
    appearance: none;
    outline: none;

    &.form-field {
        width: 100%;
    }
}

.field-validation-error {
    display: inline-block;
    margin-top: 0.5rem;
    color: red;
}

.button {
    cursor: pointer;
}

.umbraco-forms {
    &-tooltip {
        color: map-get($colours, grey3);
        font-size: 12px;
        line-height: 16px;
    }
}