﻿/* Generated from 
        <link rel="preload" as="style" href="https://fonts.googleapis.com/css?family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
    with https://github.com/mmastrac/webfont-dl
*/

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 200;
    font-display: optional;
    src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2");
}

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    font-display: optional;
    src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2");
}

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-display: optional;
    src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2");
}

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-display: optional;
    src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2");
}

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-display: optional;
    src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2");
}

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-display: optional;
    src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2");
}

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-display: optional;
    src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
    font-display: optional;
    src: url("../fonts/poppins-ad4ef68574931a304dc66ed1ed3952c063c055f7.woff2") format("woff2"), url("../fonts/poppins-307f6ed4defdf3d88da08de7f76322fbfda9f138.woff2") format("woff2"), url("../fonts/poppins-13907723256082238f2d94d469319d85910f0859.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    font-display: optional;
    src: url("../fonts/poppins-ca3f44e6d7eff6e1b1c03118cddf4b67fc97feca.woff2") format("woff2"), url("../fonts/poppins-e279ef0a72ea7780a182c82393e38a1c80fbedf3.woff2") format("woff2"), url("../fonts/poppins-130e001df902031f2e1352b069815c5e84296e46.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    font-display: optional;
    src: url("../fonts/poppins-9d73d108a7aeea80874d6e5ef8580b428ed49e39.woff2") format("woff2"), url("../fonts/poppins-e5a1f40cc721b852d2593f73ac13e4a8a8d280e2.woff2") format("woff2"), url("../fonts/poppins-a3e7876a3bb8b3a8c38bc8dbef51b1140b51b38d.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-display: optional;
    src: url("../fonts/poppins-42adbc6609a01ab61b2f5966e52bb4e33522de7d.woff2") format("woff2"), url("../fonts/poppins-79d61f8cf8377508651416ce8dded1352ebf8455.woff2") format("woff2"), url("../fonts/poppins-d70e2c70b21cbb66cd883ae56e2dedacefd81c7c.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-display: optional;
    src: url("../fonts/poppins-51566f1960f205c162068a8454e55c410d64cd6f.woff2") format("woff2"), url("../fonts/poppins-febfaaae13418ab3dbe80f87da1bda65ef6aa70c.woff2") format("woff2"), url("../fonts/poppins-a99a6d584385f86f84e893330f6ae158372b5d63.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-display: optional;
    src: url("../fonts/poppins-d5e36bc91f851a67069bd0f90b4e54f94a826ba4.woff2") format("woff2"), url("../fonts/poppins-254ef26022774b1bf1b66441ee1ce8a0592be083.woff2") format("woff2"), url("../fonts/poppins-85397079f9f963cd2f16dc5600d8d7795924d73d.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    font-display: optional;
    src: url("../fonts/poppins-a3f82fffffb29023e6ae4d7b8ac26308af08e4eb.woff2") format("woff2"), url("../fonts/poppins-96ca2860f9562438d854a87d209e3b1e6311e9bd.woff2") format("woff2"), url("../fonts/poppins-50900cbabf4ae9e1e174162f091404e343585c65.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    font-display: optional;
    src: url("../fonts/poppins-103144bcab154e8945ecdaded9d33103093d8189.woff2") format("woff2"), url("../fonts/poppins-c085bc6f04239daafd68ff54dae35ff41265a4e2.woff2") format("woff2"), url("../fonts/poppins-6d7575264299324b44d58aed8236eeb99d5b6671.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    font-display: optional;
    src: url("../fonts/poppins-ff7147fc01d58d9da1e6e55730a8e0af54213da0.woff2") format("woff2"), url("../fonts/poppins-23ab8ae99ff82b7c05c1980e0e0c9b79913300b4.woff2") format("woff2"), url("../fonts/poppins-47c920cbd187e62be2402c00da8aa2c9884dfae9.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    font-display: optional;
    src: url("../fonts/poppins-cf71855ab65a3ec625455d4c245cb2e68dd695cc.woff2") format("woff2"), url("../fonts/poppins-068da06cb4da2b139043e8d3222c77c041d3244e.woff2") format("woff2"), url("../fonts/poppins-9bb181e467942bd18582fd1aa001431ac2d781f5.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-display: optional;
    src: url("../fonts/poppins-4dea2e59f584bda246e5a72894d21ded8577e5e9.woff2") format("woff2"), url("../fonts/poppins-71a6a9d0f9f8a7a4e39ec96d6600ea540703c8aa.woff2") format("woff2"), url("../fonts/poppins-2c5f99afe358a3e8570818a99646779aaa607587.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: optional;
    src: url("../fonts/poppins-95b16d2eaeef6c91f39e23dad559d286139c40c6.woff2") format("woff2"), url("../fonts/poppins-71aeaf66680e4e7d5ffed53bf78eba3d7decca70.woff2") format("woff2"), url("../fonts/poppins-a9e9bafe64666f4595051a0e895b47a5fa39e67e.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: optional;
    src: url("../fonts/poppins-41e56550cc8f19a7f65c16041658eedbf888cc98.woff2") format("woff2"), url("../fonts/poppins-65af2a037a5ef8a8d383d518377ea1f9f6837631.woff2") format("woff2"), url("../fonts/poppins-ac6d71b4d5fdd2b3dabc9a06ff6c001e4251da0b.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: optional;
    src: url("../fonts/poppins-491f7b3336cda454a52a310ce89970aa634af004.woff2") format("woff2"), url("../fonts/poppins-334853f61ceb1fb096818740cc62d5840fbbae46.woff2") format("woff2"), url("../fonts/poppins-0da2d17e738f46d2a09e6fb7969da451719a9820.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: optional;
    src: url("../fonts/poppins-09c9cfa499178fa61dd06c4d679730702cd0134f.woff2") format("woff2"), url("../fonts/poppins-3a4cf2dc2cabd33b5b2c7cef3221131520f618a0.woff2") format("woff2"), url("../fonts/poppins-179f97ec0275f09603a8db94d4380eb584d81cd5.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: optional;
    src: url("../fonts/poppins-f189f54e850a173531b4227572193dc151cf0694.woff2") format("woff2"), url("../fonts/poppins-72c3ac29b2fcceea390d3a51c7a892efde65e4d9.woff2") format("woff2"), url("../fonts/poppins-cb726212d5d525021752a1d8470a0fb593e0c49e.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-display: optional;
    src: url("../fonts/poppins-9616b9d5e233128d8a7507d07f191b4c946120eb.woff2") format("woff2"), url("../fonts/poppins-858a2b9dc3a53a334b066eed777536ad4ca0b69f.woff2") format("woff2"), url("../fonts/poppins-6d11d743bc3cfb169d70bc86450f18351dc1a905.woff2") format("woff2");
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: optional;
    src: url("../fonts/poppins-a3c7906fb565efd19f130aa948156b8c4b73d8ea.woff2") format("woff2"), url("../fonts/poppins-a7c17e2b7c20fee418d5611b988066f9e986b186.woff2") format("woff2"), url("../fonts/poppins-ec0cbbcb4600e691cb24a63451f758727f90a306.woff2") format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    font-display: optional;
    src: url("../fonts/roboto-3245ae77dfbc7ce7883b7aa74c7d7bd4fb3b759d.woff2") format("woff2"), url("../fonts/roboto-d8ffd6c3f7391d483e8e8f3a3ced69b35d7aeec6.woff2") format("woff2"), url("../fonts/roboto-3318e951845205b2ec4d6800d8104aff2d86dde0.woff2") format("woff2"), url("../fonts/roboto-7151f2c26dada82151aa985b8ef3f0367684ea12.woff2") format("woff2"), url("../fonts/roboto-bca69e826168b2f896d3c10c7ded30acb3b28385.woff2") format("woff2"), url("../fonts/roboto-f953a0140b948f07f720632126be5dde787dc4d0.woff2") format("woff2"), url("../fonts/roboto-e34a4b9b4081c9a2ff232d5c110309d40fff74a0.woff2") format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    font-display: optional;
    src: url("../fonts/roboto-587dcbaed846d35331c29d471d590f0f1a2f4c51.woff2") format("woff2"), url("../fonts/roboto-2e2a3bfdaaf856780a7018bedf879867267df120.woff2") format("woff2"), url("../fonts/roboto-ac867c6e75ba0a969747477f7ab2f34f85bc786e.woff2") format("woff2"), url("../fonts/roboto-bb8d2e03806fa3620d277a70ae24da44df018322.woff2") format("woff2"), url("../fonts/roboto-ce49123d786b0f72fb90695f091dee251fc5a6e5.woff2") format("woff2"), url("../fonts/roboto-bd477bffd5842531d2281f9f14195aab24a598f2.woff2") format("woff2"), url("../fonts/roboto-a35afbb7b7b2c5152c4032f8b2373f47056dfd06.woff2") format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-display: optional;
    src: url("../fonts/roboto-5bdccf3db53d2b0a3f661dd77a5b2665918e0b7f.woff2") format("woff2"), url("../fonts/roboto-adea83ea580e66c52ac8f0286204bb1e7ed65175.woff2") format("woff2"), url("../fonts/roboto-3e27ac8981b1ecb36512e8b57fd841c2371e925f.woff2") format("woff2"), url("../fonts/roboto-256a8e72074e17baa995c018fb583a822b6f802c.woff2") format("woff2"), url("../fonts/roboto-489fdde5a148ef137d203171033d2db73812555d.woff2") format("woff2"), url("../fonts/roboto-b650dec86ded3af19e0c6509844e6f8e5dc4ab6b.woff2") format("woff2"), url("../fonts/roboto-af097f3155ad953db0254a2da254a41c09fff18d.woff2") format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    font-display: optional;
    src: url("../fonts/roboto-769ccc3e4a7f3f416ed7dd16d50addb5a0b45719.woff2") format("woff2"), url("../fonts/roboto-363bdd04e0b99e7b3f476fd139ef4b1327e8dee4.woff2") format("woff2"), url("../fonts/roboto-f3391f881ca9ceb6c1e62c7249ea38165439a748.woff2") format("woff2"), url("../fonts/roboto-e5b978a1710dc1e87462f9852cdbcb811ca8a395.woff2") format("woff2"), url("../fonts/roboto-3efc04de0b4d866be500a258065dbd8970e853f5.woff2") format("woff2"), url("../fonts/roboto-a0715173094f3bd702a2c40dee512cf0c68ebd04.woff2") format("woff2"), url("../fonts/roboto-50fed1f6b3de963d870ff4de8a2021ddee021a3a.woff2") format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    font-display: optional;
    src: url("../fonts/roboto-4183be331b5c8aba86a481f28e95d7b25b249209.woff2") format("woff2"), url("../fonts/roboto-633ea130d48236241a7badb4d0d31bc3f7324717.woff2") format("woff2"), url("../fonts/roboto-b4ecaaf0186fc692abd46dc3bcbb9dbe6ffbce70.woff2") format("woff2"), url("../fonts/roboto-02d951f59dc3d5ffd24ff0102d7f865727f47c9b.woff2") format("woff2"), url("../fonts/roboto-979ff8d5a7bbd2bb2904a975128e1dc6d2e0477c.woff2") format("woff2"), url("../fonts/roboto-45f40446f2334c071d2a1881b5c759ac659f281a.woff2") format("woff2"), url("../fonts/roboto-5487e623781da7b713f9a572ccd59dd7c49695f0.woff2") format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    font-display: optional;
    src: url("../fonts/roboto-913f38f8fe55711764194563974c8a2d900f1817.woff2") format("woff2"), url("../fonts/roboto-e1afc8253ba75dcc4ff40b9a6cc33234bd8fac88.woff2") format("woff2"), url("../fonts/roboto-fbce0043dab61dcb9600e9fc79cc5820f5d92094.woff2") format("woff2"), url("../fonts/roboto-144d5aeaf71f50949e6b49f4c695c8f151306e7c.woff2") format("woff2"), url("../fonts/roboto-528ba5b57e3d3b46f4e99dbeb52fe5c1ba7e3d98.woff2") format("woff2"), url("../fonts/roboto-a20a1cec08c6ebcb75889997701d2a1f8cc7a1a8.woff2") format("woff2"), url("../fonts/roboto-5145adafcd5e4b32841cfaa8d66c3d03c434d56d.woff2") format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: optional;
    src: url("../fonts/roboto-621035e11f7d7c480cfd8b7b79fddc9c5260e099.woff2") format("woff2"), url("../fonts/roboto-5e8350b9ddf0ed027cb34ec50718069e4994bb48.woff2") format("woff2"), url("../fonts/roboto-043b448bfb5a0ed2ca04b19bd7670660aa5d6f09.woff2") format("woff2"), url("../fonts/roboto-431195d7035760b002b00b47c618ae642827ec05.woff2") format("woff2"), url("../fonts/roboto-c2376de049f99838a3b455de58057996e0b94a8d.woff2") format("woff2"), url("../fonts/roboto-87198ca28741832168aff633ba34857667bf06e5.woff2") format("woff2"), url("../fonts/roboto-37ac52a84595c236122c73c37ca226837ddab9ea.woff2") format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: optional;
    src: url("../fonts/roboto-6ab00672c03f4e72fe34d59d14e0f102c89672aa.woff2") format("woff2"), url("../fonts/roboto-46eb7c8d2f3d5f3d471209ea0a30c5c80f28a365.woff2") format("woff2"), url("../fonts/roboto-05155805a63fb3bcb21028318d00587c5f150b4e.woff2") format("woff2"), url("../fonts/roboto-3054bf92a43dd94647e3d5fd0ff2ff6061a459b8.woff2") format("woff2"), url("../fonts/roboto-e628016d3f7789c6243afbae2c37082879f818a4.woff2") format("woff2"), url("../fonts/roboto-77675b59ec683e2e08834b390ec0cd287895a565.woff2") format("woff2"), url("../fonts/roboto-34f751a1b1a0c1c0b5264b99f490e689db939657.woff2") format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: optional;
    src: url("../fonts/roboto-cdbb48c38232d91f1188e705d0fac22f9819a874.woff2") format("woff2"), url("../fonts/roboto-6047396fdc25a0a49feb905f9d0bd01b8dc3ec77.woff2") format("woff2"), url("../fonts/roboto-13433fe24f42e4924db9c37dee985c821fe6d9d6.woff2") format("woff2"), url("../fonts/roboto-3a1e2980c382f4c071cb7d0fb8739b59c8113f73.woff2") format("woff2"), url("../fonts/roboto-081d22a7a51d4c026f526d8fb0d876de25eff5b4.woff2") format("woff2"), url("../fonts/roboto-4b4af153e8473e16663ae7f2e01635bf58979e2e.woff2") format("woff2"), url("../fonts/roboto-106ac454ba4e503e0a1cd15e1275130918049182.woff2") format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: optional;
    src: url("../fonts/roboto-b2dbc2933af280a58e3ddd492d35c2f4282749cd.woff2") format("woff2"), url("../fonts/roboto-3fc82ed7cfee5ff1ee9fcdf2fa4c099cfb909fbf.woff2") format("woff2"), url("../fonts/roboto-df70c1e8efe17d32de0f0dde5fec1ceb91482fae.woff2") format("woff2"), url("../fonts/roboto-a1467ca362e26bf72be79ac9c7fbe268cb321386.woff2") format("woff2"), url("../fonts/roboto-f2a4b910f3e1aff3919efd5077e88e43c5175455.woff2") format("woff2"), url("../fonts/roboto-49b74c9b5810671ba1213050bc801f56c598a8f5.woff2") format("woff2"), url("../fonts/roboto-d836c7d4bc52bcd67626b8960ae030ad315c2507.woff2") format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: optional;
    src: url("../fonts/roboto-a0c07347d6463ef7ee80a69224541848d7aca71c.woff2") format("woff2"), url("../fonts/roboto-60f0e2acff4e2d29f88204afaa61019bd3962ca6.woff2") format("woff2"), url("../fonts/roboto-9cd9fb9ceb0bd40bce7f9ccfe90e145dfb93df7e.woff2") format("woff2"), url("../fonts/roboto-3ff863937fd4fa5466d1c88cce2b2d96958070db.woff2") format("woff2"), url("../fonts/roboto-c962167c4a3ac8ab2a83f7c591b0ac0910bc5a83.woff2") format("woff2"), url("../fonts/roboto-9c9c45cbcf9baa9ab6b4c4c743dd5d968eab5ea2.woff2") format("woff2"), url("../fonts/roboto-c706e0a4122ab727645b744c21667390e8898a4d.woff2") format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-display: optional;
    src: url("../fonts/roboto-5d17e50297ac891ac41fd87526f7ff11c5b99a89.woff2") format("woff2"), url("../fonts/roboto-d041edb3d253caf2f7892a4644d0ea8e40d01c6e.woff2") format("woff2"), url("../fonts/roboto-d7f0e58ad1e803af33efd9aa8f6ed187b9f5cc3b.woff2") format("woff2"), url("../fonts/roboto-3d75bcc3a9d8c55395ac57cb8a7727db3643c97c.woff2") format("woff2"), url("../fonts/roboto-df792bb0898c3f638762406bc777b42014af6725.woff2") format("woff2"), url("../fonts/roboto-c9699e784698564ea105fd62d3f854da500aeb2a.woff2") format("woff2"), url("../fonts/roboto-f4a1bb63ac600302e23e8585eb00b6c99f232ed5.woff2") format("woff2");
}
