// reset
@import "normalize-scss/sass/normalize";
@import "reset";

// grid system (Bootstrap 4)
@import "grid";
@import "bootstrap-4-grid/scss/grid";

// Bootstrap 4
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/vendor/_rfs";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/_carousel";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_transitions";
@import "bootstrap/scss/_navbar";
@import "bootstrap/scss/_button-group";
@import "bootstrap/scss/_dropdown.scss";
@import "bootstrap/scss/_reboot.scss";
@import "bootstrap/scss/_root.scss";
@import "bootstrap/scss/_list-group.scss";

/*@import "bootstrap/scss/_nav";*/
/*@import "bootstrap/scss/_alert.scss";*/
/*@import "bootstrap/scss/_badge.scss";*/
/*@import "bootstrap/scss/_breadcrumb.scss";*/
/*@import "bootstrap/scss/_buttons.scss";*/
/*@import "bootstrap/scss/_card.scss";*/
/*@import "bootstrap/scss/_close.scss";*/
/*@import "bootstrap/scss/_code.scss";*/
/*@import "bootstrap/scss/_custom-forms.scss";*/
/*@import "bootstrap/scss/_forms.scss";*/
/*@import "bootstrap/scss/_functions.scss";*/
/*@import "bootstrap/scss/_grid.scss";*/
/*@import "bootstrap/scss/_images.scss";*/
/*@import "bootstrap/scss/_input-group.scss";*/
/*@import "bootstrap/scss/_jumbotron.scss";*/
/*@import "bootstrap/scss/_media.scss";*/
/*@import "bootstrap/scss/_mixins.scss";*/
/*@import "bootstrap/scss/_modal.scss";*/
/*@import "bootstrap/scss/_pagination.scss";*/
/*@import "bootstrap/scss/_popover.scss";*/
/*@import "bootstrap/scss/_print.scss";*/
/*@import "bootstrap/scss/_progress.scss";*/
/*@import "bootstrap/scss/_spinners.scss";*/
/*@import "bootstrap/scss/_tables.scss";*/
/*@import "bootstrap/scss/_toasts.scss";*/
/*@import "bootstrap/scss/_tooltip.scss";*/
/*@import "bootstrap/scss/_type.scss";*/
/*@import "bootstrap/scss/_utilities.scss";*/


// override bootstrap's default of position: relative; as this stops us being able to use position: absolute;
[class*="col"] {
	position: static;
}

// base and global styles
@import "mixins";
@import "style-guide/colours/colours";
@import "style-guide/typography/typography";
@import "style-guide/icons/icons";
@import "utilities";
@import "print";

html {
	height: 100%;

	&:not(.smooth-scroll-disabled) {
		scroll-behavior: smooth;
	}
}

body {
	position: relative;
	height: 100%;
	width: 100%;
	margin: 0;

	&.has-overlay {
		overflow: hidden;
	}
}

.popover-active {
  overflow-y: hidden;
  position: fixed;
  height: 100vh;
  padding-right: 15px; // scrollbar
}

.grecaptcha-badge{
	visibility:hidden;
	display:none;
}

// components
@import "D:/a/1/s/Frontend/components/partials/_images/image.scss";
@import "D:/a/1/s/Frontend/components/partials/account-subnav/account-subnav.scss";
@import "D:/a/1/s/Frontend/components/partials/basket-block/basket-block.scss";
@import "D:/a/1/s/Frontend/components/partials/basket-popup/basket-popup.scss";
@import "D:/a/1/s/Frontend/components/partials/bolton-sidebar/bolton-sidebar.scss";
@import "D:/a/1/s/Frontend/components/partials/card/card.scss";
@import "D:/a/1/s/Frontend/components/partials/checkout-steps/checkout-steps.scss";
@import "D:/a/1/s/Frontend/components/partials/flash-message/flash-message.scss";
@import "D:/a/1/s/Frontend/components/partials/homepage-carousel/homepage-carousel.scss";
@import "D:/a/1/s/Frontend/components/partials/hyperlink/hyperlink.scss";
@import "D:/a/1/s/Frontend/components/partials/logo/logo.scss";
@import "D:/a/1/s/Frontend/components/partials/order-status-list/order-status-list.scss";
@import "D:/a/1/s/Frontend/components/partials/product-lines/product-lines.scss";
@import "D:/a/1/s/Frontend/components/partials/section-title/section-title.scss";
@import "D:/a/1/s/Frontend/components/global/fonts/google.scss";
@import "D:/a/1/s/Frontend/components/global/footer/footer.scss";
@import "D:/a/1/s/Frontend/components/global/form/form.scss";
@import "D:/a/1/s/Frontend/components/global/header/header.scss";
@import "D:/a/1/s/Frontend/components/core/accordion/accordion.scss";
@import "D:/a/1/s/Frontend/components/core/articles/ArticleNavigation.scss";
@import "D:/a/1/s/Frontend/components/core/blocks/core.scss";
@import "D:/a/1/s/Frontend/components/core/blocks/diagonal-image.scss";
@import "D:/a/1/s/Frontend/components/core/blocks/offer-banner.scss";
@import "D:/a/1/s/Frontend/components/core/blocks/page-title.scss";
@import "D:/a/1/s/Frontend/components/core/blocks/sidebyside-textandimage.scss";
@import "D:/a/1/s/Frontend/components/core/blocks/threeimage.scss";
@import "D:/a/1/s/Frontend/components/core/grid/grid.scss";
@import "D:/a/1/s/Frontend/components/core/help-card-carousel/help-card-carousel.scss";
@import "D:/a/1/s/Frontend/components/core/hero-banner/hero-banner.scss";
@import "D:/a/1/s/Frontend/components/core/hero-full/hero.scss";
@import "D:/a/1/s/Frontend/components/core/home-carousel/home-carousel.scss";
@import "D:/a/1/s/Frontend/components/core/image-and-text/image-and-text.scss";
@import "D:/a/1/s/Frontend/components/core/numbered-card-grid/numbered-card-grid.scss";
@import "D:/a/1/s/Frontend/components/core/numbered-list/numbered-list.scss";
@import "D:/a/1/s/Frontend/components/core/oh-nav/oh-nav.scss";
@import "D:/a/1/s/Frontend/components/core/oh-product-list/oh-product-list.scss";
@import "D:/a/1/s/Frontend/components/core/page-header/page-header.scss";
@import "D:/a/1/s/Frontend/components/core/product-carousel/product-carousel.scss";
@import "D:/a/1/s/Frontend/components/core/product-detail/product-detail.scss";
@import "D:/a/1/s/Frontend/components/core/product-grid/product-grid.scss";
@import "D:/a/1/s/Frontend/components/core/results-grid/results-grid.scss";
@import "D:/a/1/s/Frontend/components/core/reviews-carousel/reviews-carousel.scss";
@import "D:/a/1/s/Frontend/components/core/spinner/spinner.scss";
@import "D:/a/1/s/Frontend/components/core/tabs/tabs.scss";
@import "D:/a/1/s/Frontend/components/core/test-download/test-download.scss";
@import "D:/a/1/s/Frontend/components/core/test-image-carousel/test-image-carousel.scss";
@import "D:/a/1/s/Frontend/components/core/three-cards/three-cards.scss";
@import "D:/a/1/s/Frontend/components/core/your-results-mean/your-results-mean.scss";

// jQuery plug-ins
@import "../scss/jquery.mCustomScrollbar.min.css";
