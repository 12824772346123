.results-grid {
    .h1, h1 {
        display: flex;
        justify-content: space-between;
    }

    &__head {
        display: flex;
        flex-wrap: wrap;

        &__1 {
            flex: 0 0 100%;
            margin-bottom: 1rem;
        }

        &__2 {
            flex: 0 0 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
        }

        &__3 {
            flex: 0 0 100%;
            margin-bottom: 1rem;
        }
    }

    &__grid {
        &item {
            padding: 22px 0;
            border-top: 1px solid map-get($colours, grey4);
            display: flex;
            flex-wrap: wrap;

            &:last-child {
                border-bottom: 1px solid map-get($colours, grey4);
            }

            h3, .h3 {
                line-height: 40px;
            }

            &__1 {
                flex: 0 0 100%;
                display: flex;

                svg {
                    flex: 0 0 40px;
                    margin-right: 20px;
                }
            }

            &__2 {
                flex: 0 0 100%;
                display:flex;
                align-items:center;

                h3, .h3 {
                    font-weight: 300;
                }
            }

            &__accordion {
                display: none;
            }

            &__result {
                margin-left: 60px;
            }

            &__meaningtooltip {
                display: flex;
                align-items: center;
                font-size: 18px;
                line-height: 27px;
                font-family: $heading-font-family;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                    color: map-get($colours, black);

                    svg {
                        fill: map-get($colours, grey4);
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__head {
            &__1 {
                flex: 0 0 50%;
            }

            &__2 {
                flex: 0 0 50%;

                strong {
                    flex: 0 0 50%;
                }
            }
        }

        &__grid {
            &item {
                display: flex;
                justify-content: space-between;

                &__1 {
                    flex: 0 0 50%;
                }

                &__2 {
                    flex: 0 0 50%;
                }

                &__result {
                    margin-left: 0;
                }
            }
        }
    }
}

@media print {
    /* All your print styles go here */
    nav ul, .nav__menu, footer, .hide-on-print, .nav__opener, .nav__cart-link {
        display: none !important;
    }
    .results-grid__griditem__accordion {
        display: block !important;
    }

    .container {
        margin: 1rem;
        max-width: 100%;
    }

    section {
        padding-bottom: 1rem !important;
    }

    .results-grid {
        page-break-after: always;
    }

    .results-grid__griditem {
        page-break-inside: avoid;
    }
}
