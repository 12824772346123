﻿$font-family: 'Roboto', sans-serif;
$heading-font-family: 'Poppins', sans-serif;
$other-font-family: 'Manrope', sans-serif;


// if you update this please also update components/style-guide/typography/README.md
$font-sizes: (
  tiny: 14px,
  copy: 16px,
  copy-large:18px,
  bullets: 16px,
  hero-title:55px,
  page-title: 45px,
  h1: 31px,
  h2: 23px,
  h3:21px,
  h4:20px,
  box-header:25px,
  button: 18px,
  text-link:17px
);

$font-weights: (
  light: 300,
  normal: 400,
  bold: 500,
  bolder: 700,
  boldest: 900,
);

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint);

  @include media-breakpoint-up($breakpoint) {
    @each $fontSizeName, $fontSize in $font-sizes {
      .f#{$infix}-#{$fontSizeName} {
        font-size: $fontSize;
      }
    }

    @each $fontWeightName, $fontWeight in $font-weights {
      .f#{$infix}-#{$fontWeightName} {
        font-weight: $fontWeight;
      }
    }
  }
}

.ff-heading {
    font-family: $heading-font-family;
}

$line-heights: (base: 26px, large: 30px, bullets:24px, hero-title:75px, page-title: 61px, h1: 41px, h3: 30px, box-header: 35px, button: 30px, text-link: 32px);

html,
body {
  font-family: $font-family;
  color: map-get($colours, text-body);
}

body {
    font-size: map-get($font-sizes, copy);
}

p, .copy {
    font-size: map-get($font-sizes, copy);
    font-weight: map-get($font-weights, light);
    line-height: map-get($line-heights, large);

    a {
        font-family: $font-family;
        font-weight: map-get($font-weights, light);
    }

    &.emphasised {
        font-weight: map-get($font-weights, normal);

        a {
            font-weight: map-get($font-weights, normal);
        }
    }

    &-tiny {
        font-size: map-get($font-sizes, tiny);
    }

    img{
        &[height]{
            height:auto !important;
        }
    }
}

.copy-large {
    font-size: map-get($font-sizes, copy-large);
    font-weight: map-get($font-weights, light);
    line-height: map-get($line-heights, large);
}

.page-title {
    font-family: $heading-font-family;
    font-size: map-get($font-sizes, page-title);
    font-weight: map-get($font-weights, normal);
    line-height: map-get($line-heights, page-title);
}

.hero-title {
    font-family: $heading-font-family;
    font-size: map-get($font-sizes, hero-title);
    font-weight: map-get($font-weights, normal);
    line-height: map-get($line-heights, hero-title);
}

.card-header {
    font-family: $heading-font-family;
    font-size: map-get($font-sizes, box-header);
    font-weight: map-get($font-weights, normal);
    line-height: map-get($line-heights, box-header);
    margin-bottom:24px;
}

.subheading{
    font-size:20px;
    font-weight:400;
    line-height:23px;
}

ul{
    padding-left:1rem;
}

li {
    font-size: map-get($font-sizes, bullets);
    font-weight: map-get($font-weights, light);
    line-height: map-get($line-heights, bullets);
}

.carousel-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    color: map-get($colours, purple);
    background: map-get($colours, purple);
    margin: 0 6px;
    transition: all 0s;

    .openhouse & {
        color: map-get($colours, red);
        background: map-get($colours, red);
    }

    &--white {
        color: map-get($colours, white);
        background: map-get($colours, white);
    }

    &--purple2 {
        color: map-get($colours, purple2);
        background: map-get($colours, purple2);
    }

    &--red2 {
        color: map-get($colours, red2);
        background: map-get($colours, red2);
    }

    &.active {
        width: 14px;
        height: 14px;
        background: none;
        border: 3px solid;
    }

    &-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

ul.ticks {
    list-style-type: none;
    padding: 0;

    li {
        padding-left: 22px;
        position: relative;
        line-height: map-get($line-heights, base);

        .px-icon--bullet {
            width: 13px;
            height: 13px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

ul.extras {
    list-style-type: none;
    padding: 0;

    li {
        .form__checkbox {
            label {
                font-weight: 400;
            }
        }

        &:first-child{
            .form__checkbox{
                margin-top:0;
            }
        }
    }
}

h1,.h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-family: $heading-font-family;
    font-weight: map-get($font-weights, normal);
}

h1, .h1 {
    font-size: map-get($font-sizes, h1);
    line-height: map-get($line-heights, h1);
    margin: 0.83em 0;
}

h2, .h2 {
    font-size: map-get($font-sizes, h2);
    margin: 0.83em 0;
}

h3, .h3 {
  font-size: map-get($font-sizes, h3);
  line-height: map-get($line-heights, h3);
  margin:1em 0;
}

h4, .h4 {
    font-size:20px;
    line-height:30px;
    font-weight:500;
}

hr{
    border:none;
    border-bottom: 1px solid map-get($colours, grey4);
}

a, button {
    font-family: $heading-font-family;
    font-weight: map-get($font-weights, normal);
    color: map-get($colours, purple2);
    fill: map-get($colours, purple2);
    text-decoration: none;
    transition: all 200ms ease-in-out;

    svg {
        transition: all 200ms ease-in-out;
    }

    &.emphasised {
        font-size: 18px;
        line-height: 32px;
        font-weight: 500;
    }

    &:hover {
        color: map-get($colours, purple2);
        text-decoration: underline;

        svg {
            fill: map-get($colours, purple2);
        }
    }

    .openhouse & {
        color: map-get($colours, red2);
        fill: map-get($colours, red2);
        &:hover {
            color: map-get($colours, red2);

            svg {
                fill: map-get($colours, red2);
            }
        }
    }
}

.bold {
    font-weight: map-get($font-weights, bold);
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint);

  @include media-breakpoint-up($breakpoint) {
    .c#{$infix}-white a {
      color: map-get($colours, white);
      fill: map-get($colours, white);

      &:hover {
        color: map-get($colours, white);
        fill: map-get($colours, white);

        svg {
            color: map-get($colours, white);
            fill: map-get($colours, white);
        }
      }

      .openhouse & {
        color: map-get($colours, white);
        fill: map-get($colours, white);
  
        &:hover {
          color: map-get($colours, white);
          fill: map-get($colours, white);
  
          svg {
              color: map-get($colours, white);
              fill: map-get($colours, white);
          }
        }
      }

      &:not(.c#{$infix}-white){

        color: map-get($colours, purple);
        fill: map-get($colours, purple);

        .openhouse & {
            color: map-get($colours, red2);
            fill: map-get($colours, red2);
        }

        &:hover {
            color: map-get($colours, purple);
            fill: map-get($colours, purple);

            .openhouse & {
                color: map-get($colours, red2);
                fill: map-get($colours, red2);
            }
        }
      }
    }
  }
}

address {
  font-style: normal;
}