section.block-item.diagonalimage {
    .main-row {
        background-color: #f6f7f9;

        @media (min-width: 768px) {
            min-height: 300px;
        }

        .image-holder {
            min-height: 300px
        }
    }

    h1 {
        margin-block-start: 0;
    }

    @media (max-width: 768px) {
        h1 {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }

        h4 {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            margin-block-end: 0;
            margin-block-start: 0;
        }

        .container {
            padding: 0;
        }

        .top-text {
            order: -1;
        }

        .top-image {
            height: 300px;
        }

        .bottom-text {
        }

        .bottom-image {
            height: 300px
        }
    }
}
