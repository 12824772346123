.flash-message {
    position: absolute;
    top: 100%;
    width: 100%;
    transition: top 1s, opacity 1s;

    &.closed, &:not(.flash-message-navbar) body.nav-open & {
        opacity: 0;
        transition: opacity .4s;
        pointer-events: none;
    }

    &.closed {
        top: 0;
        transition: top 1s, opacity 1s;
    }

    &__content {
        font-weight: 300;
        font-family: $heading-font-family;
        color: map-get($colours, white) !important;
        text-align: center;
        position: relative;

        p {
            margin: 0;
        }

        a {
            font-family: $heading-font-family;
            font-weight: 300;
            text-decoration: underline;
            color: map-get($colours, white);

            &:hover {
                color: map-get($colours, white) !important;
            }
        }
    }

    &__close {
        display: flex;
        align-items: center;
        position: absolute;
        justify-content: flex-end;
        right: 1.25rem;
        top: 0;
        height: 100%;

        svg {
            margin-right: calc(calc(1.875rem - 1.25rem) / 3);
        }
    }

    &__container {
        position: relative;
    }

    @include media-breakpoint-up(md) {
        &__content {
            text-align: center;
        }
    }
}

.flash-message-navbar {
    
    @include media-breakpoint-up(md) {
        top: 40px;
    }
}
