﻿.openhouse {
    .article-arrow {
        .article-arrow-svg, .article-arrow-text {
            vertical-align: middle;
            display: inline-block;
        }
    }

    .article-arrow.prev-arrow {
        svg {
            transform: rotate(180deg)
        }
    }
}
