﻿$colours: ( 
    purple:#6C6ACD,
    purple2: #4F46B8,
    purple3: #F3F4FD,
    dark-purple:#2F3055,
    footer-purple:#2F2851,
    footer-alt:#DCDCDC,
    footer-link-rollover:#9290da,
    red: #d3002a,
    red2: #a50022,
    red3: #fff1f4,
    dark-red: #3f000d,
    footer-red: #2e0009,
    footer-link-rollover-red: #e00d37,
    black: #18191F,
    white: #FFFFFF,
    grey: #F6F7F9,
    grey3:#828282,
    grey4:#BDBDBD,
    grey5:#E0E0E0,
    result-red:#DE2E30,
    result-green:#1E8449,
    result-amber:#FAA15A,
    error-red: #d30c19,
    text-body:#222222);

.colour-block {
    display: flex;
    height: 100px;
    width: 100px;
    border: 1px solid map-get($colours, black);
    margin: 0 1rem 1rem 0;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;

    span {
        text-align: center;
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint);

    @include media-breakpoint-up($breakpoint) {
        @each $colourName, $colourHex in $colours {
            .bg#{$infix}-#{$colourName} {
                background-color: $colourHex;
            }

            .bgh#{$infix}-#{$colourName} {
                &:hover,
                &:hover > a {
                    background-color: $colourHex;
                }
            }

            .c#{$infix}-#{$colourName} {
                color: $colourHex;
                fill: $colourHex;
            }

            .ch#{$infix}-#{$colourName} {
                &:hover,
                &:hover > a {
                    color: $colourHex;
                    fill: $colourHex;
                }
            }

            .bc#{$infix}-#{$colourName} {
                border-color: $colourHex !important;
            }

            .bgimp#{$infix}-#{$colourName} {
                background-color: $colourHex !important;
            }
        }
    }
}
.openhouse {
    .c-purple {
        color: map-get($colours, red) !important;
        fill: map-get($colours, red) !important;
    }

    .c-purple2 {
        color: map-get($colours, red2) !important;
        fill: map-get($colours, red2) !important;
    }
}