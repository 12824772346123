
.bolton-sidebar {
    width: 300px;
    position: fixed;
    top: 0;
    left: -300px;
    height: 100vh;
    z-index: 999;
    background: white;
    transition: all 0.3s;
    overflow-y: hidden;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

    @include media-breakpoint-up(md) {
        width: 600px;
        left: -600px;
    }
}

.bolton-sidebar.active {
    left: 0;
}


.bolton-sidebar-right {
    width: 300px;
    position: fixed;
    top: 0;
    right: -100vw;
    height: 100vh;
    z-index: 999;
    background: white;
    transition: all 0.3s;
    overflow-y: auto;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

    @include media-breakpoint-up(md) {
        width: 600px;
    }
}

.bolton-sidebar-right.active {
    right: 0px;

    @include media-breakpoint-up(md) {
        width: 600px;
    }
}

.bolton-sidebar .dismiss, .bolton-sidebar-right .dismiss {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.bolton-sidebar .dismiss:hover, .bolton-sidebar-right .dismiss:hover {
    color: #7386D5;
}

.busy-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.busy-overlay.active {
    display: block;
    opacity: 1;
}

.bolton-sidebar .sidebar-header, .bolton-sidebar-right .sidebar-header {
    padding: 20px;
    background: #2f2851;
    color: #FFF;
    text-align: center;
}

