section.block-item.sidebysidetextandimage {
    .hero-banner {
        padding: 0;
        overflow: visible;
    }

    .hero-banner--title {
        font-size: 2.5rem;
    }

    .order-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .order-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    padding-bottom: 3.75rem;

    img{
        height:100%
    }
}
