$card-tag-height: 32px;

.oh-product-list {
    overflow: hidden;
    padding-top: 2.5rem;

    &--blur {
        display: none;
    }

    &--carousel {
        & > div {
            height: 100%;

            & > div {
                height: 100%;

                &:first-of-type {
                    .product-carousel--carousel__item {
                        margin-left: 0;
                    }
                }
            }
        }

        &__item {
            margin-right: 27px;
            direction: ltr !important;
            transition: all .2s;

            &-text {
                line-height: 26px;
            }

            .card-header {
                margin-bottom: 18px;
            }

            &:hover {
                transform: scale(1.015);
            }
        }
    }

    button {
        color: #fff;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .radio-item {
        display: inline-block;
        position: relative;
        padding: 0 6px;
        margin: 10px 0 0;
    }

    .radio-item input[type='radio'] {
        display: none;
    }

    .radio-item label {
        color: #666;
        font-weight: normal;
    }

    .radio-item label:before {
        content: " ";
        display: inline-block;
        position: relative;
        top: 5px;
        margin: 0 5px 0 0;
        width: 20px;
        height: 20px;
        border-radius: 11px;
        border: 2px solid map-get($colours, red2);
        background-color: transparent;
    }

    .radio-item input[type=radio]:checked + label:after {
        border-radius: 11px;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 9px;
        left: 10px;
        content: " ";
        display: block;
        background: map-get($colours, red2);
    }
}
