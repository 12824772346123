.image-and-text {
    padding: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
    justify-content:space-between;

    &__container .image-and-text:nth-child(even){
        flex-direction:row-reverse;
    }

    &__text, &__image {
        position: relative;
        flex: 0 0 100%;
    }

    &__image {
        img {
            width: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        &__text, &__image {
            flex: 0 0 calc(50% - 0.5rem);
        }

        &__image {
            overflow: hidden;

            img {
                width:100%;
                max-width:100%;
            }
        }
    }
}
