.reviews-carousel {
    &--carousel {
        padding:5px 0;

        & > div {
            height: 100%;

            & > div {
                height: 100%;

                &:first-of-type {
                    .product-carousel--carousel__item {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    &--nav {
        display: flex;
        justify-content: center;
        color: map-get($colours, white);
    }

    &--review {
        background: map-get($colours, white);
        padding: 35px 10px;
        text-align: center;
        position: relative;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        margin: 0 10px;
        height: calc(100% - 20px); //so the triangle isn't hidden by carousel overflow:hidde
        transition: all .2s;

        &:hover {
            transform: scale(1.015);
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 1px;
            left: 50%;
            width: 0;
            height: 0;
            border: 10px solid transparent;
            border-top-color: map-get($colours, white);
            border-bottom: 0;
            margin-left: -10px;
            margin-bottom: -10px;
            z-index: 1;
        }
    }

    @include media-breakpoint-up(md) {
        &--carousel {
            height: 100%;
        }

        &--review {
            padding: 30px 60px;
        }
    }
}
