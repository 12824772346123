.section {
    padding: 40px 0;

    &-p {
        &-b {
            padding-bottom: 40px;
        }

        &-t {
            padding-top: 40px;
        }
    }

    &--large {
        padding: 40px 0 100px;
    }

    &--larger {
        padding: 40px 0 80px;
    }

    &-title {
        font-family: $heading-font-family;
        font-size: 48px;
        line-height: 64px;
        margin: 10px 0;

        &--wrap {
            margin-bottom: 50px;
        }
    }

    &-subtitle {
        font-family: $font-family;
        font-size: 23px;
        line-height: 32px;
        font-weight: 300;
    }

    &--infection-extra {
        p:last-of-type {
            a {
                display: inline-block;
                text-decoration: none;
                font-family: Poppins,sans-serif;
                font-size: 1.125rem;
                line-height: 1.875rem;
                text-align: center;
                font-size: 18px;
                line-height: 30px;
                font-weight: 500;
                border-radius: .5rem;
                padding: .875rem 2rem;
                border: 1px solid;
                color: map-get($colours, white);
                background: map-get($colours, purple);
                border-color: map-get($colours, purple);

                &:hover {
                    text-decoration: none;
                    color: map-get($colours, white);
                    background: darken(map-get($colours, purple), 10%);

                    svg {
                        fill: map-get($colours, white);
                    }
                }
            }
        }
    }

    .bg-purple &--infection-extra {
        p:not(:last-child) {
            a {
                color: map-get($colours, black);
            }
        }
        p:last-child {
            a {
                color: map-get($colours, purple);
                background: map-get($colours, white);
                border-color: map-get($colours, white);

                &:hover {
                    text-decoration: none;
                    color: map-get($colours, purple);
                    background: darken(map-get($colours, white), 10%);
                    border-color: darken(map-get($colours, white), 10%);

                    svg {
                        fill: map-get($colours, purple);
                    }
                }
            }
        }
    }

    .bg-grey &--infection-extra {
        p:last-child {
            a {
                color: map-get($colours, grey);
                background: map-get($colours, purple);
                border-color: map-get($colours, purple);

                &:hover {
                    text-decoration: none;
                    color: map-get($colours, grey);
                    background: darken(map-get($colours, purple), 10%);
                    border-color: darken(map-get($colours, purple), 10%);

                    svg {
                        fill: map-get($colours, grey);
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding: 50px 0;

        &-title {
            &--wrap {
                margin-bottom: 70px;
            }
        }

        &--larger {
            padding: 40px 0 160px;
        }
    }
}
