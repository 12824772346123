@import url(../scss/jquery.mCustomScrollbar.min.css);
.reset-list {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.reset-button {
  padding: 0;
  border-width: 0;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
  text-align: inherit; }
  .reset-button:focus {
    outline: none; }
  .reset-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  max-width: 540px; }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }

.container {
  max-width: 540px; }

@media (min-width: 768px) {
  .container, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-md, .container-lg, .container-xl {
    max-width: 1200px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

/*@import "bootstrap/scss/_nav";*/
/*@import "bootstrap/scss/_alert.scss";*/
/*@import "bootstrap/scss/_badge.scss";*/
/*@import "bootstrap/scss/_breadcrumb.scss";*/
/*@import "bootstrap/scss/_buttons.scss";*/
/*@import "bootstrap/scss/_card.scss";*/
/*@import "bootstrap/scss/_close.scss";*/
/*@import "bootstrap/scss/_code.scss";*/
/*@import "bootstrap/scss/_custom-forms.scss";*/
/*@import "bootstrap/scss/_forms.scss";*/
/*@import "bootstrap/scss/_functions.scss";*/
/*@import "bootstrap/scss/_grid.scss";*/
/*@import "bootstrap/scss/_images.scss";*/
/*@import "bootstrap/scss/_input-group.scss";*/
/*@import "bootstrap/scss/_jumbotron.scss";*/
/*@import "bootstrap/scss/_media.scss";*/
/*@import "bootstrap/scss/_mixins.scss";*/
/*@import "bootstrap/scss/_modal.scss";*/
/*@import "bootstrap/scss/_pagination.scss";*/
/*@import "bootstrap/scss/_popover.scss";*/
/*@import "bootstrap/scss/_print.scss";*/
/*@import "bootstrap/scss/_progress.scss";*/
/*@import "bootstrap/scss/_spinners.scss";*/
/*@import "bootstrap/scss/_tables.scss";*/
/*@import "bootstrap/scss/_toasts.scss";*/
/*@import "bootstrap/scss/_tooltip.scss";*/
/*@import "bootstrap/scss/_type.scss";*/
/*@import "bootstrap/scss/_utilities.scss";*/
[class*="col"] {
  position: static; }

.colour-block {
  display: flex;
  height: 100px;
  width: 100px;
  border: 1px solid #18191F;
  margin: 0 1rem 1rem 0;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center; }
  .colour-block span {
    text-align: center; }

.bg-purple {
  background-color: #6C6ACD; }

.bgh-purple:hover,
.bgh-purple:hover > a {
  background-color: #6C6ACD; }

.c-purple {
  color: #6C6ACD;
  fill: #6C6ACD; }

.ch-purple:hover,
.ch-purple:hover > a {
  color: #6C6ACD;
  fill: #6C6ACD; }

.bc-purple {
  border-color: #6C6ACD !important; }

.bgimp-purple {
  background-color: #6C6ACD !important; }

.bg-purple2 {
  background-color: #4F46B8; }

.bgh-purple2:hover,
.bgh-purple2:hover > a {
  background-color: #4F46B8; }

.c-purple2 {
  color: #4F46B8;
  fill: #4F46B8; }

.ch-purple2:hover,
.ch-purple2:hover > a {
  color: #4F46B8;
  fill: #4F46B8; }

.bc-purple2 {
  border-color: #4F46B8 !important; }

.bgimp-purple2 {
  background-color: #4F46B8 !important; }

.bg-purple3 {
  background-color: #F3F4FD; }

.bgh-purple3:hover,
.bgh-purple3:hover > a {
  background-color: #F3F4FD; }

.c-purple3 {
  color: #F3F4FD;
  fill: #F3F4FD; }

.ch-purple3:hover,
.ch-purple3:hover > a {
  color: #F3F4FD;
  fill: #F3F4FD; }

.bc-purple3 {
  border-color: #F3F4FD !important; }

.bgimp-purple3 {
  background-color: #F3F4FD !important; }

.bg-dark-purple {
  background-color: #2F3055; }

.bgh-dark-purple:hover,
.bgh-dark-purple:hover > a {
  background-color: #2F3055; }

.c-dark-purple {
  color: #2F3055;
  fill: #2F3055; }

.ch-dark-purple:hover,
.ch-dark-purple:hover > a {
  color: #2F3055;
  fill: #2F3055; }

.bc-dark-purple {
  border-color: #2F3055 !important; }

.bgimp-dark-purple {
  background-color: #2F3055 !important; }

.bg-footer-purple {
  background-color: #2F2851; }

.bgh-footer-purple:hover,
.bgh-footer-purple:hover > a {
  background-color: #2F2851; }

.c-footer-purple {
  color: #2F2851;
  fill: #2F2851; }

.ch-footer-purple:hover,
.ch-footer-purple:hover > a {
  color: #2F2851;
  fill: #2F2851; }

.bc-footer-purple {
  border-color: #2F2851 !important; }

.bgimp-footer-purple {
  background-color: #2F2851 !important; }

.bg-footer-alt {
  background-color: #DCDCDC; }

.bgh-footer-alt:hover,
.bgh-footer-alt:hover > a {
  background-color: #DCDCDC; }

.c-footer-alt {
  color: #DCDCDC;
  fill: #DCDCDC; }

.ch-footer-alt:hover,
.ch-footer-alt:hover > a {
  color: #DCDCDC;
  fill: #DCDCDC; }

.bc-footer-alt {
  border-color: #DCDCDC !important; }

.bgimp-footer-alt {
  background-color: #DCDCDC !important; }

.bg-footer-link-rollover {
  background-color: #9290da; }

.bgh-footer-link-rollover:hover,
.bgh-footer-link-rollover:hover > a {
  background-color: #9290da; }

.c-footer-link-rollover {
  color: #9290da;
  fill: #9290da; }

.ch-footer-link-rollover:hover,
.ch-footer-link-rollover:hover > a {
  color: #9290da;
  fill: #9290da; }

.bc-footer-link-rollover {
  border-color: #9290da !important; }

.bgimp-footer-link-rollover {
  background-color: #9290da !important; }

.bg-red {
  background-color: #d3002a; }

.bgh-red:hover,
.bgh-red:hover > a {
  background-color: #d3002a; }

.c-red {
  color: #d3002a;
  fill: #d3002a; }

.ch-red:hover,
.ch-red:hover > a {
  color: #d3002a;
  fill: #d3002a; }

.bc-red {
  border-color: #d3002a !important; }

.bgimp-red {
  background-color: #d3002a !important; }

.bg-red2 {
  background-color: #a50022; }

.bgh-red2:hover,
.bgh-red2:hover > a {
  background-color: #a50022; }

.c-red2 {
  color: #a50022;
  fill: #a50022; }

.ch-red2:hover,
.ch-red2:hover > a {
  color: #a50022;
  fill: #a50022; }

.bc-red2 {
  border-color: #a50022 !important; }

.bgimp-red2 {
  background-color: #a50022 !important; }

.bg-red3 {
  background-color: #fff1f4; }

.bgh-red3:hover,
.bgh-red3:hover > a {
  background-color: #fff1f4; }

.c-red3 {
  color: #fff1f4;
  fill: #fff1f4; }

.ch-red3:hover,
.ch-red3:hover > a {
  color: #fff1f4;
  fill: #fff1f4; }

.bc-red3 {
  border-color: #fff1f4 !important; }

.bgimp-red3 {
  background-color: #fff1f4 !important; }

.bg-dark-red {
  background-color: #3f000d; }

.bgh-dark-red:hover,
.bgh-dark-red:hover > a {
  background-color: #3f000d; }

.c-dark-red {
  color: #3f000d;
  fill: #3f000d; }

.ch-dark-red:hover,
.ch-dark-red:hover > a {
  color: #3f000d;
  fill: #3f000d; }

.bc-dark-red {
  border-color: #3f000d !important; }

.bgimp-dark-red {
  background-color: #3f000d !important; }

.bg-footer-red {
  background-color: #2e0009; }

.bgh-footer-red:hover,
.bgh-footer-red:hover > a {
  background-color: #2e0009; }

.c-footer-red {
  color: #2e0009;
  fill: #2e0009; }

.ch-footer-red:hover,
.ch-footer-red:hover > a {
  color: #2e0009;
  fill: #2e0009; }

.bc-footer-red {
  border-color: #2e0009 !important; }

.bgimp-footer-red {
  background-color: #2e0009 !important; }

.bg-footer-link-rollover-red {
  background-color: #e00d37; }

.bgh-footer-link-rollover-red:hover,
.bgh-footer-link-rollover-red:hover > a {
  background-color: #e00d37; }

.c-footer-link-rollover-red {
  color: #e00d37;
  fill: #e00d37; }

.ch-footer-link-rollover-red:hover,
.ch-footer-link-rollover-red:hover > a {
  color: #e00d37;
  fill: #e00d37; }

.bc-footer-link-rollover-red {
  border-color: #e00d37 !important; }

.bgimp-footer-link-rollover-red {
  background-color: #e00d37 !important; }

.bg-black {
  background-color: #18191F; }

.bgh-black:hover,
.bgh-black:hover > a {
  background-color: #18191F; }

.c-black {
  color: #18191F;
  fill: #18191F; }

.ch-black:hover,
.ch-black:hover > a {
  color: #18191F;
  fill: #18191F; }

.bc-black {
  border-color: #18191F !important; }

.bgimp-black {
  background-color: #18191F !important; }

.bg-white {
  background-color: #FFFFFF; }

.bgh-white:hover,
.bgh-white:hover > a {
  background-color: #FFFFFF; }

.c-white {
  color: #FFFFFF;
  fill: #FFFFFF; }

.ch-white:hover,
.ch-white:hover > a {
  color: #FFFFFF;
  fill: #FFFFFF; }

.bc-white {
  border-color: #FFFFFF !important; }

.bgimp-white {
  background-color: #FFFFFF !important; }

.bg-grey {
  background-color: #F6F7F9; }

.bgh-grey:hover,
.bgh-grey:hover > a {
  background-color: #F6F7F9; }

.c-grey {
  color: #F6F7F9;
  fill: #F6F7F9; }

.ch-grey:hover,
.ch-grey:hover > a {
  color: #F6F7F9;
  fill: #F6F7F9; }

.bc-grey {
  border-color: #F6F7F9 !important; }

.bgimp-grey {
  background-color: #F6F7F9 !important; }

.bg-grey3 {
  background-color: #828282; }

.bgh-grey3:hover,
.bgh-grey3:hover > a {
  background-color: #828282; }

.c-grey3 {
  color: #828282;
  fill: #828282; }

.ch-grey3:hover,
.ch-grey3:hover > a {
  color: #828282;
  fill: #828282; }

.bc-grey3 {
  border-color: #828282 !important; }

.bgimp-grey3 {
  background-color: #828282 !important; }

.bg-grey4 {
  background-color: #BDBDBD; }

.bgh-grey4:hover,
.bgh-grey4:hover > a {
  background-color: #BDBDBD; }

.c-grey4 {
  color: #BDBDBD;
  fill: #BDBDBD; }

.ch-grey4:hover,
.ch-grey4:hover > a {
  color: #BDBDBD;
  fill: #BDBDBD; }

.bc-grey4 {
  border-color: #BDBDBD !important; }

.bgimp-grey4 {
  background-color: #BDBDBD !important; }

.bg-grey5 {
  background-color: #E0E0E0; }

.bgh-grey5:hover,
.bgh-grey5:hover > a {
  background-color: #E0E0E0; }

.c-grey5 {
  color: #E0E0E0;
  fill: #E0E0E0; }

.ch-grey5:hover,
.ch-grey5:hover > a {
  color: #E0E0E0;
  fill: #E0E0E0; }

.bc-grey5 {
  border-color: #E0E0E0 !important; }

.bgimp-grey5 {
  background-color: #E0E0E0 !important; }

.bg-result-red {
  background-color: #DE2E30; }

.bgh-result-red:hover,
.bgh-result-red:hover > a {
  background-color: #DE2E30; }

.c-result-red {
  color: #DE2E30;
  fill: #DE2E30; }

.ch-result-red:hover,
.ch-result-red:hover > a {
  color: #DE2E30;
  fill: #DE2E30; }

.bc-result-red {
  border-color: #DE2E30 !important; }

.bgimp-result-red {
  background-color: #DE2E30 !important; }

.bg-result-green {
  background-color: #1E8449; }

.bgh-result-green:hover,
.bgh-result-green:hover > a {
  background-color: #1E8449; }

.c-result-green {
  color: #1E8449;
  fill: #1E8449; }

.ch-result-green:hover,
.ch-result-green:hover > a {
  color: #1E8449;
  fill: #1E8449; }

.bc-result-green {
  border-color: #1E8449 !important; }

.bgimp-result-green {
  background-color: #1E8449 !important; }

.bg-result-amber {
  background-color: #FAA15A; }

.bgh-result-amber:hover,
.bgh-result-amber:hover > a {
  background-color: #FAA15A; }

.c-result-amber {
  color: #FAA15A;
  fill: #FAA15A; }

.ch-result-amber:hover,
.ch-result-amber:hover > a {
  color: #FAA15A;
  fill: #FAA15A; }

.bc-result-amber {
  border-color: #FAA15A !important; }

.bgimp-result-amber {
  background-color: #FAA15A !important; }

.bg-error-red {
  background-color: #d30c19; }

.bgh-error-red:hover,
.bgh-error-red:hover > a {
  background-color: #d30c19; }

.c-error-red {
  color: #d30c19;
  fill: #d30c19; }

.ch-error-red:hover,
.ch-error-red:hover > a {
  color: #d30c19;
  fill: #d30c19; }

.bc-error-red {
  border-color: #d30c19 !important; }

.bgimp-error-red {
  background-color: #d30c19 !important; }

.bg-text-body {
  background-color: #222222; }

.bgh-text-body:hover,
.bgh-text-body:hover > a {
  background-color: #222222; }

.c-text-body {
  color: #222222;
  fill: #222222; }

.ch-text-body:hover,
.ch-text-body:hover > a {
  color: #222222;
  fill: #222222; }

.bc-text-body {
  border-color: #222222 !important; }

.bgimp-text-body {
  background-color: #222222 !important; }

@media (min-width: 768px) {
  .bg-md-purple {
    background-color: #6C6ACD; }
  .bgh-md-purple:hover,
  .bgh-md-purple:hover > a {
    background-color: #6C6ACD; }
  .c-md-purple {
    color: #6C6ACD;
    fill: #6C6ACD; }
  .ch-md-purple:hover,
  .ch-md-purple:hover > a {
    color: #6C6ACD;
    fill: #6C6ACD; }
  .bc-md-purple {
    border-color: #6C6ACD !important; }
  .bgimp-md-purple {
    background-color: #6C6ACD !important; }
  .bg-md-purple2 {
    background-color: #4F46B8; }
  .bgh-md-purple2:hover,
  .bgh-md-purple2:hover > a {
    background-color: #4F46B8; }
  .c-md-purple2 {
    color: #4F46B8;
    fill: #4F46B8; }
  .ch-md-purple2:hover,
  .ch-md-purple2:hover > a {
    color: #4F46B8;
    fill: #4F46B8; }
  .bc-md-purple2 {
    border-color: #4F46B8 !important; }
  .bgimp-md-purple2 {
    background-color: #4F46B8 !important; }
  .bg-md-purple3 {
    background-color: #F3F4FD; }
  .bgh-md-purple3:hover,
  .bgh-md-purple3:hover > a {
    background-color: #F3F4FD; }
  .c-md-purple3 {
    color: #F3F4FD;
    fill: #F3F4FD; }
  .ch-md-purple3:hover,
  .ch-md-purple3:hover > a {
    color: #F3F4FD;
    fill: #F3F4FD; }
  .bc-md-purple3 {
    border-color: #F3F4FD !important; }
  .bgimp-md-purple3 {
    background-color: #F3F4FD !important; }
  .bg-md-dark-purple {
    background-color: #2F3055; }
  .bgh-md-dark-purple:hover,
  .bgh-md-dark-purple:hover > a {
    background-color: #2F3055; }
  .c-md-dark-purple {
    color: #2F3055;
    fill: #2F3055; }
  .ch-md-dark-purple:hover,
  .ch-md-dark-purple:hover > a {
    color: #2F3055;
    fill: #2F3055; }
  .bc-md-dark-purple {
    border-color: #2F3055 !important; }
  .bgimp-md-dark-purple {
    background-color: #2F3055 !important; }
  .bg-md-footer-purple {
    background-color: #2F2851; }
  .bgh-md-footer-purple:hover,
  .bgh-md-footer-purple:hover > a {
    background-color: #2F2851; }
  .c-md-footer-purple {
    color: #2F2851;
    fill: #2F2851; }
  .ch-md-footer-purple:hover,
  .ch-md-footer-purple:hover > a {
    color: #2F2851;
    fill: #2F2851; }
  .bc-md-footer-purple {
    border-color: #2F2851 !important; }
  .bgimp-md-footer-purple {
    background-color: #2F2851 !important; }
  .bg-md-footer-alt {
    background-color: #DCDCDC; }
  .bgh-md-footer-alt:hover,
  .bgh-md-footer-alt:hover > a {
    background-color: #DCDCDC; }
  .c-md-footer-alt {
    color: #DCDCDC;
    fill: #DCDCDC; }
  .ch-md-footer-alt:hover,
  .ch-md-footer-alt:hover > a {
    color: #DCDCDC;
    fill: #DCDCDC; }
  .bc-md-footer-alt {
    border-color: #DCDCDC !important; }
  .bgimp-md-footer-alt {
    background-color: #DCDCDC !important; }
  .bg-md-footer-link-rollover {
    background-color: #9290da; }
  .bgh-md-footer-link-rollover:hover,
  .bgh-md-footer-link-rollover:hover > a {
    background-color: #9290da; }
  .c-md-footer-link-rollover {
    color: #9290da;
    fill: #9290da; }
  .ch-md-footer-link-rollover:hover,
  .ch-md-footer-link-rollover:hover > a {
    color: #9290da;
    fill: #9290da; }
  .bc-md-footer-link-rollover {
    border-color: #9290da !important; }
  .bgimp-md-footer-link-rollover {
    background-color: #9290da !important; }
  .bg-md-red {
    background-color: #d3002a; }
  .bgh-md-red:hover,
  .bgh-md-red:hover > a {
    background-color: #d3002a; }
  .c-md-red {
    color: #d3002a;
    fill: #d3002a; }
  .ch-md-red:hover,
  .ch-md-red:hover > a {
    color: #d3002a;
    fill: #d3002a; }
  .bc-md-red {
    border-color: #d3002a !important; }
  .bgimp-md-red {
    background-color: #d3002a !important; }
  .bg-md-red2 {
    background-color: #a50022; }
  .bgh-md-red2:hover,
  .bgh-md-red2:hover > a {
    background-color: #a50022; }
  .c-md-red2 {
    color: #a50022;
    fill: #a50022; }
  .ch-md-red2:hover,
  .ch-md-red2:hover > a {
    color: #a50022;
    fill: #a50022; }
  .bc-md-red2 {
    border-color: #a50022 !important; }
  .bgimp-md-red2 {
    background-color: #a50022 !important; }
  .bg-md-red3 {
    background-color: #fff1f4; }
  .bgh-md-red3:hover,
  .bgh-md-red3:hover > a {
    background-color: #fff1f4; }
  .c-md-red3 {
    color: #fff1f4;
    fill: #fff1f4; }
  .ch-md-red3:hover,
  .ch-md-red3:hover > a {
    color: #fff1f4;
    fill: #fff1f4; }
  .bc-md-red3 {
    border-color: #fff1f4 !important; }
  .bgimp-md-red3 {
    background-color: #fff1f4 !important; }
  .bg-md-dark-red {
    background-color: #3f000d; }
  .bgh-md-dark-red:hover,
  .bgh-md-dark-red:hover > a {
    background-color: #3f000d; }
  .c-md-dark-red {
    color: #3f000d;
    fill: #3f000d; }
  .ch-md-dark-red:hover,
  .ch-md-dark-red:hover > a {
    color: #3f000d;
    fill: #3f000d; }
  .bc-md-dark-red {
    border-color: #3f000d !important; }
  .bgimp-md-dark-red {
    background-color: #3f000d !important; }
  .bg-md-footer-red {
    background-color: #2e0009; }
  .bgh-md-footer-red:hover,
  .bgh-md-footer-red:hover > a {
    background-color: #2e0009; }
  .c-md-footer-red {
    color: #2e0009;
    fill: #2e0009; }
  .ch-md-footer-red:hover,
  .ch-md-footer-red:hover > a {
    color: #2e0009;
    fill: #2e0009; }
  .bc-md-footer-red {
    border-color: #2e0009 !important; }
  .bgimp-md-footer-red {
    background-color: #2e0009 !important; }
  .bg-md-footer-link-rollover-red {
    background-color: #e00d37; }
  .bgh-md-footer-link-rollover-red:hover,
  .bgh-md-footer-link-rollover-red:hover > a {
    background-color: #e00d37; }
  .c-md-footer-link-rollover-red {
    color: #e00d37;
    fill: #e00d37; }
  .ch-md-footer-link-rollover-red:hover,
  .ch-md-footer-link-rollover-red:hover > a {
    color: #e00d37;
    fill: #e00d37; }
  .bc-md-footer-link-rollover-red {
    border-color: #e00d37 !important; }
  .bgimp-md-footer-link-rollover-red {
    background-color: #e00d37 !important; }
  .bg-md-black {
    background-color: #18191F; }
  .bgh-md-black:hover,
  .bgh-md-black:hover > a {
    background-color: #18191F; }
  .c-md-black {
    color: #18191F;
    fill: #18191F; }
  .ch-md-black:hover,
  .ch-md-black:hover > a {
    color: #18191F;
    fill: #18191F; }
  .bc-md-black {
    border-color: #18191F !important; }
  .bgimp-md-black {
    background-color: #18191F !important; }
  .bg-md-white {
    background-color: #FFFFFF; }
  .bgh-md-white:hover,
  .bgh-md-white:hover > a {
    background-color: #FFFFFF; }
  .c-md-white {
    color: #FFFFFF;
    fill: #FFFFFF; }
  .ch-md-white:hover,
  .ch-md-white:hover > a {
    color: #FFFFFF;
    fill: #FFFFFF; }
  .bc-md-white {
    border-color: #FFFFFF !important; }
  .bgimp-md-white {
    background-color: #FFFFFF !important; }
  .bg-md-grey {
    background-color: #F6F7F9; }
  .bgh-md-grey:hover,
  .bgh-md-grey:hover > a {
    background-color: #F6F7F9; }
  .c-md-grey {
    color: #F6F7F9;
    fill: #F6F7F9; }
  .ch-md-grey:hover,
  .ch-md-grey:hover > a {
    color: #F6F7F9;
    fill: #F6F7F9; }
  .bc-md-grey {
    border-color: #F6F7F9 !important; }
  .bgimp-md-grey {
    background-color: #F6F7F9 !important; }
  .bg-md-grey3 {
    background-color: #828282; }
  .bgh-md-grey3:hover,
  .bgh-md-grey3:hover > a {
    background-color: #828282; }
  .c-md-grey3 {
    color: #828282;
    fill: #828282; }
  .ch-md-grey3:hover,
  .ch-md-grey3:hover > a {
    color: #828282;
    fill: #828282; }
  .bc-md-grey3 {
    border-color: #828282 !important; }
  .bgimp-md-grey3 {
    background-color: #828282 !important; }
  .bg-md-grey4 {
    background-color: #BDBDBD; }
  .bgh-md-grey4:hover,
  .bgh-md-grey4:hover > a {
    background-color: #BDBDBD; }
  .c-md-grey4 {
    color: #BDBDBD;
    fill: #BDBDBD; }
  .ch-md-grey4:hover,
  .ch-md-grey4:hover > a {
    color: #BDBDBD;
    fill: #BDBDBD; }
  .bc-md-grey4 {
    border-color: #BDBDBD !important; }
  .bgimp-md-grey4 {
    background-color: #BDBDBD !important; }
  .bg-md-grey5 {
    background-color: #E0E0E0; }
  .bgh-md-grey5:hover,
  .bgh-md-grey5:hover > a {
    background-color: #E0E0E0; }
  .c-md-grey5 {
    color: #E0E0E0;
    fill: #E0E0E0; }
  .ch-md-grey5:hover,
  .ch-md-grey5:hover > a {
    color: #E0E0E0;
    fill: #E0E0E0; }
  .bc-md-grey5 {
    border-color: #E0E0E0 !important; }
  .bgimp-md-grey5 {
    background-color: #E0E0E0 !important; }
  .bg-md-result-red {
    background-color: #DE2E30; }
  .bgh-md-result-red:hover,
  .bgh-md-result-red:hover > a {
    background-color: #DE2E30; }
  .c-md-result-red {
    color: #DE2E30;
    fill: #DE2E30; }
  .ch-md-result-red:hover,
  .ch-md-result-red:hover > a {
    color: #DE2E30;
    fill: #DE2E30; }
  .bc-md-result-red {
    border-color: #DE2E30 !important; }
  .bgimp-md-result-red {
    background-color: #DE2E30 !important; }
  .bg-md-result-green {
    background-color: #1E8449; }
  .bgh-md-result-green:hover,
  .bgh-md-result-green:hover > a {
    background-color: #1E8449; }
  .c-md-result-green {
    color: #1E8449;
    fill: #1E8449; }
  .ch-md-result-green:hover,
  .ch-md-result-green:hover > a {
    color: #1E8449;
    fill: #1E8449; }
  .bc-md-result-green {
    border-color: #1E8449 !important; }
  .bgimp-md-result-green {
    background-color: #1E8449 !important; }
  .bg-md-result-amber {
    background-color: #FAA15A; }
  .bgh-md-result-amber:hover,
  .bgh-md-result-amber:hover > a {
    background-color: #FAA15A; }
  .c-md-result-amber {
    color: #FAA15A;
    fill: #FAA15A; }
  .ch-md-result-amber:hover,
  .ch-md-result-amber:hover > a {
    color: #FAA15A;
    fill: #FAA15A; }
  .bc-md-result-amber {
    border-color: #FAA15A !important; }
  .bgimp-md-result-amber {
    background-color: #FAA15A !important; }
  .bg-md-error-red {
    background-color: #d30c19; }
  .bgh-md-error-red:hover,
  .bgh-md-error-red:hover > a {
    background-color: #d30c19; }
  .c-md-error-red {
    color: #d30c19;
    fill: #d30c19; }
  .ch-md-error-red:hover,
  .ch-md-error-red:hover > a {
    color: #d30c19;
    fill: #d30c19; }
  .bc-md-error-red {
    border-color: #d30c19 !important; }
  .bgimp-md-error-red {
    background-color: #d30c19 !important; }
  .bg-md-text-body {
    background-color: #222222; }
  .bgh-md-text-body:hover,
  .bgh-md-text-body:hover > a {
    background-color: #222222; }
  .c-md-text-body {
    color: #222222;
    fill: #222222; }
  .ch-md-text-body:hover,
  .ch-md-text-body:hover > a {
    color: #222222;
    fill: #222222; }
  .bc-md-text-body {
    border-color: #222222 !important; }
  .bgimp-md-text-body {
    background-color: #222222 !important; } }

@media (min-width: 992px) {
  .bg-lg-purple {
    background-color: #6C6ACD; }
  .bgh-lg-purple:hover,
  .bgh-lg-purple:hover > a {
    background-color: #6C6ACD; }
  .c-lg-purple {
    color: #6C6ACD;
    fill: #6C6ACD; }
  .ch-lg-purple:hover,
  .ch-lg-purple:hover > a {
    color: #6C6ACD;
    fill: #6C6ACD; }
  .bc-lg-purple {
    border-color: #6C6ACD !important; }
  .bgimp-lg-purple {
    background-color: #6C6ACD !important; }
  .bg-lg-purple2 {
    background-color: #4F46B8; }
  .bgh-lg-purple2:hover,
  .bgh-lg-purple2:hover > a {
    background-color: #4F46B8; }
  .c-lg-purple2 {
    color: #4F46B8;
    fill: #4F46B8; }
  .ch-lg-purple2:hover,
  .ch-lg-purple2:hover > a {
    color: #4F46B8;
    fill: #4F46B8; }
  .bc-lg-purple2 {
    border-color: #4F46B8 !important; }
  .bgimp-lg-purple2 {
    background-color: #4F46B8 !important; }
  .bg-lg-purple3 {
    background-color: #F3F4FD; }
  .bgh-lg-purple3:hover,
  .bgh-lg-purple3:hover > a {
    background-color: #F3F4FD; }
  .c-lg-purple3 {
    color: #F3F4FD;
    fill: #F3F4FD; }
  .ch-lg-purple3:hover,
  .ch-lg-purple3:hover > a {
    color: #F3F4FD;
    fill: #F3F4FD; }
  .bc-lg-purple3 {
    border-color: #F3F4FD !important; }
  .bgimp-lg-purple3 {
    background-color: #F3F4FD !important; }
  .bg-lg-dark-purple {
    background-color: #2F3055; }
  .bgh-lg-dark-purple:hover,
  .bgh-lg-dark-purple:hover > a {
    background-color: #2F3055; }
  .c-lg-dark-purple {
    color: #2F3055;
    fill: #2F3055; }
  .ch-lg-dark-purple:hover,
  .ch-lg-dark-purple:hover > a {
    color: #2F3055;
    fill: #2F3055; }
  .bc-lg-dark-purple {
    border-color: #2F3055 !important; }
  .bgimp-lg-dark-purple {
    background-color: #2F3055 !important; }
  .bg-lg-footer-purple {
    background-color: #2F2851; }
  .bgh-lg-footer-purple:hover,
  .bgh-lg-footer-purple:hover > a {
    background-color: #2F2851; }
  .c-lg-footer-purple {
    color: #2F2851;
    fill: #2F2851; }
  .ch-lg-footer-purple:hover,
  .ch-lg-footer-purple:hover > a {
    color: #2F2851;
    fill: #2F2851; }
  .bc-lg-footer-purple {
    border-color: #2F2851 !important; }
  .bgimp-lg-footer-purple {
    background-color: #2F2851 !important; }
  .bg-lg-footer-alt {
    background-color: #DCDCDC; }
  .bgh-lg-footer-alt:hover,
  .bgh-lg-footer-alt:hover > a {
    background-color: #DCDCDC; }
  .c-lg-footer-alt {
    color: #DCDCDC;
    fill: #DCDCDC; }
  .ch-lg-footer-alt:hover,
  .ch-lg-footer-alt:hover > a {
    color: #DCDCDC;
    fill: #DCDCDC; }
  .bc-lg-footer-alt {
    border-color: #DCDCDC !important; }
  .bgimp-lg-footer-alt {
    background-color: #DCDCDC !important; }
  .bg-lg-footer-link-rollover {
    background-color: #9290da; }
  .bgh-lg-footer-link-rollover:hover,
  .bgh-lg-footer-link-rollover:hover > a {
    background-color: #9290da; }
  .c-lg-footer-link-rollover {
    color: #9290da;
    fill: #9290da; }
  .ch-lg-footer-link-rollover:hover,
  .ch-lg-footer-link-rollover:hover > a {
    color: #9290da;
    fill: #9290da; }
  .bc-lg-footer-link-rollover {
    border-color: #9290da !important; }
  .bgimp-lg-footer-link-rollover {
    background-color: #9290da !important; }
  .bg-lg-red {
    background-color: #d3002a; }
  .bgh-lg-red:hover,
  .bgh-lg-red:hover > a {
    background-color: #d3002a; }
  .c-lg-red {
    color: #d3002a;
    fill: #d3002a; }
  .ch-lg-red:hover,
  .ch-lg-red:hover > a {
    color: #d3002a;
    fill: #d3002a; }
  .bc-lg-red {
    border-color: #d3002a !important; }
  .bgimp-lg-red {
    background-color: #d3002a !important; }
  .bg-lg-red2 {
    background-color: #a50022; }
  .bgh-lg-red2:hover,
  .bgh-lg-red2:hover > a {
    background-color: #a50022; }
  .c-lg-red2 {
    color: #a50022;
    fill: #a50022; }
  .ch-lg-red2:hover,
  .ch-lg-red2:hover > a {
    color: #a50022;
    fill: #a50022; }
  .bc-lg-red2 {
    border-color: #a50022 !important; }
  .bgimp-lg-red2 {
    background-color: #a50022 !important; }
  .bg-lg-red3 {
    background-color: #fff1f4; }
  .bgh-lg-red3:hover,
  .bgh-lg-red3:hover > a {
    background-color: #fff1f4; }
  .c-lg-red3 {
    color: #fff1f4;
    fill: #fff1f4; }
  .ch-lg-red3:hover,
  .ch-lg-red3:hover > a {
    color: #fff1f4;
    fill: #fff1f4; }
  .bc-lg-red3 {
    border-color: #fff1f4 !important; }
  .bgimp-lg-red3 {
    background-color: #fff1f4 !important; }
  .bg-lg-dark-red {
    background-color: #3f000d; }
  .bgh-lg-dark-red:hover,
  .bgh-lg-dark-red:hover > a {
    background-color: #3f000d; }
  .c-lg-dark-red {
    color: #3f000d;
    fill: #3f000d; }
  .ch-lg-dark-red:hover,
  .ch-lg-dark-red:hover > a {
    color: #3f000d;
    fill: #3f000d; }
  .bc-lg-dark-red {
    border-color: #3f000d !important; }
  .bgimp-lg-dark-red {
    background-color: #3f000d !important; }
  .bg-lg-footer-red {
    background-color: #2e0009; }
  .bgh-lg-footer-red:hover,
  .bgh-lg-footer-red:hover > a {
    background-color: #2e0009; }
  .c-lg-footer-red {
    color: #2e0009;
    fill: #2e0009; }
  .ch-lg-footer-red:hover,
  .ch-lg-footer-red:hover > a {
    color: #2e0009;
    fill: #2e0009; }
  .bc-lg-footer-red {
    border-color: #2e0009 !important; }
  .bgimp-lg-footer-red {
    background-color: #2e0009 !important; }
  .bg-lg-footer-link-rollover-red {
    background-color: #e00d37; }
  .bgh-lg-footer-link-rollover-red:hover,
  .bgh-lg-footer-link-rollover-red:hover > a {
    background-color: #e00d37; }
  .c-lg-footer-link-rollover-red {
    color: #e00d37;
    fill: #e00d37; }
  .ch-lg-footer-link-rollover-red:hover,
  .ch-lg-footer-link-rollover-red:hover > a {
    color: #e00d37;
    fill: #e00d37; }
  .bc-lg-footer-link-rollover-red {
    border-color: #e00d37 !important; }
  .bgimp-lg-footer-link-rollover-red {
    background-color: #e00d37 !important; }
  .bg-lg-black {
    background-color: #18191F; }
  .bgh-lg-black:hover,
  .bgh-lg-black:hover > a {
    background-color: #18191F; }
  .c-lg-black {
    color: #18191F;
    fill: #18191F; }
  .ch-lg-black:hover,
  .ch-lg-black:hover > a {
    color: #18191F;
    fill: #18191F; }
  .bc-lg-black {
    border-color: #18191F !important; }
  .bgimp-lg-black {
    background-color: #18191F !important; }
  .bg-lg-white {
    background-color: #FFFFFF; }
  .bgh-lg-white:hover,
  .bgh-lg-white:hover > a {
    background-color: #FFFFFF; }
  .c-lg-white {
    color: #FFFFFF;
    fill: #FFFFFF; }
  .ch-lg-white:hover,
  .ch-lg-white:hover > a {
    color: #FFFFFF;
    fill: #FFFFFF; }
  .bc-lg-white {
    border-color: #FFFFFF !important; }
  .bgimp-lg-white {
    background-color: #FFFFFF !important; }
  .bg-lg-grey {
    background-color: #F6F7F9; }
  .bgh-lg-grey:hover,
  .bgh-lg-grey:hover > a {
    background-color: #F6F7F9; }
  .c-lg-grey {
    color: #F6F7F9;
    fill: #F6F7F9; }
  .ch-lg-grey:hover,
  .ch-lg-grey:hover > a {
    color: #F6F7F9;
    fill: #F6F7F9; }
  .bc-lg-grey {
    border-color: #F6F7F9 !important; }
  .bgimp-lg-grey {
    background-color: #F6F7F9 !important; }
  .bg-lg-grey3 {
    background-color: #828282; }
  .bgh-lg-grey3:hover,
  .bgh-lg-grey3:hover > a {
    background-color: #828282; }
  .c-lg-grey3 {
    color: #828282;
    fill: #828282; }
  .ch-lg-grey3:hover,
  .ch-lg-grey3:hover > a {
    color: #828282;
    fill: #828282; }
  .bc-lg-grey3 {
    border-color: #828282 !important; }
  .bgimp-lg-grey3 {
    background-color: #828282 !important; }
  .bg-lg-grey4 {
    background-color: #BDBDBD; }
  .bgh-lg-grey4:hover,
  .bgh-lg-grey4:hover > a {
    background-color: #BDBDBD; }
  .c-lg-grey4 {
    color: #BDBDBD;
    fill: #BDBDBD; }
  .ch-lg-grey4:hover,
  .ch-lg-grey4:hover > a {
    color: #BDBDBD;
    fill: #BDBDBD; }
  .bc-lg-grey4 {
    border-color: #BDBDBD !important; }
  .bgimp-lg-grey4 {
    background-color: #BDBDBD !important; }
  .bg-lg-grey5 {
    background-color: #E0E0E0; }
  .bgh-lg-grey5:hover,
  .bgh-lg-grey5:hover > a {
    background-color: #E0E0E0; }
  .c-lg-grey5 {
    color: #E0E0E0;
    fill: #E0E0E0; }
  .ch-lg-grey5:hover,
  .ch-lg-grey5:hover > a {
    color: #E0E0E0;
    fill: #E0E0E0; }
  .bc-lg-grey5 {
    border-color: #E0E0E0 !important; }
  .bgimp-lg-grey5 {
    background-color: #E0E0E0 !important; }
  .bg-lg-result-red {
    background-color: #DE2E30; }
  .bgh-lg-result-red:hover,
  .bgh-lg-result-red:hover > a {
    background-color: #DE2E30; }
  .c-lg-result-red {
    color: #DE2E30;
    fill: #DE2E30; }
  .ch-lg-result-red:hover,
  .ch-lg-result-red:hover > a {
    color: #DE2E30;
    fill: #DE2E30; }
  .bc-lg-result-red {
    border-color: #DE2E30 !important; }
  .bgimp-lg-result-red {
    background-color: #DE2E30 !important; }
  .bg-lg-result-green {
    background-color: #1E8449; }
  .bgh-lg-result-green:hover,
  .bgh-lg-result-green:hover > a {
    background-color: #1E8449; }
  .c-lg-result-green {
    color: #1E8449;
    fill: #1E8449; }
  .ch-lg-result-green:hover,
  .ch-lg-result-green:hover > a {
    color: #1E8449;
    fill: #1E8449; }
  .bc-lg-result-green {
    border-color: #1E8449 !important; }
  .bgimp-lg-result-green {
    background-color: #1E8449 !important; }
  .bg-lg-result-amber {
    background-color: #FAA15A; }
  .bgh-lg-result-amber:hover,
  .bgh-lg-result-amber:hover > a {
    background-color: #FAA15A; }
  .c-lg-result-amber {
    color: #FAA15A;
    fill: #FAA15A; }
  .ch-lg-result-amber:hover,
  .ch-lg-result-amber:hover > a {
    color: #FAA15A;
    fill: #FAA15A; }
  .bc-lg-result-amber {
    border-color: #FAA15A !important; }
  .bgimp-lg-result-amber {
    background-color: #FAA15A !important; }
  .bg-lg-error-red {
    background-color: #d30c19; }
  .bgh-lg-error-red:hover,
  .bgh-lg-error-red:hover > a {
    background-color: #d30c19; }
  .c-lg-error-red {
    color: #d30c19;
    fill: #d30c19; }
  .ch-lg-error-red:hover,
  .ch-lg-error-red:hover > a {
    color: #d30c19;
    fill: #d30c19; }
  .bc-lg-error-red {
    border-color: #d30c19 !important; }
  .bgimp-lg-error-red {
    background-color: #d30c19 !important; }
  .bg-lg-text-body {
    background-color: #222222; }
  .bgh-lg-text-body:hover,
  .bgh-lg-text-body:hover > a {
    background-color: #222222; }
  .c-lg-text-body {
    color: #222222;
    fill: #222222; }
  .ch-lg-text-body:hover,
  .ch-lg-text-body:hover > a {
    color: #222222;
    fill: #222222; }
  .bc-lg-text-body {
    border-color: #222222 !important; }
  .bgimp-lg-text-body {
    background-color: #222222 !important; } }

@media (min-width: 1200px) {
  .bg-xl-purple {
    background-color: #6C6ACD; }
  .bgh-xl-purple:hover,
  .bgh-xl-purple:hover > a {
    background-color: #6C6ACD; }
  .c-xl-purple {
    color: #6C6ACD;
    fill: #6C6ACD; }
  .ch-xl-purple:hover,
  .ch-xl-purple:hover > a {
    color: #6C6ACD;
    fill: #6C6ACD; }
  .bc-xl-purple {
    border-color: #6C6ACD !important; }
  .bgimp-xl-purple {
    background-color: #6C6ACD !important; }
  .bg-xl-purple2 {
    background-color: #4F46B8; }
  .bgh-xl-purple2:hover,
  .bgh-xl-purple2:hover > a {
    background-color: #4F46B8; }
  .c-xl-purple2 {
    color: #4F46B8;
    fill: #4F46B8; }
  .ch-xl-purple2:hover,
  .ch-xl-purple2:hover > a {
    color: #4F46B8;
    fill: #4F46B8; }
  .bc-xl-purple2 {
    border-color: #4F46B8 !important; }
  .bgimp-xl-purple2 {
    background-color: #4F46B8 !important; }
  .bg-xl-purple3 {
    background-color: #F3F4FD; }
  .bgh-xl-purple3:hover,
  .bgh-xl-purple3:hover > a {
    background-color: #F3F4FD; }
  .c-xl-purple3 {
    color: #F3F4FD;
    fill: #F3F4FD; }
  .ch-xl-purple3:hover,
  .ch-xl-purple3:hover > a {
    color: #F3F4FD;
    fill: #F3F4FD; }
  .bc-xl-purple3 {
    border-color: #F3F4FD !important; }
  .bgimp-xl-purple3 {
    background-color: #F3F4FD !important; }
  .bg-xl-dark-purple {
    background-color: #2F3055; }
  .bgh-xl-dark-purple:hover,
  .bgh-xl-dark-purple:hover > a {
    background-color: #2F3055; }
  .c-xl-dark-purple {
    color: #2F3055;
    fill: #2F3055; }
  .ch-xl-dark-purple:hover,
  .ch-xl-dark-purple:hover > a {
    color: #2F3055;
    fill: #2F3055; }
  .bc-xl-dark-purple {
    border-color: #2F3055 !important; }
  .bgimp-xl-dark-purple {
    background-color: #2F3055 !important; }
  .bg-xl-footer-purple {
    background-color: #2F2851; }
  .bgh-xl-footer-purple:hover,
  .bgh-xl-footer-purple:hover > a {
    background-color: #2F2851; }
  .c-xl-footer-purple {
    color: #2F2851;
    fill: #2F2851; }
  .ch-xl-footer-purple:hover,
  .ch-xl-footer-purple:hover > a {
    color: #2F2851;
    fill: #2F2851; }
  .bc-xl-footer-purple {
    border-color: #2F2851 !important; }
  .bgimp-xl-footer-purple {
    background-color: #2F2851 !important; }
  .bg-xl-footer-alt {
    background-color: #DCDCDC; }
  .bgh-xl-footer-alt:hover,
  .bgh-xl-footer-alt:hover > a {
    background-color: #DCDCDC; }
  .c-xl-footer-alt {
    color: #DCDCDC;
    fill: #DCDCDC; }
  .ch-xl-footer-alt:hover,
  .ch-xl-footer-alt:hover > a {
    color: #DCDCDC;
    fill: #DCDCDC; }
  .bc-xl-footer-alt {
    border-color: #DCDCDC !important; }
  .bgimp-xl-footer-alt {
    background-color: #DCDCDC !important; }
  .bg-xl-footer-link-rollover {
    background-color: #9290da; }
  .bgh-xl-footer-link-rollover:hover,
  .bgh-xl-footer-link-rollover:hover > a {
    background-color: #9290da; }
  .c-xl-footer-link-rollover {
    color: #9290da;
    fill: #9290da; }
  .ch-xl-footer-link-rollover:hover,
  .ch-xl-footer-link-rollover:hover > a {
    color: #9290da;
    fill: #9290da; }
  .bc-xl-footer-link-rollover {
    border-color: #9290da !important; }
  .bgimp-xl-footer-link-rollover {
    background-color: #9290da !important; }
  .bg-xl-red {
    background-color: #d3002a; }
  .bgh-xl-red:hover,
  .bgh-xl-red:hover > a {
    background-color: #d3002a; }
  .c-xl-red {
    color: #d3002a;
    fill: #d3002a; }
  .ch-xl-red:hover,
  .ch-xl-red:hover > a {
    color: #d3002a;
    fill: #d3002a; }
  .bc-xl-red {
    border-color: #d3002a !important; }
  .bgimp-xl-red {
    background-color: #d3002a !important; }
  .bg-xl-red2 {
    background-color: #a50022; }
  .bgh-xl-red2:hover,
  .bgh-xl-red2:hover > a {
    background-color: #a50022; }
  .c-xl-red2 {
    color: #a50022;
    fill: #a50022; }
  .ch-xl-red2:hover,
  .ch-xl-red2:hover > a {
    color: #a50022;
    fill: #a50022; }
  .bc-xl-red2 {
    border-color: #a50022 !important; }
  .bgimp-xl-red2 {
    background-color: #a50022 !important; }
  .bg-xl-red3 {
    background-color: #fff1f4; }
  .bgh-xl-red3:hover,
  .bgh-xl-red3:hover > a {
    background-color: #fff1f4; }
  .c-xl-red3 {
    color: #fff1f4;
    fill: #fff1f4; }
  .ch-xl-red3:hover,
  .ch-xl-red3:hover > a {
    color: #fff1f4;
    fill: #fff1f4; }
  .bc-xl-red3 {
    border-color: #fff1f4 !important; }
  .bgimp-xl-red3 {
    background-color: #fff1f4 !important; }
  .bg-xl-dark-red {
    background-color: #3f000d; }
  .bgh-xl-dark-red:hover,
  .bgh-xl-dark-red:hover > a {
    background-color: #3f000d; }
  .c-xl-dark-red {
    color: #3f000d;
    fill: #3f000d; }
  .ch-xl-dark-red:hover,
  .ch-xl-dark-red:hover > a {
    color: #3f000d;
    fill: #3f000d; }
  .bc-xl-dark-red {
    border-color: #3f000d !important; }
  .bgimp-xl-dark-red {
    background-color: #3f000d !important; }
  .bg-xl-footer-red {
    background-color: #2e0009; }
  .bgh-xl-footer-red:hover,
  .bgh-xl-footer-red:hover > a {
    background-color: #2e0009; }
  .c-xl-footer-red {
    color: #2e0009;
    fill: #2e0009; }
  .ch-xl-footer-red:hover,
  .ch-xl-footer-red:hover > a {
    color: #2e0009;
    fill: #2e0009; }
  .bc-xl-footer-red {
    border-color: #2e0009 !important; }
  .bgimp-xl-footer-red {
    background-color: #2e0009 !important; }
  .bg-xl-footer-link-rollover-red {
    background-color: #e00d37; }
  .bgh-xl-footer-link-rollover-red:hover,
  .bgh-xl-footer-link-rollover-red:hover > a {
    background-color: #e00d37; }
  .c-xl-footer-link-rollover-red {
    color: #e00d37;
    fill: #e00d37; }
  .ch-xl-footer-link-rollover-red:hover,
  .ch-xl-footer-link-rollover-red:hover > a {
    color: #e00d37;
    fill: #e00d37; }
  .bc-xl-footer-link-rollover-red {
    border-color: #e00d37 !important; }
  .bgimp-xl-footer-link-rollover-red {
    background-color: #e00d37 !important; }
  .bg-xl-black {
    background-color: #18191F; }
  .bgh-xl-black:hover,
  .bgh-xl-black:hover > a {
    background-color: #18191F; }
  .c-xl-black {
    color: #18191F;
    fill: #18191F; }
  .ch-xl-black:hover,
  .ch-xl-black:hover > a {
    color: #18191F;
    fill: #18191F; }
  .bc-xl-black {
    border-color: #18191F !important; }
  .bgimp-xl-black {
    background-color: #18191F !important; }
  .bg-xl-white {
    background-color: #FFFFFF; }
  .bgh-xl-white:hover,
  .bgh-xl-white:hover > a {
    background-color: #FFFFFF; }
  .c-xl-white {
    color: #FFFFFF;
    fill: #FFFFFF; }
  .ch-xl-white:hover,
  .ch-xl-white:hover > a {
    color: #FFFFFF;
    fill: #FFFFFF; }
  .bc-xl-white {
    border-color: #FFFFFF !important; }
  .bgimp-xl-white {
    background-color: #FFFFFF !important; }
  .bg-xl-grey {
    background-color: #F6F7F9; }
  .bgh-xl-grey:hover,
  .bgh-xl-grey:hover > a {
    background-color: #F6F7F9; }
  .c-xl-grey {
    color: #F6F7F9;
    fill: #F6F7F9; }
  .ch-xl-grey:hover,
  .ch-xl-grey:hover > a {
    color: #F6F7F9;
    fill: #F6F7F9; }
  .bc-xl-grey {
    border-color: #F6F7F9 !important; }
  .bgimp-xl-grey {
    background-color: #F6F7F9 !important; }
  .bg-xl-grey3 {
    background-color: #828282; }
  .bgh-xl-grey3:hover,
  .bgh-xl-grey3:hover > a {
    background-color: #828282; }
  .c-xl-grey3 {
    color: #828282;
    fill: #828282; }
  .ch-xl-grey3:hover,
  .ch-xl-grey3:hover > a {
    color: #828282;
    fill: #828282; }
  .bc-xl-grey3 {
    border-color: #828282 !important; }
  .bgimp-xl-grey3 {
    background-color: #828282 !important; }
  .bg-xl-grey4 {
    background-color: #BDBDBD; }
  .bgh-xl-grey4:hover,
  .bgh-xl-grey4:hover > a {
    background-color: #BDBDBD; }
  .c-xl-grey4 {
    color: #BDBDBD;
    fill: #BDBDBD; }
  .ch-xl-grey4:hover,
  .ch-xl-grey4:hover > a {
    color: #BDBDBD;
    fill: #BDBDBD; }
  .bc-xl-grey4 {
    border-color: #BDBDBD !important; }
  .bgimp-xl-grey4 {
    background-color: #BDBDBD !important; }
  .bg-xl-grey5 {
    background-color: #E0E0E0; }
  .bgh-xl-grey5:hover,
  .bgh-xl-grey5:hover > a {
    background-color: #E0E0E0; }
  .c-xl-grey5 {
    color: #E0E0E0;
    fill: #E0E0E0; }
  .ch-xl-grey5:hover,
  .ch-xl-grey5:hover > a {
    color: #E0E0E0;
    fill: #E0E0E0; }
  .bc-xl-grey5 {
    border-color: #E0E0E0 !important; }
  .bgimp-xl-grey5 {
    background-color: #E0E0E0 !important; }
  .bg-xl-result-red {
    background-color: #DE2E30; }
  .bgh-xl-result-red:hover,
  .bgh-xl-result-red:hover > a {
    background-color: #DE2E30; }
  .c-xl-result-red {
    color: #DE2E30;
    fill: #DE2E30; }
  .ch-xl-result-red:hover,
  .ch-xl-result-red:hover > a {
    color: #DE2E30;
    fill: #DE2E30; }
  .bc-xl-result-red {
    border-color: #DE2E30 !important; }
  .bgimp-xl-result-red {
    background-color: #DE2E30 !important; }
  .bg-xl-result-green {
    background-color: #1E8449; }
  .bgh-xl-result-green:hover,
  .bgh-xl-result-green:hover > a {
    background-color: #1E8449; }
  .c-xl-result-green {
    color: #1E8449;
    fill: #1E8449; }
  .ch-xl-result-green:hover,
  .ch-xl-result-green:hover > a {
    color: #1E8449;
    fill: #1E8449; }
  .bc-xl-result-green {
    border-color: #1E8449 !important; }
  .bgimp-xl-result-green {
    background-color: #1E8449 !important; }
  .bg-xl-result-amber {
    background-color: #FAA15A; }
  .bgh-xl-result-amber:hover,
  .bgh-xl-result-amber:hover > a {
    background-color: #FAA15A; }
  .c-xl-result-amber {
    color: #FAA15A;
    fill: #FAA15A; }
  .ch-xl-result-amber:hover,
  .ch-xl-result-amber:hover > a {
    color: #FAA15A;
    fill: #FAA15A; }
  .bc-xl-result-amber {
    border-color: #FAA15A !important; }
  .bgimp-xl-result-amber {
    background-color: #FAA15A !important; }
  .bg-xl-error-red {
    background-color: #d30c19; }
  .bgh-xl-error-red:hover,
  .bgh-xl-error-red:hover > a {
    background-color: #d30c19; }
  .c-xl-error-red {
    color: #d30c19;
    fill: #d30c19; }
  .ch-xl-error-red:hover,
  .ch-xl-error-red:hover > a {
    color: #d30c19;
    fill: #d30c19; }
  .bc-xl-error-red {
    border-color: #d30c19 !important; }
  .bgimp-xl-error-red {
    background-color: #d30c19 !important; }
  .bg-xl-text-body {
    background-color: #222222; }
  .bgh-xl-text-body:hover,
  .bgh-xl-text-body:hover > a {
    background-color: #222222; }
  .c-xl-text-body {
    color: #222222;
    fill: #222222; }
  .ch-xl-text-body:hover,
  .ch-xl-text-body:hover > a {
    color: #222222;
    fill: #222222; }
  .bc-xl-text-body {
    border-color: #222222 !important; }
  .bgimp-xl-text-body {
    background-color: #222222 !important; } }

.openhouse .c-purple {
  color: #d3002a !important;
  fill: #d3002a !important; }

.openhouse .c-purple2 {
  color: #a50022 !important;
  fill: #a50022 !important; }

.f-tiny {
  font-size: 14px; }

.f-copy {
  font-size: 16px; }

.f-copy-large {
  font-size: 18px; }

.f-bullets {
  font-size: 16px; }

.f-hero-title {
  font-size: 55px; }

.f-page-title {
  font-size: 45px; }

.f-h1 {
  font-size: 31px; }

.f-h2 {
  font-size: 23px; }

.f-h3 {
  font-size: 21px; }

.f-h4 {
  font-size: 20px; }

.f-box-header {
  font-size: 25px; }

.f-button {
  font-size: 18px; }

.f-text-link {
  font-size: 17px; }

.f-light {
  font-weight: 300; }

.f-normal {
  font-weight: 400; }

.f-bold {
  font-weight: 500; }

.f-bolder {
  font-weight: 700; }

.f-boldest {
  font-weight: 900; }

@media (min-width: 768px) {
  .f-md-tiny {
    font-size: 14px; }
  .f-md-copy {
    font-size: 16px; }
  .f-md-copy-large {
    font-size: 18px; }
  .f-md-bullets {
    font-size: 16px; }
  .f-md-hero-title {
    font-size: 55px; }
  .f-md-page-title {
    font-size: 45px; }
  .f-md-h1 {
    font-size: 31px; }
  .f-md-h2 {
    font-size: 23px; }
  .f-md-h3 {
    font-size: 21px; }
  .f-md-h4 {
    font-size: 20px; }
  .f-md-box-header {
    font-size: 25px; }
  .f-md-button {
    font-size: 18px; }
  .f-md-text-link {
    font-size: 17px; }
  .f-md-light {
    font-weight: 300; }
  .f-md-normal {
    font-weight: 400; }
  .f-md-bold {
    font-weight: 500; }
  .f-md-bolder {
    font-weight: 700; }
  .f-md-boldest {
    font-weight: 900; } }

@media (min-width: 992px) {
  .f-lg-tiny {
    font-size: 14px; }
  .f-lg-copy {
    font-size: 16px; }
  .f-lg-copy-large {
    font-size: 18px; }
  .f-lg-bullets {
    font-size: 16px; }
  .f-lg-hero-title {
    font-size: 55px; }
  .f-lg-page-title {
    font-size: 45px; }
  .f-lg-h1 {
    font-size: 31px; }
  .f-lg-h2 {
    font-size: 23px; }
  .f-lg-h3 {
    font-size: 21px; }
  .f-lg-h4 {
    font-size: 20px; }
  .f-lg-box-header {
    font-size: 25px; }
  .f-lg-button {
    font-size: 18px; }
  .f-lg-text-link {
    font-size: 17px; }
  .f-lg-light {
    font-weight: 300; }
  .f-lg-normal {
    font-weight: 400; }
  .f-lg-bold {
    font-weight: 500; }
  .f-lg-bolder {
    font-weight: 700; }
  .f-lg-boldest {
    font-weight: 900; } }

@media (min-width: 1200px) {
  .f-xl-tiny {
    font-size: 14px; }
  .f-xl-copy {
    font-size: 16px; }
  .f-xl-copy-large {
    font-size: 18px; }
  .f-xl-bullets {
    font-size: 16px; }
  .f-xl-hero-title {
    font-size: 55px; }
  .f-xl-page-title {
    font-size: 45px; }
  .f-xl-h1 {
    font-size: 31px; }
  .f-xl-h2 {
    font-size: 23px; }
  .f-xl-h3 {
    font-size: 21px; }
  .f-xl-h4 {
    font-size: 20px; }
  .f-xl-box-header {
    font-size: 25px; }
  .f-xl-button {
    font-size: 18px; }
  .f-xl-text-link {
    font-size: 17px; }
  .f-xl-light {
    font-weight: 300; }
  .f-xl-normal {
    font-weight: 400; }
  .f-xl-bold {
    font-weight: 500; }
  .f-xl-bolder {
    font-weight: 700; }
  .f-xl-boldest {
    font-weight: 900; } }

.ff-heading {
  font-family: "Poppins", sans-serif; }

html,
body {
  font-family: "Roboto", sans-serif;
  color: #222222; }

body {
  font-size: 16px; }

p, .copy {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px; }
  p a, .copy a {
    font-family: "Roboto", sans-serif;
    font-weight: 300; }
  p.emphasised, .copy.emphasised {
    font-weight: 400; }
    p.emphasised a, .copy.emphasised a {
      font-weight: 400; }
  p-tiny, .copy-tiny {
    font-size: 14px; }
  p img[height], .copy img[height] {
    height: auto !important; }

.copy-large {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px; }

.page-title {
  font-family: "Poppins", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 61px; }

.hero-title {
  font-family: "Poppins", sans-serif;
  font-size: 55px;
  font-weight: 400;
  line-height: 75px; }

.card-header {
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 24px; }

.subheading {
  font-size: 20px;
  font-weight: 400;
  line-height: 23px; }

ul {
  padding-left: 1rem; }

li {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px; }

.carousel-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  color: #6C6ACD;
  background: #6C6ACD;
  margin: 0 6px;
  transition: all 0s; }
  .openhouse .carousel-dot {
    color: #d3002a;
    background: #d3002a; }
  .carousel-dot--white {
    color: #FFFFFF;
    background: #FFFFFF; }
  .carousel-dot--purple2 {
    color: #4F46B8;
    background: #4F46B8; }
  .carousel-dot--red2 {
    color: #a50022;
    background: #a50022; }
  .carousel-dot.active {
    width: 14px;
    height: 14px;
    background: none;
    border: 3px solid; }
  .carousel-dot-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; }

ul.ticks {
  list-style-type: none;
  padding: 0; }
  ul.ticks li {
    padding-left: 22px;
    position: relative;
    line-height: 26px; }
    ul.ticks li .px-icon--bullet {
      width: 13px;
      height: 13px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }

ul.extras {
  list-style-type: none;
  padding: 0; }
  ul.extras li .form__checkbox label {
    font-weight: 400; }
  ul.extras li:first-child .form__checkbox {
    margin-top: 0; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 400; }

h1, .h1 {
  font-size: 31px;
  line-height: 41px;
  margin: 0.83em 0; }

h2, .h2 {
  font-size: 23px;
  margin: 0.83em 0; }

h3, .h3 {
  font-size: 21px;
  line-height: 30px;
  margin: 1em 0; }

h4, .h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500; }

hr {
  border: none;
  border-bottom: 1px solid #BDBDBD; }

a, button {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #4F46B8;
  fill: #4F46B8;
  text-decoration: none;
  transition: all 200ms ease-in-out; }
  a svg, button svg {
    transition: all 200ms ease-in-out; }
  a.emphasised, button.emphasised {
    font-size: 18px;
    line-height: 32px;
    font-weight: 500; }
  a:hover, button:hover {
    color: #4F46B8;
    text-decoration: underline; }
    a:hover svg, button:hover svg {
      fill: #4F46B8; }
  .openhouse a, .openhouse button {
    color: #a50022;
    fill: #a50022; }
    .openhouse a:hover, .openhouse button:hover {
      color: #a50022; }
      .openhouse a:hover svg, .openhouse button:hover svg {
        fill: #a50022; }

.bold {
  font-weight: 500; }

.c-white a {
  color: #FFFFFF;
  fill: #FFFFFF; }
  .c-white a:hover {
    color: #FFFFFF;
    fill: #FFFFFF; }
    .c-white a:hover svg {
      color: #FFFFFF;
      fill: #FFFFFF; }
  .openhouse .c-white a {
    color: #FFFFFF;
    fill: #FFFFFF; }
    .openhouse .c-white a:hover {
      color: #FFFFFF;
      fill: #FFFFFF; }
      .openhouse .c-white a:hover svg {
        color: #FFFFFF;
        fill: #FFFFFF; }
  .c-white a:not(.c-white) {
    color: #6C6ACD;
    fill: #6C6ACD; }
    .openhouse .c-white a:not(.c-white) {
      color: #a50022;
      fill: #a50022; }
    .c-white a:not(.c-white):hover {
      color: #6C6ACD;
      fill: #6C6ACD; }
      .openhouse .c-white a:not(.c-white):hover {
        color: #a50022;
        fill: #a50022; }

@media (min-width: 768px) {
  .c-md-white a {
    color: #FFFFFF;
    fill: #FFFFFF; }
    .c-md-white a:hover {
      color: #FFFFFF;
      fill: #FFFFFF; }
      .c-md-white a:hover svg {
        color: #FFFFFF;
        fill: #FFFFFF; }
    .openhouse .c-md-white a {
      color: #FFFFFF;
      fill: #FFFFFF; }
      .openhouse .c-md-white a:hover {
        color: #FFFFFF;
        fill: #FFFFFF; }
        .openhouse .c-md-white a:hover svg {
          color: #FFFFFF;
          fill: #FFFFFF; }
    .c-md-white a:not(.c-md-white) {
      color: #6C6ACD;
      fill: #6C6ACD; }
      .openhouse .c-md-white a:not(.c-md-white) {
        color: #a50022;
        fill: #a50022; }
      .c-md-white a:not(.c-md-white):hover {
        color: #6C6ACD;
        fill: #6C6ACD; }
        .openhouse .c-md-white a:not(.c-md-white):hover {
          color: #a50022;
          fill: #a50022; } }

@media (min-width: 992px) {
  .c-lg-white a {
    color: #FFFFFF;
    fill: #FFFFFF; }
    .c-lg-white a:hover {
      color: #FFFFFF;
      fill: #FFFFFF; }
      .c-lg-white a:hover svg {
        color: #FFFFFF;
        fill: #FFFFFF; }
    .openhouse .c-lg-white a {
      color: #FFFFFF;
      fill: #FFFFFF; }
      .openhouse .c-lg-white a:hover {
        color: #FFFFFF;
        fill: #FFFFFF; }
        .openhouse .c-lg-white a:hover svg {
          color: #FFFFFF;
          fill: #FFFFFF; }
    .c-lg-white a:not(.c-lg-white) {
      color: #6C6ACD;
      fill: #6C6ACD; }
      .openhouse .c-lg-white a:not(.c-lg-white) {
        color: #a50022;
        fill: #a50022; }
      .c-lg-white a:not(.c-lg-white):hover {
        color: #6C6ACD;
        fill: #6C6ACD; }
        .openhouse .c-lg-white a:not(.c-lg-white):hover {
          color: #a50022;
          fill: #a50022; } }

@media (min-width: 1200px) {
  .c-xl-white a {
    color: #FFFFFF;
    fill: #FFFFFF; }
    .c-xl-white a:hover {
      color: #FFFFFF;
      fill: #FFFFFF; }
      .c-xl-white a:hover svg {
        color: #FFFFFF;
        fill: #FFFFFF; }
    .openhouse .c-xl-white a {
      color: #FFFFFF;
      fill: #FFFFFF; }
      .openhouse .c-xl-white a:hover {
        color: #FFFFFF;
        fill: #FFFFFF; }
        .openhouse .c-xl-white a:hover svg {
          color: #FFFFFF;
          fill: #FFFFFF; }
    .c-xl-white a:not(.c-xl-white) {
      color: #6C6ACD;
      fill: #6C6ACD; }
      .openhouse .c-xl-white a:not(.c-xl-white) {
        color: #a50022;
        fill: #a50022; }
      .c-xl-white a:not(.c-xl-white):hover {
        color: #6C6ACD;
        fill: #6C6ACD; }
        .openhouse .c-xl-white a:not(.c-xl-white):hover {
          color: #a50022;
          fill: #a50022; } }

address {
  font-style: normal; }

.px-icon {
  width: 20px;
  height: 20px;
  max-width: 100%; }
  .px-icon--socials {
    width: 24px;
    height: 24px; }
    .px-icon--socials-lg {
      width: 35px;
      height: 35px; }
  .px-icon--large {
    height: 40px;
    width: auto; }
  .px-icon--card {
    height: 90px;
    width: auto; }
  .px-icon--card-mid {
    height: 60px;
    width: auto; }
  .px-icon--carousel-nav {
    width: 30px;
    height: 30px; }
  .px-icon--header {
    width: 30px;
    height: 30px;
    width: 20px;
    height: 15px; }
  .px-icon--nav-close {
    width: 22px;
    height: 22px; }
  .px-icon--testmelogo,
  .px-icon--testmelogo svg {
    width: 150px;
    height: auto; }
  .px-icon--nav-arrow {
    width: 13px;
    height: 13px; }
  .px-icon--checkbox-tooltip {
    width: 27px;
    height: 27px; }
  .px-icon--checkout-step {
    width: 10px;
    height: 10px; }
  .px-icon--dropdown-arrow {
    width: 14px;
    height: 14px; }
  .px-icon--result {
    width: 40px;
    height: 40px; }
  .px-icon--order-status {
    width: 29px;
    height: 29px; }
  .px-icon--experience {
    width: 46px;
    height: 46px; }

/*Desktop only container. This container is full width until the md breakpoint*/
/*after that, it follows the same max-widths as the bootstrap container*/
.responsive-container {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin-right: auto;
  margin-left: auto;
  /*responsive-container__column must be added to cols*/ }
  .responsive-container__column {
    max-width: 33.75rem;
    margin: 0 auto; }
  @media (min-width: 768px) {
    .responsive-container {
      max-width: 720px; }
      .responsive-container__column {
        max-width: unset; } }
  @media (min-width: 992px) {
    .responsive-container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .responsive-container {
      max-width: 1200px; } }

/*block*/
.block {
  padding: 1.25rem; }
  .block h1, .block .h1, .block h2, .block .h2, .block h3, .block .h3, .block h4, .block .h4,
  .block h5, .block .h5, .block h6, .block .h6 {
    margin-top: 0; }
  .block ul {
    padding: 0;
    margin: 0 0 1.25rem 1em; }
  @media (min-width: 768px) {
    .block {
      padding: 30px; }
      .block ul {
        margin: 0 0 30px 1em; } }

.loading {
  display: none;
  position: relative;
  width: 20px;
  height: 20px; }
  .loading div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 21px;
    height: 21px;
    border: 3px solid currentColor;
    border-radius: 50%;
    animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent; }
    .loading div:nth-child(1) {
      animation-delay: -0.45s; }
    .loading div:nth-child(2) {
      animation-delay: -0.3s; }
    .loading div:nth-child(3) {
      animation-delay: -0.15s; }

.is-loading > * {
  display: none; }

.is-loading > .loading {
  display: inline-block; }

@keyframes loading-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

html {
  height: 100%; }
  html:not(.smooth-scroll-disabled) {
    scroll-behavior: smooth; }

body {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0; }
  body.has-overlay {
    overflow: hidden; }

.popover-active {
  overflow-y: hidden;
  position: fixed;
  height: 100vh;
  padding-right: 15px; }

.grecaptcha-badge {
  visibility: hidden;
  display: none; }

img {
  max-width: 100%;
  object-fit: cover; }
  img.animate {
    -webkit-animation-duration: 1.2s;
    animation-duration: 1.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
    img.animate-fade-right {
      -webkit-animation-name: fadeInRight;
      animation-name: fadeInRight; }

.media-block--embed iframe {
  width: 100%; }

.image-and-text iframe {
  width: 100%;
  height: 100%; }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(320px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(320px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.account-subnav__item {
  display: block;
  color: #18191F;
  font-size: 21px;
  line-height: 31px;
  margin-bottom: 1.25rem; }
  .account-subnav__item:hover, .account-subnav__item.active {
    color: #4F46B8; }

.basket-block {
  padding: 1.25rem; }
  @media (max-width: 767.98px) {
    .basket-block__popout {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 0;
      left: 0;
      z-index: 200; }
    .basket-block__popout .basket-block__content {
      display: none; }
    .basket-block__popout.open {
      box-shadow: 0.75rem 0.25rem 0.75rem 0.75rem rgba(0, 0, 0, 0.25); }
    .basket-block__popout.open .basket-block__content {
      display: flex; }
    .basket-block__popout.open > .basket-block__overlay {
      height: 100%;
      opacity: 1; }
    .basket-block__total {
      padding-top: .75rem;
      padding-bottom: .75rem; }
      .basket-block__total:hover {
        cursor: pointer; }
    .basket-block:not(.basket-block__popout) {
      margin-left: -1.25rem;
      margin-right: -1.25rem; }
    .basket-block__overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background: red;
      position: fixed;
      z-index: -1;
      background: rgba(244, 245, 247, 0.8);
      opacity: 0;
      transition: all .1s; } }
  .basket-block__title {
    padding-top: 10px;
    padding-bottom: 10px; }
    .basket-block__title .h2 {
      margin: 0; }
  .basket-block__lines {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #BDBDBD; }
    .basket-block__lines:last-of-type {
      border-bottom: 1px solid #BDBDBD; }
  .basket-block__line {
    display: flex;
    justify-content: space-between;
    padding: 10px 0; }
    .basket-block__line span {
      font-size: 17px;
      line-height: 20px;
      font-weight: 400;
      font-family: "Roboto", sans-serif;
      padding-right: 0.25rem; }
  .basket-block__promo {
    color: #18191F;
    text-decoration: underline; }
    .basket-block__promo-field {
      width: 100%; }
      .basket-block__promo-field.form-field {
        display: none; }
        .basket-block__promo-field.form-field.active {
          display: block; }
    .basket-block__promo-button.button {
      width: 100%;
      display: none;
      font-size: 1rem;
      line-height: 0.75rem; }
      .basket-block__promo-button.button.active {
        display: block; }
    .basket-block__promo:hover {
      text-decoration: underline; }
  .basket-block__total {
    display: flex;
    justify-content: space-between;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
    .basket-block__total .h2 {
      margin: 0; }
    .basket-block__total svg {
      transform: rotateZ(-90deg); }
  @media (min-width: 768px) {
    .basket-block__total {
      padding: 1.25rem 0 0;
      justify-content: space-between; }
    .basket-block__title {
      padding: 0 0 1.25rem 0; }
    .basket-block__lines {
      padding: 1.25rem 0; } }

.basket-popup {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: none;
  z-index: 999; }
  .basket-popup.active {
    display: block; }
  .basket-popup__close {
    position: absolute;
    right: 0;
    top: calc(-1.25rem - 10px); }
  .basket-popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    background: rgba(244, 245, 247, 0.8);
    opacity: 0;
    transition: all .2s; }
    .basket-popup__overlay.active {
      opacity: 1; }
  .basket-popup__window {
    position: absolute;
    top: calc(50% + 10px);
    left: 50%;
    width: 100%;
    z-index: 999;
    width: 100%;
    max-width: 90%;
    background: #FFFFFF;
    border-radius: 10px;
    transition: all .2s;
    transform: translate(-50%, -60%);
    opacity: 0; }
    .basket-popup__window.active {
      opacity: 1;
      transform: translate(-50%, -50%); }
  .basket-popup__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #6C6ACD; }
  @media (min-width: 768px) {
    .basket-popup__title {
      font-size: 31px;
      line-height: 41px; }
    .basket-popup__window {
      max-width: 90%; }
    .basket-popup__row {
      color: #18191F; }
      .basket-popup__row .h2 {
        font-size: 23px; }
    .basket-popup__buttons {
      display: flex;
      justify-content: space-between; }
      .basket-popup__buttons > * {
        flex-basis: 48%; } }
  @media (min-width: 992px) {
    .basket-popup__window {
      max-width: 945px; } }

.bolton-sidebar {
  width: 300px;
  position: fixed;
  top: 0;
  left: -300px;
  height: 100vh;
  z-index: 999;
  background: white;
  transition: all 0.3s;
  overflow-y: hidden;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2); }
  @media (min-width: 768px) {
    .bolton-sidebar {
      width: 600px;
      left: -600px; } }

.bolton-sidebar.active {
  left: 0; }

.bolton-sidebar-right {
  width: 300px;
  position: fixed;
  top: 0;
  right: -100vw;
  height: 100vh;
  z-index: 999;
  background: white;
  transition: all 0.3s;
  overflow-y: auto;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2); }
  @media (min-width: 768px) {
    .bolton-sidebar-right {
      width: 600px; } }

.bolton-sidebar-right.active {
  right: 0px; }
  @media (min-width: 768px) {
    .bolton-sidebar-right.active {
      width: 600px; } }

.bolton-sidebar .dismiss, .bolton-sidebar-right .dismiss {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

.bolton-sidebar .dismiss:hover, .bolton-sidebar-right .dismiss:hover {
  color: #7386D5; }

.busy-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  transition: all 0.5s ease-in-out; }

.busy-overlay.active {
  display: block;
  opacity: 1; }

.bolton-sidebar .sidebar-header, .bolton-sidebar-right .sidebar-header {
  padding: 20px;
  background: #2f2851;
  color: #FFF;
  text-align: center; }

.card {
  padding: 22px;
  border-radius: 10px;
  transition: .2s;
  position: relative;
  box-shadow: 0px 10px 15px rgba(212, 217, 232, 0.4);
  /*&:hover {
        box-shadow: 0px 40px 50px rgba(212, 217, 232, 0.6);
    }*/ }
  .card-image {
    margin: -22px -22px 20px -22px; }
    .card-image img {
      border-radius: 10px 10px 0 0; }
  .card.animate-fade-in.animated {
    animation-name: animateInCard;
    animation-duration: 350ms;
    animation-delay: calc(var(--animation-order) * 100ms);
    animation-fill-mode: both;
    animation-timing-function: ease-in-out; }
  .card-tag {
    font-size: 16px;
    line-height: 32px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    right: 15px;
    top: 0;
    transform: translateY(-100%);
    position: absolute;
    padding: 0 10px;
    font-weight: 300; }
  .card-freetext p {
    margin: 0; }
  .card-number {
    background: #6C6ACD;
    color: #FFFFFF;
    border-radius: 50%;
    display: inline-block;
    font-size: 48px;
    width: 62px;
    height: 62px;
    line-height: 62px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(15px, -50%); }
    @media (min-width: 768px) {
      .card-number {
        position: static;
        margin: 0 auto;
        top: unset;
        right: unset;
        transform: unset;
        margin-bottom: 20px; } }
  .card-price {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 32px;
    white-space: nowrap; }
    .card-price span {
      font-size: 30px;
      line-height: 49px;
      padding-right: 8px;
      font-weight: 400; }
  @media (min-width: 768px) {
    .card {
      box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.4); } }

@keyframes animateInCard {
  0% {
    opacity: 0.6;
    transform: translateY(50px); }
  100% {
    opacity: 1; } }

.checkout-steps {
  border-bottom: 1px solid #BDBDBD; }
  .checkout-steps__step {
    width: 100%;
    align-items: center;
    display: flex;
    font-weight: 400;
    color: #828282; }
    .checkout-steps__step svg {
      transform: rotateZ(90deg); }
    .checkout-steps__step a {
      font-family: "Roboto", sans-serif;
      color: inherit;
      font-weight: inherit; }
    .checkout-steps__step.visited {
      color: #18191F; }
    .checkout-steps__step.active {
      color: #6C6ACD; }
      .checkout-steps__step.active a {
        pointer-events: none;
        color: inherit; }
      .checkout-steps__step.active svg {
        fill: #6C6ACD; }
  .checkout-steps:not(.open) .checkout-steps__step {
    display: none; }
    .checkout-steps:not(.open) .checkout-steps__step.active {
      display: flex; }
  .checkout-steps.open .checkout-steps__step {
    display: block; }
    .checkout-steps.open .checkout-steps__step svg {
      transform: rotateZ(-90deg); }
  @media (min-width: 768px) {
    .checkout-steps {
      margin: 0;
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: space-between; }
      .checkout-steps__step {
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        display: flex;
        justify-content: center; }
        .checkout-steps__step:first-child {
          padding-left: 0; }
        .checkout-steps__step:last-child {
          padding-right: 0; }
        .checkout-steps__step.active {
          font-weight: 500; }
      .checkout-steps:not(.open) .checkout-steps__step {
        height: auto;
        display: flex; }
      .checkout-steps.open .checkout-steps__step {
        display: flex; } }
  @media (max-width: 767.98px) {
    .checkout-steps.open {
      width: 100%;
      position: absolute;
      background: #FFFFFF;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-bottom: none;
      box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
      z-index: 10; } }

.checkout__accountwithus a {
  font-weight: bold; }

.checkout__accountwithus--wrap {
  border-bottom: 1px solid #BDBDBD; }

.checkout__checkoutasguest--wrap {
  background: #F3F4FD; }

.checkout__referral_block {
  background: #6C6ACD;
  color: #FFFFFF; }
  .checkout__referral_block svg {
    width: 24px;
    height: 24px;
    color: #FFFFFF; }

@media (min-width: 768px) {
  .checkout__checkoutasguest--wrap {
    background: none; }
  .checkout__accountwithus {
    border-bottom: 1px solid #BDBDBD; }
    .checkout__accountwithus--wrap {
      border: none; } }

.stripe-wrapper form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px; }

.stripe-wrapper input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white; }

.stripe-wrapper .result-message {
  line-height: 22px;
  font-size: 16px; }

.stripe-wrapper .result-message a {
  color: #596fd6;
  font-weight: 600;
  text-decoration: none; }

.stripe-wrapper #card-error {
  color: #d30c19;
  line-height: 17px;
  margin-top: 12px; }

.stripe-wrapper #card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white; }

.stripe-wrapper #payment-request-button {
  margin-bottom: 32px; }

@media only screen and (max-width: 600px) {
  .stripe-wrapper form {
    width: 80vw; } }

.button--stripepayment {
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%; }
  .button--stripepayment:hover {
    filter: contrast(115%); }
  .button--stripepayment:disabled {
    opacity: 0.5;
    cursor: default; }

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.flash-message {
  position: absolute;
  top: 100%;
  width: 100%;
  transition: top 1s, opacity 1s; }
  .flash-message.closed, .flash-message:not(.flash-message-navbar) body.nav-open .flash-message {
    opacity: 0;
    transition: opacity .4s;
    pointer-events: none; }
  .flash-message.closed {
    top: 0;
    transition: top 1s, opacity 1s; }
  .flash-message__content {
    font-weight: 300;
    font-family: "Poppins", sans-serif;
    color: #FFFFFF !important;
    text-align: center;
    position: relative; }
    .flash-message__content p {
      margin: 0; }
    .flash-message__content a {
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      text-decoration: underline;
      color: #FFFFFF; }
      .flash-message__content a:hover {
        color: #FFFFFF !important; }
  .flash-message__close {
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: flex-end;
    right: 1.25rem;
    top: 0;
    height: 100%; }
    .flash-message__close svg {
      margin-right: calc(calc(1.875rem - 1.25rem) / 3); }
  .flash-message__container {
    position: relative; }
  @media (min-width: 768px) {
    .flash-message__content {
      text-align: center; } }

@media (min-width: 768px) {
  .flash-message-navbar {
    top: 40px; } }

.openhouse .button--accordion-nav {
  background: #a50022;
  color: #FFFFFF;
  border-color: #a50022; }
  .openhouse .button--accordion-nav:hover {
    background: #a50022;
    color: #FFFFFF;
    border-color: #a50022; }
  .openhouse .button--accordion-nav.active {
    background: #a50022;
    color: #FFFFFF;
    border-color: #a50022; }
    .openhouse .button--accordion-nav.active:hover {
      background: #a50022;
      color: #FFFFFF;
      border-color: #a50022; }

.button {
  display: block;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 30px;
  background: #6C6ACD;
  border: 1px solid #6C6ACD;
  color: #FFFFFF;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  border-radius: 8px;
  padding: 14px 2rem; }
  .openhouse .button {
    background: #a50022;
    border: 1px solid #a50022;
    color: #FFFFFF; }
  .button:hover {
    text-decoration: none;
    color: #fff;
    background: #4644c0; }
    .openhouse .button:hover {
      background: #720017; }
    .button:hover svg {
      fill: #fff; }
  .button--white {
    background: #FFFFFF;
    color: #6C6ACD; }
    .button--white_alt {
      background: #FFFFFF;
      color: #6C6ACD; }
      .button--white_alt:hover {
        color: #FFFFFF;
        background: #6C6ACD;
        border: 1px solid; }
  .button--nav {
    padding: 6px 40px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400; }
  .button--mobilenav {
    width: 50%;
    border-radius: 0;
    font-size: 18px;
    font-weight: 400;
    padding: 17px .25rem; }
  .button--inline {
    display: inline-block; }
  .button--large {
    width: 100%; }
    @media (min-width: 768px) {
      .button--large {
        width: auto;
        padding: 0 100px; } }
  .button--accordion-nav {
    background: #F6F7F9;
    color: #18191F;
    border-color: #F6F7F9;
    text-align: center;
    padding: 13px 2px;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s; }
    .button--accordion-nav:hover {
      background: #4F46B8;
      color: #FFFFFF;
      border-color: #4F46B8;
      transform: scale(1.015); }
    .button--accordion-nav.active {
      background: #6C6ACD;
      color: #FFFFFF;
      border-color: #6C6ACD; }
      .button--accordion-nav.active:hover {
        background: #4F46B8;
        color: #FFFFFF;
        border-color: #4F46B8; }
    @media (min-width: 768px) {
      .button--accordion-nav {
        padding: 16px 8px;
        margin-bottom: 12px; } }
  .button.reduced-padding {
    padding: .5rem; }
    .button.reduced-padding-x {
      padding-left: .5rem;
      padding-right: .5rem; }
    .button.reduced-padding-y {
      padding-top: .5rem;
      padding-bottom: .5rem; }

a.arrow svg {
  transition: all 200ms ease-in-out;
  top: 5px;
  margin-left: 12px;
  position: relative; }

a.arrow:hover svg {
  transform: translateX(12px); }

.hyperlink--underline {
  text-decoration: underline; }
  .hyperlink--underline:hover {
    text-decoration: underline; }

.hyperlink--block {
  display: block; }

.hyperlink--black {
  color: #18191F;
  font-family: "Roboto", sans-serif; }

.hyperlink--bolder {
  font-weight: 500; }

.logo {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  /*svg {
        width: 18px;
        height: 18px;
    }*/ }
  .logo span {
    padding-left: 11px; }
  .openhouse .logo img {
    max-width: 150px; }
  .logo__mobile-nav {
    padding-top: 15px; }
    .openhouse .logo__mobile-nav {
      padding-top: 0; }
      .openhouse .logo__mobile-nav img {
        max-width: 120px; }

.order-status-list__item {
  margin-bottom: 30px;
  display: flex;
  align-items: center; }
  .order-status-list__item:last-child {
    margin-bottom: 0; }
  .order-status-list__item svg {
    flex: 0 0 29px;
    margin-right: 1.25rem; }
  .order-status-list__item__date {
    color: #828282;
    display: block;
    font-size: 16px; }
  .order-status-list__item__title {
    display: block;
    color: #18191F;
    font-size: 16px; }

.basket__product {
  border-top: 1px solid #BDBDBD;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #BDBDBD; }
  .basket__product-left {
    width: 70%; }
  .basket__product-right {
    text-align: right;
    width: 30%; }
  .basket__product-info {
    font-weight: 400;
    font-size: 16px; }
  .basket__product-test-kit {
    padding-top: 1.25rem; }
  .basket__product-add-ons {
    font-size: 16px;
    line-height: 24px; }
  .basket__product h3, .basket__product .h3 {
    font-size: 23px;
    line-height: 34px;
    font-weight: 400;
    margin: 0; }
  .basket__product-remove {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #828282; }
    .basket__product-remove:hover {
      color: #4f4f4f; }

.section {
  padding: 40px 0; }
  .section-p-b {
    padding-bottom: 40px; }
  .section-p-t {
    padding-top: 40px; }
  .section--large {
    padding: 40px 0 100px; }
  .section--larger {
    padding: 40px 0 80px; }
  .section-title {
    font-family: "Poppins", sans-serif;
    font-size: 48px;
    line-height: 64px;
    margin: 10px 0; }
    .section-title--wrap {
      margin-bottom: 50px; }
  .section-subtitle {
    font-family: "Roboto", sans-serif;
    font-size: 23px;
    line-height: 32px;
    font-weight: 300; }
  .section--infection-extra p:last-of-type a {
    display: inline-block;
    text-decoration: none;
    font-family: Poppins,sans-serif;
    font-size: 1.125rem;
    line-height: 1.875rem;
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    border-radius: .5rem;
    padding: .875rem 2rem;
    border: 1px solid;
    color: #FFFFFF;
    background: #6C6ACD;
    border-color: #6C6ACD; }
    .section--infection-extra p:last-of-type a:hover {
      text-decoration: none;
      color: #FFFFFF;
      background: #4644c0; }
      .section--infection-extra p:last-of-type a:hover svg {
        fill: #FFFFFF; }
  .bg-purple .section--infection-extra p:not(:last-child) a {
    color: #18191F; }
  .bg-purple .section--infection-extra p:last-child a {
    color: #6C6ACD;
    background: #FFFFFF;
    border-color: #FFFFFF; }
    .bg-purple .section--infection-extra p:last-child a:hover {
      text-decoration: none;
      color: #6C6ACD;
      background: #e6e6e6;
      border-color: #e6e6e6; }
      .bg-purple .section--infection-extra p:last-child a:hover svg {
        fill: #6C6ACD; }
  .bg-grey .section--infection-extra p:last-child a {
    color: #F6F7F9;
    background: #6C6ACD;
    border-color: #6C6ACD; }
    .bg-grey .section--infection-extra p:last-child a:hover {
      text-decoration: none;
      color: #F6F7F9;
      background: #4644c0;
      border-color: #4644c0; }
      .bg-grey .section--infection-extra p:last-child a:hover svg {
        fill: #F6F7F9; }
  @media (min-width: 768px) {
    .section {
      padding: 50px 0; }
      .section-title--wrap {
        margin-bottom: 70px; }
      .section--larger {
        padding: 40px 0 160px; } }

/* Generated from 
        <link rel="preload" as="style" href="https://fonts.googleapis.com/css?family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
    with https://github.com/mmastrac/webfont-dl
*/
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  font-display: optional;
  src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2"); }

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2"); }

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2"); }

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2"); }

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2"); }

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2"); }

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-display: optional;
  src: url("../fonts/manrope-2a5561ab13b67069fba4b166a02312bead514409.woff2") format("woff2"), url("../fonts/manrope-790547402343692df1ac86096da31bbabd0e3c54.woff2") format("woff2"), url("../fonts/manrope-4b656aed01bb4e7279631bfdcac80b1ebd4f8b7e.woff2") format("woff2"), url("../fonts/manrope-7e6fe35c8b971df01fc708a30241f898f7002af2.woff2") format("woff2"), url("../fonts/manrope-b56d92590212c9860073c9cedfeda064e2b1f191.woff2") format("woff2"), url("../fonts/manrope-980b449066ba5b8a2a36e0c41b55f68e4014b94a.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: optional;
  src: url("../fonts/poppins-ad4ef68574931a304dc66ed1ed3952c063c055f7.woff2") format("woff2"), url("../fonts/poppins-307f6ed4defdf3d88da08de7f76322fbfda9f138.woff2") format("woff2"), url("../fonts/poppins-13907723256082238f2d94d469319d85910f0859.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: optional;
  src: url("../fonts/poppins-ca3f44e6d7eff6e1b1c03118cddf4b67fc97feca.woff2") format("woff2"), url("../fonts/poppins-e279ef0a72ea7780a182c82393e38a1c80fbedf3.woff2") format("woff2"), url("../fonts/poppins-130e001df902031f2e1352b069815c5e84296e46.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: optional;
  src: url("../fonts/poppins-9d73d108a7aeea80874d6e5ef8580b428ed49e39.woff2") format("woff2"), url("../fonts/poppins-e5a1f40cc721b852d2593f73ac13e4a8a8d280e2.woff2") format("woff2"), url("../fonts/poppins-a3e7876a3bb8b3a8c38bc8dbef51b1140b51b38d.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: optional;
  src: url("../fonts/poppins-42adbc6609a01ab61b2f5966e52bb4e33522de7d.woff2") format("woff2"), url("../fonts/poppins-79d61f8cf8377508651416ce8dded1352ebf8455.woff2") format("woff2"), url("../fonts/poppins-d70e2c70b21cbb66cd883ae56e2dedacefd81c7c.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: optional;
  src: url("../fonts/poppins-51566f1960f205c162068a8454e55c410d64cd6f.woff2") format("woff2"), url("../fonts/poppins-febfaaae13418ab3dbe80f87da1bda65ef6aa70c.woff2") format("woff2"), url("../fonts/poppins-a99a6d584385f86f84e893330f6ae158372b5d63.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: optional;
  src: url("../fonts/poppins-d5e36bc91f851a67069bd0f90b4e54f94a826ba4.woff2") format("woff2"), url("../fonts/poppins-254ef26022774b1bf1b66441ee1ce8a0592be083.woff2") format("woff2"), url("../fonts/poppins-85397079f9f963cd2f16dc5600d8d7795924d73d.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: optional;
  src: url("../fonts/poppins-a3f82fffffb29023e6ae4d7b8ac26308af08e4eb.woff2") format("woff2"), url("../fonts/poppins-96ca2860f9562438d854a87d209e3b1e6311e9bd.woff2") format("woff2"), url("../fonts/poppins-50900cbabf4ae9e1e174162f091404e343585c65.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: optional;
  src: url("../fonts/poppins-103144bcab154e8945ecdaded9d33103093d8189.woff2") format("woff2"), url("../fonts/poppins-c085bc6f04239daafd68ff54dae35ff41265a4e2.woff2") format("woff2"), url("../fonts/poppins-6d7575264299324b44d58aed8236eeb99d5b6671.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: optional;
  src: url("../fonts/poppins-ff7147fc01d58d9da1e6e55730a8e0af54213da0.woff2") format("woff2"), url("../fonts/poppins-23ab8ae99ff82b7c05c1980e0e0c9b79913300b4.woff2") format("woff2"), url("../fonts/poppins-47c920cbd187e62be2402c00da8aa2c9884dfae9.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: optional;
  src: url("../fonts/poppins-cf71855ab65a3ec625455d4c245cb2e68dd695cc.woff2") format("woff2"), url("../fonts/poppins-068da06cb4da2b139043e8d3222c77c041d3244e.woff2") format("woff2"), url("../fonts/poppins-9bb181e467942bd18582fd1aa001431ac2d781f5.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: optional;
  src: url("../fonts/poppins-4dea2e59f584bda246e5a72894d21ded8577e5e9.woff2") format("woff2"), url("../fonts/poppins-71a6a9d0f9f8a7a4e39ec96d6600ea540703c8aa.woff2") format("woff2"), url("../fonts/poppins-2c5f99afe358a3e8570818a99646779aaa607587.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src: url("../fonts/poppins-95b16d2eaeef6c91f39e23dad559d286139c40c6.woff2") format("woff2"), url("../fonts/poppins-71aeaf66680e4e7d5ffed53bf78eba3d7decca70.woff2") format("woff2"), url("../fonts/poppins-a9e9bafe64666f4595051a0e895b47a5fa39e67e.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url("../fonts/poppins-41e56550cc8f19a7f65c16041658eedbf888cc98.woff2") format("woff2"), url("../fonts/poppins-65af2a037a5ef8a8d383d518377ea1f9f6837631.woff2") format("woff2"), url("../fonts/poppins-ac6d71b4d5fdd2b3dabc9a06ff6c001e4251da0b.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url("../fonts/poppins-491f7b3336cda454a52a310ce89970aa634af004.woff2") format("woff2"), url("../fonts/poppins-334853f61ceb1fb096818740cc62d5840fbbae46.woff2") format("woff2"), url("../fonts/poppins-0da2d17e738f46d2a09e6fb7969da451719a9820.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url("../fonts/poppins-09c9cfa499178fa61dd06c4d679730702cd0134f.woff2") format("woff2"), url("../fonts/poppins-3a4cf2dc2cabd33b5b2c7cef3221131520f618a0.woff2") format("woff2"), url("../fonts/poppins-179f97ec0275f09603a8db94d4380eb584d81cd5.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url("../fonts/poppins-f189f54e850a173531b4227572193dc151cf0694.woff2") format("woff2"), url("../fonts/poppins-72c3ac29b2fcceea390d3a51c7a892efde65e4d9.woff2") format("woff2"), url("../fonts/poppins-cb726212d5d525021752a1d8470a0fb593e0c49e.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: optional;
  src: url("../fonts/poppins-9616b9d5e233128d8a7507d07f191b4c946120eb.woff2") format("woff2"), url("../fonts/poppins-858a2b9dc3a53a334b066eed777536ad4ca0b69f.woff2") format("woff2"), url("../fonts/poppins-6d11d743bc3cfb169d70bc86450f18351dc1a905.woff2") format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: optional;
  src: url("../fonts/poppins-a3c7906fb565efd19f130aa948156b8c4b73d8ea.woff2") format("woff2"), url("../fonts/poppins-a7c17e2b7c20fee418d5611b988066f9e986b186.woff2") format("woff2"), url("../fonts/poppins-ec0cbbcb4600e691cb24a63451f758727f90a306.woff2") format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: optional;
  src: url("../fonts/roboto-3245ae77dfbc7ce7883b7aa74c7d7bd4fb3b759d.woff2") format("woff2"), url("../fonts/roboto-d8ffd6c3f7391d483e8e8f3a3ced69b35d7aeec6.woff2") format("woff2"), url("../fonts/roboto-3318e951845205b2ec4d6800d8104aff2d86dde0.woff2") format("woff2"), url("../fonts/roboto-7151f2c26dada82151aa985b8ef3f0367684ea12.woff2") format("woff2"), url("../fonts/roboto-bca69e826168b2f896d3c10c7ded30acb3b28385.woff2") format("woff2"), url("../fonts/roboto-f953a0140b948f07f720632126be5dde787dc4d0.woff2") format("woff2"), url("../fonts/roboto-e34a4b9b4081c9a2ff232d5c110309d40fff74a0.woff2") format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: optional;
  src: url("../fonts/roboto-587dcbaed846d35331c29d471d590f0f1a2f4c51.woff2") format("woff2"), url("../fonts/roboto-2e2a3bfdaaf856780a7018bedf879867267df120.woff2") format("woff2"), url("../fonts/roboto-ac867c6e75ba0a969747477f7ab2f34f85bc786e.woff2") format("woff2"), url("../fonts/roboto-bb8d2e03806fa3620d277a70ae24da44df018322.woff2") format("woff2"), url("../fonts/roboto-ce49123d786b0f72fb90695f091dee251fc5a6e5.woff2") format("woff2"), url("../fonts/roboto-bd477bffd5842531d2281f9f14195aab24a598f2.woff2") format("woff2"), url("../fonts/roboto-a35afbb7b7b2c5152c4032f8b2373f47056dfd06.woff2") format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: optional;
  src: url("../fonts/roboto-5bdccf3db53d2b0a3f661dd77a5b2665918e0b7f.woff2") format("woff2"), url("../fonts/roboto-adea83ea580e66c52ac8f0286204bb1e7ed65175.woff2") format("woff2"), url("../fonts/roboto-3e27ac8981b1ecb36512e8b57fd841c2371e925f.woff2") format("woff2"), url("../fonts/roboto-256a8e72074e17baa995c018fb583a822b6f802c.woff2") format("woff2"), url("../fonts/roboto-489fdde5a148ef137d203171033d2db73812555d.woff2") format("woff2"), url("../fonts/roboto-b650dec86ded3af19e0c6509844e6f8e5dc4ab6b.woff2") format("woff2"), url("../fonts/roboto-af097f3155ad953db0254a2da254a41c09fff18d.woff2") format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: optional;
  src: url("../fonts/roboto-769ccc3e4a7f3f416ed7dd16d50addb5a0b45719.woff2") format("woff2"), url("../fonts/roboto-363bdd04e0b99e7b3f476fd139ef4b1327e8dee4.woff2") format("woff2"), url("../fonts/roboto-f3391f881ca9ceb6c1e62c7249ea38165439a748.woff2") format("woff2"), url("../fonts/roboto-e5b978a1710dc1e87462f9852cdbcb811ca8a395.woff2") format("woff2"), url("../fonts/roboto-3efc04de0b4d866be500a258065dbd8970e853f5.woff2") format("woff2"), url("../fonts/roboto-a0715173094f3bd702a2c40dee512cf0c68ebd04.woff2") format("woff2"), url("../fonts/roboto-50fed1f6b3de963d870ff4de8a2021ddee021a3a.woff2") format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: optional;
  src: url("../fonts/roboto-4183be331b5c8aba86a481f28e95d7b25b249209.woff2") format("woff2"), url("../fonts/roboto-633ea130d48236241a7badb4d0d31bc3f7324717.woff2") format("woff2"), url("../fonts/roboto-b4ecaaf0186fc692abd46dc3bcbb9dbe6ffbce70.woff2") format("woff2"), url("../fonts/roboto-02d951f59dc3d5ffd24ff0102d7f865727f47c9b.woff2") format("woff2"), url("../fonts/roboto-979ff8d5a7bbd2bb2904a975128e1dc6d2e0477c.woff2") format("woff2"), url("../fonts/roboto-45f40446f2334c071d2a1881b5c759ac659f281a.woff2") format("woff2"), url("../fonts/roboto-5487e623781da7b713f9a572ccd59dd7c49695f0.woff2") format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: optional;
  src: url("../fonts/roboto-913f38f8fe55711764194563974c8a2d900f1817.woff2") format("woff2"), url("../fonts/roboto-e1afc8253ba75dcc4ff40b9a6cc33234bd8fac88.woff2") format("woff2"), url("../fonts/roboto-fbce0043dab61dcb9600e9fc79cc5820f5d92094.woff2") format("woff2"), url("../fonts/roboto-144d5aeaf71f50949e6b49f4c695c8f151306e7c.woff2") format("woff2"), url("../fonts/roboto-528ba5b57e3d3b46f4e99dbeb52fe5c1ba7e3d98.woff2") format("woff2"), url("../fonts/roboto-a20a1cec08c6ebcb75889997701d2a1f8cc7a1a8.woff2") format("woff2"), url("../fonts/roboto-5145adafcd5e4b32841cfaa8d66c3d03c434d56d.woff2") format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: optional;
  src: url("../fonts/roboto-621035e11f7d7c480cfd8b7b79fddc9c5260e099.woff2") format("woff2"), url("../fonts/roboto-5e8350b9ddf0ed027cb34ec50718069e4994bb48.woff2") format("woff2"), url("../fonts/roboto-043b448bfb5a0ed2ca04b19bd7670660aa5d6f09.woff2") format("woff2"), url("../fonts/roboto-431195d7035760b002b00b47c618ae642827ec05.woff2") format("woff2"), url("../fonts/roboto-c2376de049f99838a3b455de58057996e0b94a8d.woff2") format("woff2"), url("../fonts/roboto-87198ca28741832168aff633ba34857667bf06e5.woff2") format("woff2"), url("../fonts/roboto-37ac52a84595c236122c73c37ca226837ddab9ea.woff2") format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src: url("../fonts/roboto-6ab00672c03f4e72fe34d59d14e0f102c89672aa.woff2") format("woff2"), url("../fonts/roboto-46eb7c8d2f3d5f3d471209ea0a30c5c80f28a365.woff2") format("woff2"), url("../fonts/roboto-05155805a63fb3bcb21028318d00587c5f150b4e.woff2") format("woff2"), url("../fonts/roboto-3054bf92a43dd94647e3d5fd0ff2ff6061a459b8.woff2") format("woff2"), url("../fonts/roboto-e628016d3f7789c6243afbae2c37082879f818a4.woff2") format("woff2"), url("../fonts/roboto-77675b59ec683e2e08834b390ec0cd287895a565.woff2") format("woff2"), url("../fonts/roboto-34f751a1b1a0c1c0b5264b99f490e689db939657.woff2") format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url("../fonts/roboto-cdbb48c38232d91f1188e705d0fac22f9819a874.woff2") format("woff2"), url("../fonts/roboto-6047396fdc25a0a49feb905f9d0bd01b8dc3ec77.woff2") format("woff2"), url("../fonts/roboto-13433fe24f42e4924db9c37dee985c821fe6d9d6.woff2") format("woff2"), url("../fonts/roboto-3a1e2980c382f4c071cb7d0fb8739b59c8113f73.woff2") format("woff2"), url("../fonts/roboto-081d22a7a51d4c026f526d8fb0d876de25eff5b4.woff2") format("woff2"), url("../fonts/roboto-4b4af153e8473e16663ae7f2e01635bf58979e2e.woff2") format("woff2"), url("../fonts/roboto-106ac454ba4e503e0a1cd15e1275130918049182.woff2") format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url("../fonts/roboto-b2dbc2933af280a58e3ddd492d35c2f4282749cd.woff2") format("woff2"), url("../fonts/roboto-3fc82ed7cfee5ff1ee9fcdf2fa4c099cfb909fbf.woff2") format("woff2"), url("../fonts/roboto-df70c1e8efe17d32de0f0dde5fec1ceb91482fae.woff2") format("woff2"), url("../fonts/roboto-a1467ca362e26bf72be79ac9c7fbe268cb321386.woff2") format("woff2"), url("../fonts/roboto-f2a4b910f3e1aff3919efd5077e88e43c5175455.woff2") format("woff2"), url("../fonts/roboto-49b74c9b5810671ba1213050bc801f56c598a8f5.woff2") format("woff2"), url("../fonts/roboto-d836c7d4bc52bcd67626b8960ae030ad315c2507.woff2") format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url("../fonts/roboto-a0c07347d6463ef7ee80a69224541848d7aca71c.woff2") format("woff2"), url("../fonts/roboto-60f0e2acff4e2d29f88204afaa61019bd3962ca6.woff2") format("woff2"), url("../fonts/roboto-9cd9fb9ceb0bd40bce7f9ccfe90e145dfb93df7e.woff2") format("woff2"), url("../fonts/roboto-3ff863937fd4fa5466d1c88cce2b2d96958070db.woff2") format("woff2"), url("../fonts/roboto-c962167c4a3ac8ab2a83f7c591b0ac0910bc5a83.woff2") format("woff2"), url("../fonts/roboto-9c9c45cbcf9baa9ab6b4c4c743dd5d968eab5ea2.woff2") format("woff2"), url("../fonts/roboto-c706e0a4122ab727645b744c21667390e8898a4d.woff2") format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: optional;
  src: url("../fonts/roboto-5d17e50297ac891ac41fd87526f7ff11c5b99a89.woff2") format("woff2"), url("../fonts/roboto-d041edb3d253caf2f7892a4644d0ea8e40d01c6e.woff2") format("woff2"), url("../fonts/roboto-d7f0e58ad1e803af33efd9aa8f6ed187b9f5cc3b.woff2") format("woff2"), url("../fonts/roboto-3d75bcc3a9d8c55395ac57cb8a7727db3643c97c.woff2") format("woff2"), url("../fonts/roboto-df792bb0898c3f638762406bc777b42014af6725.woff2") format("woff2"), url("../fonts/roboto-c9699e784698564ea105fd62d3f854da500aeb2a.woff2") format("woff2"), url("../fonts/roboto-f4a1bb63ac600302e23e8585eb00b6c99f232ed5.woff2") format("woff2"); }

.footer-main--text {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 24px; }

.footer-main--social {
  padding-right: 11px; }

.footer-main--text {
  margin: 0;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #DCDCDC;
  line-height: 26px; }
  .footer-main--text p {
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #DCDCDC;
    line-height: 26px; }

.footer-main--contact {
  color: #FFFFFF; }
  .footer-main--contact a {
    color: #FFFFFF; }
  .footer-main--contact:hover, .footer-main--contact a:hover {
    text-decoration: none;
    color: #9290da; }

.footer__link {
  color: #FFFFFF; }
  .footer__link a {
    color: #FFFFFF; }
  .footer__link:hover, .footer__link a:hover {
    text-decoration: none;
    color: #9290da; }

.footer__logo {
  max-height: 60px; }

.footer-sub--link, .footer-sub--copyright {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  line-height: 30px; }
  .footer-sub--link + span, .footer-sub--copyright + span {
    line-height: 30px; }

.footer-sub--link {
  color: #DCDCDC; }
  .footer-sub--link a {
    color: #DCDCDC; }
  .footer-sub--link:hover, .footer-sub--link a:hover {
    text-decoration: none;
    color: #9290da; }
  .openhouse .footer-sub--link {
    color: #DCDCDC; }
    .openhouse .footer-sub--link a {
      color: #DCDCDC; }
    .openhouse .footer-sub--link:hover, .openhouse .footer-sub--link a:hover {
      text-decoration: none;
      color: #e00d37; }

@media (min-width: 768px) {
  .footer-main--link {
    width: 25%; }
  .footer-main--contact {
    margin-bottom: 5px; }
  .footer-sub--link, .footer-sub--copyright {
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    line-height: 24px; }
    .footer-sub--link + span, .footer-sub--copyright + span {
      line-height: 24px; } }

@media (min-width: 992px) {
  .footer-main--link {
    max-width: 20%; } }

.form-field {
  display: block;
  padding: 12px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #18191F;
  background-color: #FFFFFF;
  border: 1px solid #828282;
  border-radius: 8px;
  position: relative; }
  .form-field--selectwrapper {
    padding: 0;
    border: 0; }
  .form-field:disabled {
    opacity: 0.8;
    background: #E7E7E7; }
  .form-field-label {
    font-size: 18px;
    display: block;
    margin-bottom: .5rem; }
    .form-field-label--small {
      font-size: 16px;
      font-weight: 300; }
  .form-field__dropdown {
    position: relative;
    display: block; }
    .form-field__dropdown input.form-field:hover {
      cursor: pointer; }
    .form-field__dropdown-items {
      display: none;
      position: absolute;
      top: 100%;
      width: 100%;
      background: #FFFFFF;
      z-index: 10;
      border: 1px solid #828282;
      border-top-color: white;
      overflow: hidden scroll;
      max-height: 250px; }
    .form-field__dropdown-arrow {
      background: #E0E0E0;
      position: absolute;
      right: 1px;
      width: 49px;
      height: calc(100% - 2px);
      top: 1px;
      transition: all .2s;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      right: 0.05rem; }
      .form-field__dropdown-arrow-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: .7rem;
        pointer-events: none; }
      .form-field__dropdown-arrow svg {
        color: #828282;
        fill: #828282; }
    .form-field__dropdown-item {
      padding: 0.75rem;
      display: block;
      color: #18191F;
      transition: 0s;
      font-size: 18px;
      font-family: "Roboto", sans-serif; }
      .form-field__dropdown-item:hover {
        background: #6C6ACD;
        color: #FFFFFF; }
    .form-field__dropdown.open input.form-field {
      border-bottom: 1px solid #FFFFFF;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .form-field__dropdown.open .form-field__dropdown-items {
      display: block; }
    .form-field__dropdown.open .form-field__dropdown-arrow {
      background: #FFFFFF; }
      .form-field__dropdown.open .form-field__dropdown-arrow svg {
        transform: rotateZ(180deg); }

.form--dark .form-field {
  background-color: white; }

.form-subfields {
  border-left: 4px solid #D8D8D8; }

input:not(:checked) ~ .form-subfields {
  display: none; }

.form__checkbox {
  display: flex;
  align-items: center;
  position: relative; }
  .form__checkbox--tooltip {
    display: flex; }
    .form__checkbox--tooltip:hover {
      cursor: pointer; }
    .form__checkbox--tooltip-popup {
      background: #FFFFFF;
      color: #18191F;
      padding: 20px;
      display: none;
      position: absolute;
      left: 0;
      bottom: calc(100% + 20px);
      border-radius: 10px;
      box-shadow: 0px 5px 25px rgba(212, 217, 232, 0.7);
      max-width: 100%;
      width: 500px;
      z-index: 1; }
    svg:active + .form__checkbox--tooltip-popup, svg:hover + .form__checkbox--tooltip-popup, svg.active + .form__checkbox--tooltip-popup, svg:focus + .form__checkbox--tooltip-popup {
      display: block; }

.form__checkbox input {
  position: absolute;
  left: -99999em;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.form__checkbox input:checked ~ label:before {
  content: url("/assets/images/tick.svg");
  background-color: #FFFFFF;
  border: 1px solid #18191F; }

.form__checkbox label {
  display: inline-block;
  position: relative;
  padding-left: calc(24px + 1rem);
  color: #000;
  cursor: pointer;
  line-height: 24px; }

.form__checkbox--white label {
  color: #fff; }

.form__checkbox label:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
  background-color: #fff;
  height: 24px;
  width: 24px;
  border: 1px solid #18191F;
  border-radius: 5px;
  text-align: center; }

.form-date {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .form-date label {
    flex: 0 0 100%; }
  .form-date .form-field {
    text-align: center;
    flex: 0 0 calc(33% - 5px); }
  @media (min-width: 992px) {
    .form-date {
      justify-content: flex-start; }
      .form-date .form-field {
        max-width: 80px;
        margin-right: 5px; } }

.form-address-locator {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .form-address-locator label {
    flex: 0 0 100%; }
  .form-address-locator .form-field, .form-address-locator .button {
    flex: 0 0 48%; }
  @media (max-width: 767.98px) {
    .form-address-locator .button {
      padding: .25rem .5rem;
      font-size: 14px; } }

.form-explanatory-text {
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-top: 10px; }

.form-password {
  position: relative; }
  input[type=text] + .form-password-icon {
    color: #4F46B8;
    fill: #4F46B8; }
  .form-password-icon {
    position: absolute;
    height: 100%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    color: #BDBDBD;
    fill: #BDBDBD; }
    .form-password-icon svg {
      width: 30px;
      height: 30px;
      pointer-events: none; }

.form__contact .form-field-label {
  font-size: 16px; }

input[type="text"],
input[type="email"],
select,
textarea,
input[type="number"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none; }
  input[type="text"].form-field,
  input[type="email"].form-field,
  select.form-field,
  textarea.form-field,
  input[type="number"].form-field {
    width: 100%; }

.field-validation-error {
  display: inline-block;
  margin-top: 0.5rem;
  color: red; }

.button {
  cursor: pointer; }

.umbraco-forms-tooltip {
  color: #828282;
  font-size: 12px;
  line-height: 16px; }

header, body, nav {
  transition: none; }

body.nav-open {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%; }
  body.nav-open .nav {
    height: 100%;
    max-height: 100%;
    position: absolute;
    width: 100%;
    top: 0; }
    body.nav-open .nav--overlay {
      display: block; }
  @media (min-width: 768px) {
    body.nav-open .nav {
      height: auto; }
      body.nav-open .nav__menu--overlay {
        display: block;
        z-index: -1; } }

.openhouse .nav-open .nav__menu--link-dropdown.active svg {
  transform: rotateZ(-180deg); }

.testme .nav-open .nav__menu--link-dropdown svg {
  transform: rotateZ(-180deg); }

.openhouse .nav__cart-link.has-shopping::after {
  background: #d3002a; }

.nav {
  position: relative;
  z-index: 100; }
  .nav__opener, .nav__closer {
    display: flex; }
  .nav__link {
    font-size: 18px; }
    .nav__link:hover {
      text-decoration: none; }
    .openhouse .nav__link {
      font-size: 1rem; }
  .nav__cart-link {
    display: flex;
    align-items: center; }
    .nav__cart-link.has-shopping {
      position: relative; }
      .nav__cart-link.has-shopping::after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #6C6ACD;
        top: 0;
        right: 0;
        transform: translateX(50%); }
  .nav .mobile-cart.has-shopping {
    position: relative; }
    .nav .mobile-cart.has-shopping::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #d3002a;
      top: 0;
      right: 0;
      transform: translateX(50%); }
  .nav .js-nav-close {
    display: none; }
  .nav-open .js-nav-open-mob {
    display: none; }
  .nav-open .js-nav-close {
    display: flex; }
  .nav__mobile-wrap {
    padding: 22.5px 0;
    z-index: 100;
    background: #FFFFFF;
    height: 75px;
    border-bottom: 1px solid #bdbdbd; }
  .nav__desktop-wrap {
    padding: 1rem 0;
    background: #FFFFFF;
    border-bottom: 1px solid #bdbdbd; }
  .nav__menu {
    width: 100%;
    position: fixed;
    pointer-events: none;
    height: auto;
    transition: top 0.4s, opacity 0.4s;
    overflow: hidden scroll;
    max-height: 100%;
    opacity: 0;
    top: -100%;
    background: #FFFFFF; }
    .nav__menu.active {
      pointer-events: auto;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      opacity: 1;
      top: 75px;
      height: calc(100% - 75px); }
    .nav__menu--overlay {
      position: fixed;
      height: 100vh;
      top: 0;
      width: 100%;
      background: rgba(244, 245, 247, 0.8);
      display: none; }
    .nav__menu--links {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      overflow: hidden; }
    .nav__menu--group {
      width: 100%;
      margin: 0 auto;
      max-width: 33.75rem; }
    .nav__menu--link-dropdown {
      display: flex;
      align-items: center;
      color: #18191F; }
      .nav__menu--link-dropdown svg {
        margin-left: 5px;
        color: #6C6ACD;
        transition: all .4s; }
    .nav__menu--link:hover {
      text-decoration: none; }
    .nav__menu--title {
      font-weight: 500;
      font-size: 23px;
      line-height: 35px;
      margin-bottom: 30px;
      font-family: "Poppins", sans-serif; }
    .nav__menu--col1 {
      background: #FFFFFF; }
    .nav__menu--col2 {
      background: #F3F4FD; }
    .nav__menu--extra-nav-items {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; }
    .nav__menu--mobile .nav__subnav {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100%; }
    .nav__menu--mobile__item {
      border-bottom: 1px solid #bdbdbd; }
      .nav__menu--mobile__item-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #18191F;
        padding: 1rem 1.25rem;
        font-size: 23px; }
        .nav__menu--mobile__item-link:hover {
          text-decoration: none; }
      .nav__menu--mobile__item-links {
        height: 0;
        transition: height .2s;
        overflow: hidden; }
        .nav__menu--mobile__item-links--inner {
          padding: 1rem 1.25rem; }
        .nav__menu--mobile__item-links--title {
          font-weight: 500;
          font-size: 23px;
          line-height: 35px;
          margin-bottom: .75rem;
          font-family: "Poppins", sans-serif; }
      .nav__menu--mobile__item-link.open + .nav__menu--mobile__item-links {
        height: auto; }
  @media (min-width: 768px) {
    .nav__menu {
      overflow: hidden;
      position: absolute;
      left: 0;
      max-height: none;
      background: linear-gradient(90deg, #FFFFFF 50%, #F3F4FD 50%);
      z-index: -1; }
      .openhouse .nav__menu {
        background: linear-gradient(90deg, #FFFFFF 50%, #fff1f4 50%); }
      .nav__menu--links {
        max-height: 225px; }
      .nav__menu.active {
        height: auto;
        top: calc(100% + 1px); }
      .nav__menu--col1, .nav__menu--col2 {
        background: none; }
      .nav__menu--group {
        max-width: unset; }
      .nav__menu--container {
        max-width: 45rem; } }
  @media (min-width: 992px) {
    .nav__menu--container {
      max-width: 60rem; } }
  @media (min-width: 1200px) {
    .nav__menu--container {
      max-width: 75rem; } }

.accordion__item {
  border-bottom: 1px solid #828282; }
  .accordion__item__name {
    color: #18191F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: .75rem 0; }
    .accordion__item__name svg {
      transition: all .2s;
      transform: rotateZ(90deg); }
    .accordion__item__name.active svg {
      transform: rotateZ(-90deg); }
    .accordion__item__name:hover {
      color: #18191F; }
  .accordion__item__content {
    display: none; }

.accordion__nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .accordion__nav .button--accordion-nav {
    flex: 0 0 100%; }

@media (min-width: 768px) {
  .accordion__item__name {
    padding: 1rem 0; }
  .accordion__nav {
    display: block; } }

.openhouse .article-arrow .article-arrow-svg, .openhouse .article-arrow .article-arrow-text {
  vertical-align: middle;
  display: inline-block; }

.openhouse .article-arrow.prev-arrow svg {
  transform: rotate(180deg); }

section.block-item {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }

section.block-item.diagonalimage .main-row {
  background-color: #f6f7f9; }
  @media (min-width: 768px) {
    section.block-item.diagonalimage .main-row {
      min-height: 300px; } }
  section.block-item.diagonalimage .main-row .image-holder {
    min-height: 300px; }

section.block-item.diagonalimage h1 {
  margin-block-start: 0; }

@media (max-width: 768px) {
  section.block-item.diagonalimage h1 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  section.block-item.diagonalimage h4 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-block-end: 0;
    margin-block-start: 0; }
  section.block-item.diagonalimage .container {
    padding: 0; }
  section.block-item.diagonalimage .top-text {
    order: -1; }
  section.block-item.diagonalimage .top-image {
    height: 300px; }
  section.block-item.diagonalimage .bottom-image {
    height: 300px; } }

section.block-item.offerbanner .row {
  margin-right: 0;
  margin-left: 0; }

section.block-item.offerbanner a {
  text-decoration: none;
  cursor: pointer; }

@media (max-width: 768px) {
  section.block-item.offerbanner .container {
    padding: 0; } }

section.block-item.pagetitle h1 {
  text-align: left; }

section.block-item.pagetitle h5 {
  margin-top: 0; }

.page-header {
  padding-top: 3.5rem !important; }

section.block-item.sidebysidetextandimage {
  padding-bottom: 3.75rem; }
  section.block-item.sidebysidetextandimage .hero-banner {
    padding: 0;
    overflow: visible; }
  section.block-item.sidebysidetextandimage .hero-banner--title {
    font-size: 2.5rem; }
  section.block-item.sidebysidetextandimage .order-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  section.block-item.sidebysidetextandimage .order-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  section.block-item.sidebysidetextandimage img {
    height: 100%; }

section.block-item.threeimage {
  margin-top: 1.875rem; }
  section.block-item.threeimage .row {
    margin-right: 0 !important;
    margin-left: 0 !important; }
    section.block-item.threeimage .row h2 {
      margin: 0;
      border-radius: 10px 10px 0px 0px; }
    section.block-item.threeimage .row h3 {
      margin: -5px 0 0 0;
      padding: 15px 0 20px 0;
      background-color: white; }
    section.block-item.threeimage .row h5 {
      margin: 0;
      padding-bottom: 20px;
      background-color: white;
      border-radius: 0px 0px 10px 10px; }
    section.block-item.threeimage .row .card-image {
      position: relative;
      text-align: center; }
      section.block-item.threeimage .row .card-image img {
        max-height: 15.625rem !important;
        min-height: 15.625rem !important; }
      section.block-item.threeimage .row .card-image .cta {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    section.block-item.threeimage .row .card-image {
      margin: 0.5rem -0.875rem 0.5rem -0.875rem; }
    section.block-item.threeimage .row .curved-shadow {
      border-radius: 10px 10px 10px 10px;
      box-shadow: 0 0 2.5rem 0px #28293047; }
  @media (max-width: 768px) {
    section.block-item.threeimage .container {
      padding: 2px; }
    section.block-item.threeimage .card-image {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

.order-grid__item {
  padding: 20px 0;
  border-top: 1px solid #BDBDBD;
  display: flex;
  flex: 0 0 100%;
  justify-content: space-between;
  flex-wrap: wrap; }
  .order-grid__item:last-child {
    border-bottom: 1px solid #BDBDBD; }

.order-grid__content {
  flex: 0 0 100%; }
  .order-grid__content h2, .order-grid__content .h2 {
    margin-bottom: .8rem; }
  .order-grid__content .copy {
    width: 40%; }

.order-grid__cta {
  display: flex;
  align-items: center;
  flex: 0 0 100%; }

@media (min-width: 768px) {
  .order-grid__content {
    flex: 0 0 60%; }
  .order-grid__cta {
    justify-content: flex-end;
    flex: 0 0 40%; } }

.help-card-carousel {
  /*&__next {
        transform: rotateZ(-90deg);
    }*/ }
  .help-card-carousel > .container {
    overflow: hidden; }
  .help-card-carousel--nav {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .help-card-carousel__prev, .help-card-carousel__next {
    color: #4F46B8;
    display: none; }
    .help-card-carousel__prev svg, .help-card-carousel__next svg {
      color: #4F46B8;
      fill: #4F46B8;
      stroke: #4F46B8; }
  .help-card-carousel__prev {
    transform: rotateZ(180deg); }
  .help-card-carousel--carousel {
    height: 100%;
    overflow: visible !important; }
    .help-card-carousel--carousel > div {
      height: 100%; }
      .help-card-carousel--carousel > div > div {
        height: 100%; }
    .help-card-carousel--carousel .card {
      margin: 0 1.3rem; }
      .help-card-carousel--carousel .card:hover {
        transform: scale(1.015); }
  @media (min-width: 768px) {
    .help-card-carousel {
      min-height: 673px; }
      .help-card-carousel__prev, .help-card-carousel__next {
        display: block; } }

.hero-banner {
  padding: 60px 0;
  overflow: hidden; }
  .hero-banner--column {
    max-width: 33.75rem;
    margin: 0 auto; }
  .hero-banner--container {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    margin-right: auto;
    margin-left: auto; }
  .hero-banner--title {
    font-weight: 400;
    font-size: 45px;
    line-height: 54px; }
  .hero-banner--subheading {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 25px;
    line-height: 44px; }
  .hero-banner--button {
    max-width: 250px; }
  .hero-banner--img {
    aspect-ratio: 1.167; }
  @media (max-width: 991.98px) {
    .hero-banner--image {
      padding: 0; }
      .hero-banner--image img {
        width: 100%; } }
  @media (min-width: 768px) {
    .hero-banner {
      min-height: 432px; }
      .hero-banner--column {
        max-width: unset; }
      .hero-banner--container {
        max-width: 45rem; }
      .hero-banner--title {
        font-size: 55px;
        line-height: 66px; }
      .hero-banner--subheading {
        font-size: 23px;
        line-height: 35px; }
      .hero-banner--content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
  @media (min-width: 992px) {
    .hero-banner--container {
      max-width: 60rem; } }
  @media (min-width: 1200px) {
    .hero-banner--container {
      max-width: 75rem; } }

.openhouse .hero-banner--button {
  color: #fff !important;
  min-height: 90px;
  display: flex;
  justify-content: center;
  align-items: center; }

.hero-banner-full {
  display: block;
  height: 200px; }
  .hero-banner-full--medium {
    height: fit-content; }
  .hero-banner-full--tall {
    height: fit-content; }
  .hero-banner-full .js-hero-carousel {
    height: 100%; }
    .hero-banner-full .js-hero-carousel > div {
      height: 100%; }
      .hero-banner-full .js-hero-carousel > div > div {
        height: 100%; }
  .hero-banner-full .hero-button {
    position: absolute;
    z-index: 200;
    top: 50%;
    transform: translateY(-100%);
    background-color: rgba(255, 255, 255, 0.5);
    width: 100px;
    height: 150px;
    color: #222;
    border: none;
    cursor: pointer; }
  .hero-banner-full .hero-button:hover {
    background-color: rgba(211, 0, 42, 0.1); }
  .hero-banner-full .hero-button.next {
    right: 0; }
  .hero-banner-full .hero-button.prev {
    transform: rotate(180deg) translateY(100%); }
  .hero-banner-full .hero-item {
    width: 100%;
    height: 100%; }
    .hero-banner-full .hero-item .hero-banner-full-column {
      height: 100%; }
      .hero-banner-full .hero-item .hero-banner-full-column .hero-banner-full-content {
        width: 100%;
        height: 100%; }
    .hero-banner-full .hero-item .hero-bg-image {
      position: absolute;
      width: 100vw;
      height: 100%;
      top: 0;
      z-index: -1; }
  @media (min-width: 768px) {
    .hero-banner-full {
      margin-bottom: 1.5rem; } }

.home-carousel {
  overflow: hidden;
  padding: 1.75rem 0; }
  .home-carousel .container {
    padding: 0; }
  .home-carousel img {
    aspect-ratio: 1.167; }
  .home-carousel .item-content div {
    padding-left: 1.25rem; }
  .home-carousel--blur {
    display: none; }
  .home-carousel--cta {
    width: 1000px;
    padding: 0;
    left: -50%;
    top: 0;
    z-index: 0;
    position: absolute;
    height: 1000px;
    background: #F6F7F9; }
    .home-carousel--cta__subtext {
      font-weight: 500;
      line-height: 24px; }
    .home-carousel--cta__text p {
      margin: 0; }
    .home-carousel--cta__inner {
      z-index: 1;
      position: relative;
      background: #6C6ACD;
      border-radius: 10px; }
      .home-carousel--cta__inner-bg {
        top: 0;
        left: -50%;
        width: 1000%;
        height: 100%;
        z-index: -1; }
      .home-carousel--cta__inner .h1 {
        margin: 0; }
  .home-carousel--carousel > div {
    height: 100%; }
    .home-carousel--carousel > div > div {
      height: 100%; }
      .home-carousel--carousel > div > div:first-of-type .home-carousel--carousel__item {
        margin-left: 0; }
  .home-carousel--carousel__item {
    margin-right: 27px;
    direction: ltr !important;
    transition: all .2s; }
    .home-carousel--carousel__item-text {
      line-height: 26px; }
    .home-carousel--carousel__item .card-header {
      margin-bottom: 18px; }
    .home-carousel--carousel__item:hover {
      transform: scale(1.015); }
  @media (min-width: 768px) {
    .home-carousel {
      padding: 3.75rem 0;
      min-height: 550px; }
      .home-carousel .container {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
      .home-carousel .item-content {
        margin-top: auto;
        margin-bottom: auto !important;
        padding-right: 0;
        padding-left: 0; }
      .home-carousel--carousel {
        overflow: visible !important;
        height: 100%;
        max-width: 45rem; }
        .home-carousel--carousel__container {
          position: relative; }
      .home-carousel--blur {
        display: block;
        opacity: 0.5;
        position: absolute;
        right: 100%;
        height: calc(100% + 44px);
        top: -40px;
        width: 100%;
        background: #F6F7F9;
        filter: blur(2px);
        pointer-events: none; }
      .home-carousel--title {
        font-size: 48px;
        line-height: 64px; }
      .home-carousel--cta {
        top: -32px;
        left: unset;
        background: #F6F7F9; }
        .home-carousel--cta__inner {
          max-width: 275px; } }
  @media (min-width: 992px) {
    .home-carousel--carousel {
      max-width: 60rem; } }
  @media (min-width: 1200px) {
    .home-carousel--carousel {
      max-width: 75rem; } }

.carousel-indicators {
  bottom: -40px; }
  .carousel-indicators li {
    background-color: lightgray; }
  .carousel-indicators li.active {
    background-color: darkgrey; }

.image-and-text {
  padding: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
  justify-content: space-between; }
  .image-and-text__container .image-and-text:nth-child(even) {
    flex-direction: row-reverse; }
  .image-and-text__text, .image-and-text__image {
    position: relative;
    flex: 0 0 100%; }
  .image-and-text__image img {
    width: 100%; }
  @media (min-width: 768px) {
    .image-and-text__text, .image-and-text__image {
      flex: 0 0 calc(50% - 0.5rem); }
    .image-and-text__image {
      overflow: hidden; }
      .image-and-text__image img {
        width: 100%;
        max-width: 100%; } }

.numbered-list {
  list-style-type: none;
  padding: 0;
  padding-left: 10px; }
  .numbered-list li {
    font-size: 16px;
    line-height: 25px;
    position: relative;
    display: flex;
    margin-bottom: 30px; }
  .numbered-list__index {
    color: #6C6ACD;
    font-size: 36px;
    margin-right: 30px;
    line-height: 2.5rem; }

.dropdown-menu {
  padding: 0;
  margin: 0;
  border: 0 solid transition !important;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.mainmenu li.top-level a {
  padding: 16px 15px 16px 0; }

.mainmenu a, .navbar-default .navbar-nav > li > a, .mainmenu ul li a, .navbar-expand-lg .navbar-nav .nav-link {
  padding: 16px 15px;
  display: block !important; }

.mainmenu .active a, .mainmenu .active a:focus, .mainmenu .active a:hover, .mainmenu li a:hover, .mainmenu li a:focus, .navbar-default .navbar-nav > .show > a, .navbar-default .navbar-nav > .show > a:focus, .navbar-default .navbar-nav > .show > a:hover {
  outline: 0; }

/*==========Sub Menu=v==========*/
.mainmenu .collapse ul ul, .mainmenu .collapse ul ul.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff; }

.mainmenu .collapse ul ul ul, .mainmenu .collapse ul ul ul.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff; }

.mainmenu .collapse ul ul ul ul, .mainmenu .collapse ul ul ul ul.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff; }

/******************************Drop-down menu work on hover**********************************/
.mainmenu {
  background: none;
  border: 0 solid;
  margin: 0;
  padding: 0;
  min-height: 20px;
  width: 100%; }

@media only screen and (min-width: 767px) {
  .mainmenu .collapse ul li:hover > ul {
    display: block; }
  .mainmenu .collapse ul ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 250px;
    display: none; }
  /*******/
  .mainmenu .collapse ul ul li {
    position: relative; }
  .mainmenu .collapse ul ul li:hover > ul {
    display: block; }
  .mainmenu .collapse ul ul ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 250px;
    display: none; }
  /*******/
  .mainmenu .collapse ul ul ul li {
    position: relative; }
  .mainmenu .collapse ul ul ul li:hover ul {
    display: block; }
  .mainmenu .collapse ul ul ul ul {
    position: absolute;
    top: 0;
    left: -100%;
    min-width: 250px;
    display: none;
    z-index: 1; } }

@media only screen and (max-width: 767px) {
  .navbar-nav .show .dropdown-menu .dropdown-menu > li > a {
    padding: 16px 15px 16px 35px; }
  .navbar-nav .show .dropdown-menu .dropdown-menu .dropdown-menu > li > a {
    padding: 16px 15px 16px 45px; } }

@media only screen and (min-width: 767px) {
  .hidden-md-up {
    display: none; } }

a.lang-link {
  display: inline; }

a.tel-link {
  justify-content: normal; }

.oh-product-list {
  overflow: hidden;
  padding-top: 2.5rem; }
  .oh-product-list--blur {
    display: none; }
  .oh-product-list--carousel > div {
    height: 100%; }
    .oh-product-list--carousel > div > div {
      height: 100%; }
      .oh-product-list--carousel > div > div:first-of-type .product-carousel--carousel__item {
        margin-left: 0; }
  .oh-product-list--carousel__item {
    margin-right: 27px;
    direction: ltr !important;
    transition: all .2s; }
    .oh-product-list--carousel__item-text {
      line-height: 26px; }
    .oh-product-list--carousel__item .card-header {
      margin-bottom: 18px; }
    .oh-product-list--carousel__item:hover {
      transform: scale(1.015); }
  .oh-product-list button {
    color: #fff; }
  .oh-product-list ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  .oh-product-list .radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0; }
  .oh-product-list .radio-item input[type='radio'] {
    display: none; }
  .oh-product-list .radio-item label {
    color: #666;
    font-weight: normal; }
  .oh-product-list .radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #a50022;
    background-color: transparent; }
  .oh-product-list .radio-item input[type=radio]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: #a50022; }

.page-header {
  padding-top: 56px; }
  .page-header--subtitle {
    font-size: 23px;
    line-height: 32px; }
  .page-header--product .page-header--subtitle {
    margin-top: 0;
    color: #4F46B8; }
  .page-header .page-title {
    margin-top: 0; }
  @media (min-width: 768px) {
    .page-header--product {
      border-bottom: none; } }

.product-carousel {
  overflow: hidden;
  padding-top: 2.5rem; }
  .product-carousel--blur {
    display: none; }
  .product-carousel--cta {
    width: 1000px;
    padding: 0;
    left: -50%;
    top: 0;
    z-index: 0;
    position: absolute;
    height: 1000px;
    background: #F6F7F9; }
    .product-carousel--cta__subtext {
      font-weight: 500;
      line-height: 24px; }
    .product-carousel--cta__text p {
      margin: 0; }
    .product-carousel--cta__inner {
      z-index: 1;
      position: relative;
      background: #6C6ACD;
      border-radius: 10px; }
      .product-carousel--cta__inner-bg {
        top: 0;
        left: -50%;
        width: 1000%;
        height: 100%;
        z-index: -1; }
      .product-carousel--cta__inner .h1 {
        margin: 0; }
  .product-carousel--carousel > div {
    height: 100%; }
    .product-carousel--carousel > div > div {
      height: 100%; }
      .product-carousel--carousel > div > div:first-of-type .product-carousel--carousel__item {
        margin-left: 0; }
  .product-carousel--carousel__item {
    margin-right: 27px;
    direction: ltr !important;
    transition: all .2s; }
    .product-carousel--carousel__item-text {
      line-height: 26px; }
    .product-carousel--carousel__item .card-header {
      margin-bottom: 18px; }
    .product-carousel--carousel__item:hover {
      transform: scale(1.015); }
  @media (min-width: 768px) {
    .product-carousel {
      padding: 2.5rem 0;
      min-height: 725px; }
      .product-carousel--carousel {
        overflow: visible !important;
        height: 100%; }
        .product-carousel--carousel__container {
          position: relative; }
      .product-carousel--blur {
        display: block;
        opacity: 0.5;
        position: absolute;
        right: 100%;
        height: calc(100% + 44px);
        top: -40px;
        width: 100%;
        background: #F6F7F9;
        filter: blur(2px);
        pointer-events: none; }
      .product-carousel--title {
        font-size: 48px;
        line-height: 64px; }
      .product-carousel--cta {
        top: -32px;
        left: unset;
        background: #F6F7F9; }
        .product-carousel--cta__inner {
          max-width: 275px; } }

.product-detail__wrap {
  overflow: hidden; }

.product-detail__info {
  background: #F6F7F9;
  padding-top: 26px;
  padding-bottom: 26px; }
  .product-detail__info h4 {
    font-size: 18px;
    margin-bottom: 0; }
    .product-detail__info h4:first-child {
      margin-top: 0; }

@media (min-width: 768px) {
  .product-detail {
    padding-bottom: 35px; }
    .product-detail__wrap {
      background: linear-gradient(90deg, #F6F7F9 50%, #FFFFFF 50%); }
    .product-detail__info {
      background: none; } }

.product-grid__card {
  transition: all .2s; }
  .product-grid__card .h1, .product-grid__card h1 {
    margin-top: 0; }
  .product-grid__card:hover {
    transform: scale(1.015); }
  .product-grid__card-description {
    line-height: 26px; }

.results-grid .h1, .results-grid h1 {
  display: flex;
  justify-content: space-between; }

.results-grid__head {
  display: flex;
  flex-wrap: wrap; }
  .results-grid__head__1 {
    flex: 0 0 100%;
    margin-bottom: 1rem; }
  .results-grid__head__2 {
    flex: 0 0 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem; }
  .results-grid__head__3 {
    flex: 0 0 100%;
    margin-bottom: 1rem; }

.results-grid__griditem {
  padding: 22px 0;
  border-top: 1px solid #BDBDBD;
  display: flex;
  flex-wrap: wrap; }
  .results-grid__griditem:last-child {
    border-bottom: 1px solid #BDBDBD; }
  .results-grid__griditem h3, .results-grid__griditem .h3 {
    line-height: 40px; }
  .results-grid__griditem__1 {
    flex: 0 0 100%;
    display: flex; }
    .results-grid__griditem__1 svg {
      flex: 0 0 40px;
      margin-right: 20px; }
  .results-grid__griditem__2 {
    flex: 0 0 100%;
    display: flex;
    align-items: center; }
    .results-grid__griditem__2 h3, .results-grid__griditem__2 .h3 {
      font-weight: 300; }
  .results-grid__griditem__accordion {
    display: none; }
  .results-grid__griditem__result {
    margin-left: 60px; }
  .results-grid__griditem__meaningtooltip {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 27px;
    font-family: "Poppins", sans-serif;
    text-decoration: none; }
    .results-grid__griditem__meaningtooltip:hover {
      text-decoration: underline;
      color: #18191F; }
      .results-grid__griditem__meaningtooltip:hover svg {
        fill: #BDBDBD; }

@media (min-width: 992px) {
  .results-grid__head__1 {
    flex: 0 0 50%; }
  .results-grid__head__2 {
    flex: 0 0 50%; }
    .results-grid__head__2 strong {
      flex: 0 0 50%; }
  .results-grid__griditem {
    display: flex;
    justify-content: space-between; }
    .results-grid__griditem__1 {
      flex: 0 0 50%; }
    .results-grid__griditem__2 {
      flex: 0 0 50%; }
    .results-grid__griditem__result {
      margin-left: 0; } }

@media print {
  /* All your print styles go here */
  nav ul, .nav__menu, footer, .hide-on-print, .nav__opener, .nav__cart-link {
    display: none !important; }
  .results-grid__griditem__accordion {
    display: block !important; }
  .container {
    margin: 1rem;
    max-width: 100%; }
  section {
    padding-bottom: 1rem !important; }
  .results-grid {
    page-break-after: always; }
  .results-grid__griditem {
    page-break-inside: avoid; } }

.reviews-carousel--carousel {
  padding: 5px 0; }
  .reviews-carousel--carousel > div {
    height: 100%; }
    .reviews-carousel--carousel > div > div {
      height: 100%; }
      .reviews-carousel--carousel > div > div:first-of-type .product-carousel--carousel__item {
        margin-left: 0; }

.reviews-carousel--nav {
  display: flex;
  justify-content: center;
  color: #FFFFFF; }

.reviews-carousel--review {
  background: #FFFFFF;
  padding: 35px 10px;
  text-align: center;
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  height: calc(100% - 20px);
  transition: all .2s; }
  .reviews-carousel--review:hover {
    transform: scale(1.015); }
  .reviews-carousel--review:after {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #FFFFFF;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
    z-index: 1; }

@media (min-width: 768px) {
  .reviews-carousel--carousel {
    height: 100%; }
  .reviews-carousel--review {
    padding: 30px 60px; } }

/* https://github.com/swordray/ispinner */
.ispinner {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0px auto; }

.ispinner .ispinner-blade {
  position: absolute;
  left: 44.5%;
  top: 37%;
  width: 10%;
  height: 25%;
  border-radius: 50%/20%;
  -webkit-animation: ispinner-fade 1s linear infinite;
  animation: ispinner-fade 1s linear infinite;
  will-change: opacity;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ispinner .ispinner-blade:nth-child(1) {
  -webkit-animation-delay: -1.66667s;
  animation-delay: -1.66667s;
  -webkit-transform: rotate(30deg) translate(0, -150%);
  transform: rotate(30deg) translate(0, -150%); }

.ispinner .ispinner-blade:nth-child(2) {
  -webkit-animation-delay: -1.58333s;
  animation-delay: -1.58333s;
  -webkit-transform: rotate(60deg) translate(0, -150%);
  transform: rotate(60deg) translate(0, -150%); }

.ispinner .ispinner-blade:nth-child(3) {
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
  -webkit-transform: rotate(90deg) translate(0, -150%);
  transform: rotate(90deg) translate(0, -150%); }

.ispinner .ispinner-blade:nth-child(4) {
  -webkit-animation-delay: -1.41667s;
  animation-delay: -1.41667s;
  -webkit-transform: rotate(120deg) translate(0, -150%);
  transform: rotate(120deg) translate(0, -150%); }

.ispinner .ispinner-blade:nth-child(5) {
  -webkit-animation-delay: -1.33333s;
  animation-delay: -1.33333s;
  -webkit-transform: rotate(150deg) translate(0, -150%);
  transform: rotate(150deg) translate(0, -150%); }

.ispinner .ispinner-blade:nth-child(6) {
  -webkit-animation-delay: -1.25s;
  animation-delay: -1.25s;
  -webkit-transform: rotate(180deg) translate(0, -150%);
  transform: rotate(180deg) translate(0, -150%); }

.ispinner .ispinner-blade:nth-child(7) {
  -webkit-animation-delay: -1.16667s;
  animation-delay: -1.16667s;
  -webkit-transform: rotate(210deg) translate(0, -150%);
  transform: rotate(210deg) translate(0, -150%); }

.ispinner .ispinner-blade:nth-child(8) {
  -webkit-animation-delay: -1.08333s;
  animation-delay: -1.08333s;
  -webkit-transform: rotate(240deg) translate(0, -150%);
  transform: rotate(240deg) translate(0, -150%); }

.ispinner .ispinner-blade:nth-child(9) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  -webkit-transform: rotate(270deg) translate(0, -150%);
  transform: rotate(270deg) translate(0, -150%); }

.ispinner .ispinner-blade:nth-child(10) {
  -webkit-animation-delay: -.91667s;
  animation-delay: -.91667s;
  -webkit-transform: rotate(300deg) translate(0, -150%);
  transform: rotate(300deg) translate(0, -150%); }

.ispinner .ispinner-blade:nth-child(11) {
  -webkit-animation-delay: -.83333s;
  animation-delay: -.83333s;
  -webkit-transform: rotate(330deg) translate(0, -150%);
  transform: rotate(330deg) translate(0, -150%); }

.ispinner .ispinner-blade:nth-child(12) {
  -webkit-animation-delay: -.75s;
  animation-delay: -.75s;
  -webkit-transform: rotate(360deg) translate(0, -150%);
  transform: rotate(360deg) translate(0, -150%); }

.ispinner.animating .ispinner-blade {
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ispinner.white .ispinner-blade {
  background-color: #fff; }

.ispinner.gray .ispinner-blade {
  background-color: #8c8c8c; }

.ispinner.large {
  width: 30px;
  height: 30px; }

.ispinner.large .ispinner-blade {
  width: 8.57143%;
  height: 25.71429%;
  border-radius: 50%/16.67%; }

@-webkit-keyframes ispinner-fade {
  0% {
    opacity: .85; }
  50% {
    opacity: .25; }
  100% {
    opacity: .25; } }

@keyframes ispinner-fade {
  0% {
    opacity: .85; }
  50% {
    opacity: .25; }
  100% {
    opacity: .25; } }

.tabs__tab {
  font-size: 23px;
  line-height: 35px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid #BDBDBD;
  padding: 17px 0;
  width: 100%;
  -webkit-user-select: none;
  /* Safari */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  -moz-user-select: none;
  /* Old versions of Firefox */
  user-select: none;
  /* Non-prefixed version*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center; }
  .openhouse .tabs__tab {
    font-size: 18px;
    padding: 10px 0;
    line-height: 22px; }
  .tabs__tab svg {
    transition: all .4s;
    transform: rotateZ(90deg);
    fill: #6C6ACD; }
    .openhouse .tabs__tab svg {
      fill: #a50022; }
  .tabs__tab:hover {
    cursor: pointer;
    color: #4F46B8; }
    .openhouse .tabs__tab:hover {
      color: #a50022; }
  .tabs__tab.active {
    color: #6C6ACD;
    border: none; }
    .openhouse .tabs__tab.active {
      color: #d3002a; }
    .tabs__tab.active:hover {
      color: #4F46B8; }
      .openhouse .tabs__tab.active:hover {
        color: #a50022; }
    .tabs__tab.active svg {
      transform: rotateZ(-90deg); }

.tabs__content {
  display: none;
  border-bottom: 1px solid #828282; }
  .tabs__content.active.mobile {
    display: block; }
  .tabs__content.active.desktop {
    display: none; }

@media (min-width: 992px) {
  .tabs__tab {
    font-size: 21px;
    border: 1px solid transparent;
    border-bottom: none;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 100%; }
    .tabs__tab svg {
      display: none; }
    .tabs__tab.open-on-load {
      color: #6C6ACD;
      border: none; }
      .openhouse .tabs__tab.open-on-load {
        color: #a50022; }
      .tabs__tab.open-on-load:hover {
        color: #4F46B8; }
        .openhouse .tabs__tab.open-on-load:hover {
          color: #d3002a; }
      .tabs__tab.open-on-load svg {
        transform: rotateZ(-90deg); }
    .tabs__tab.active, .tabs__tab.open-on-load {
      border: 1px solid;
      border-bottom: none !important; }
  .tabs__tabsWrap {
    border-bottom: 1px solid #BDBDBD; }
  .tabs__content {
    border-bottom: none;
    padding: calc(40px - 1rem) 0; }
    .tabs__content.open-on-load.mobile {
      display: block; }
    .tabs__content.open-on-load.desktop {
      display: none; }
    .tabs__content.active.mobile, .tabs__content.open-on-load.mobile {
      display: none; }
    .tabs__content.active.desktop, .tabs__content.open-on-load.desktop {
      display: block; } }

.testkit-carousel {
  overflow: hidden;
  padding-top: 0.5rem; }
  .testkit-carousel--blur {
    display: none; }
  .testkit-carousel--cta {
    width: 1000px;
    padding: 0;
    left: -50%;
    top: 0;
    z-index: 0;
    position: absolute;
    height: 1000px;
    background: #F6F7F9; }
    .testkit-carousel--cta__subtext {
      font-weight: 500;
      line-height: 24px; }
    .testkit-carousel--cta__text p {
      margin: 0; }
    .testkit-carousel--cta__inner {
      z-index: 1;
      position: relative;
      background: #6C6ACD;
      border-radius: 10px; }
      .testkit-carousel--cta__inner-bg {
        top: 0;
        left: -50%;
        width: 1000%;
        height: 100%;
        z-index: -1; }
      .testkit-carousel--cta__inner .h1 {
        margin: 0; }
  .testkit-carousel--carousel > div {
    height: 100%; }
    .testkit-carousel--carousel > div > div {
      height: 100%; }
      .testkit-carousel--carousel > div > div:first-of-type .testkit-carousel--carousel__item {
        margin-left: 0; }
  .testkit-carousel--carousel__item {
    margin-right: 27px;
    direction: ltr !important;
    transition: all .2s; }
    .testkit-carousel--carousel__item-text {
      line-height: 26px; }
    .testkit-carousel--carousel__item .card-header {
      margin-bottom: 18px; }
    .testkit-carousel--carousel__item:hover {
      transform: scale(1.015); }
  .testkit-carousel--carousel-image {
    max-width: 360px; }
  .testkit-carousel--nav {
    max-width: 360px; }
  @media (min-width: 768px) {
    .testkit-carousel {
      padding: 0.5rem 0; }
      .testkit-carousel--carousel-image {
        max-width: 480px; }
      .testkit-carousel--nav {
        max-width: 480px; }
      .testkit-carousel--carousel {
        overflow: visible !important;
        height: 100%; }
        .testkit-carousel--carousel__container {
          position: relative; }
      .testkit-carousel--blur {
        display: block;
        opacity: 0.5;
        position: absolute;
        right: 100%;
        height: calc(100% + 44px);
        top: -40px;
        width: 100%;
        background: #F6F7F9;
        filter: blur(2px);
        pointer-events: none; }
      .testkit-carousel--title {
        font-size: 48px;
        line-height: 64px; }
      .testkit-carousel--cta {
        top: -32px;
        left: unset;
        background: #F6F7F9; }
        .testkit-carousel--cta__inner {
          max-width: 275px; } }

@media (min-width: 768px) {
  .three-cards {
    min-height: 447px; } }

.your-results-mean h2, .your-results-mean .h2 {
  margin-top: 0; }

.your-results-mean__griditem {
  display: flex;
  border-bottom: 1px solid #BDBDBD;
  padding: 28px 0; }
  .your-results-mean__griditem__icon {
    flex: 0 0 40px;
    margin-right: 1.25rem; }
  .your-results-mean__griditem__content p {
    margin: .8em 0 0; }

@media (min-width: 768px) {
  .your-results-mean__griditem {
    padding: 1.25rem 0; } }
