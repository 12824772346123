section.block-item.pagetitle {
    h1 {
        text-align: left;
    }
    h5 {
        margin-top: 0;
    }
}
.page-header {
    padding-top: 3.5rem !important;
}