.basket {
    &__product {
        border-top: 1px solid map-get($colours, grey4);
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid map-get($colours, grey4);

        &-left {
            width: 70%;
        }

        &-right {
            text-align: right;
            width: 30%;
        }

        &-info {
            font-weight: 400;
            font-size: 16px;
        }

        &-test-kit {
            padding-top: 1.25rem;
        }

        &-add-ons {
            font-size: 16px;
            line-height: 24px;
        }

        h3, .h3 {
            font-size: 23px;
            line-height: 34px;
            font-weight: 400;
            margin: 0;
        }

        &-remove {
            font-family: $heading-font-family;
            font-size: 12px;
            line-height: 14px;
            color: map-get($colours, grey3);

            &:hover {
                color: darken(map-get($colours, grey3), 20%);
            }
        }
    }
}
