.dropdown-menu {
    padding: 0;
    margin: 0;
    border: 0 solid transition !important;
    border: 0 solid rgba(0,0,0,.15);
    border-radius: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.mainmenu li.top-level a {
    padding: 16px 15px 16px 0;
}

.mainmenu a, .navbar-default .navbar-nav > li > a, .mainmenu ul li a, .navbar-expand-lg .navbar-nav .nav-link {
    padding: 16px 15px;
    display: block !important;
}

.mainmenu .active a, .mainmenu .active a:focus, .mainmenu .active a:hover, .mainmenu li a:hover, .mainmenu li a:focus, .navbar-default .navbar-nav > .show > a, .navbar-default .navbar-nav > .show > a:focus, .navbar-default .navbar-nav > .show > a:hover {
    outline: 0;
}
/*==========Sub Menu=v==========*/
.mainmenu .collapse ul ul, .mainmenu .collapse ul ul.dropdown-menu {
    border: 1px solid rgba(0,0,0,.15);
    background: #fff;
}

.mainmenu .collapse ul ul ul, .mainmenu .collapse ul ul ul.dropdown-menu {
    border: 1px solid rgba(0,0,0,.15);
    background: #fff
}

.mainmenu .collapse ul ul ul ul, .mainmenu .collapse ul ul ul ul.dropdown-menu {
    border: 1px solid rgba(0,0,0,.15);
    background: #fff
}

/******************************Drop-down menu work on hover**********************************/
.mainmenu {
    background: none;
    border: 0 solid;
    margin: 0;
    padding: 0;
    min-height: 20px;
    width: 100%;
}

@media only screen and (min-width: 767px) {
    .mainmenu .collapse ul li:hover > ul {
        display: block
    }

    .mainmenu .collapse ul ul {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 250px;
        display: none
    }
    /*******/
    .mainmenu .collapse ul ul li {
        position: relative
    }

    .mainmenu .collapse ul ul li:hover > ul {
        display: block
    }

    .mainmenu .collapse ul ul ul {
        position: absolute;
        top: 0;
        left: 100%;
        min-width: 250px;
        display: none
    }
    /*******/
    .mainmenu .collapse ul ul ul li {
        position: relative
    }

    .mainmenu .collapse ul ul ul li:hover ul {
        display: block
    }

    .mainmenu .collapse ul ul ul ul {
        position: absolute;
        top: 0;
        left: -100%;
        min-width: 250px;
        display: none;
        z-index: 1
    }
}

@media only screen and (max-width: 767px) {
    .navbar-nav .show .dropdown-menu .dropdown-menu > li > a {
        padding: 16px 15px 16px 35px
    }

    .navbar-nav .show .dropdown-menu .dropdown-menu .dropdown-menu > li > a {
        padding: 16px 15px 16px 45px
    }
}
@media only screen and (min-width: 767px) {
    .hidden-md-up {
        display: none;
    }
}

a.lang-link {
    display: inline;
}

a.tel-link {
    justify-content: normal;
}