.product-detail {
    &__wrap {
        overflow: hidden;
    }

    &__info {
        background: map-get($colours, grey);
        padding-top: 26px;
        padding-bottom: 26px;

        h4 {
            font-size: 18px;
            margin-bottom: 0;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding-bottom: 35px;

        &__wrap {
            background: linear-gradient(90deg, map-get($colours, grey) 50%, map-get($colours, white) 50%);
        }

        &__info {
            background: none;
        }
    }
}
