.px-icon {
    width: 20px;
    height: 20px;
    max-width: 100%;

    &--socials {
        width: 24px;
        height: 24px;

        &-lg {
            width: 35px;
            height: 35px;
        }
    }

    &--large {
        height: 40px;
        width: auto;
    }

    &--card {
        height: 90px;
        width: auto;
    }

    &--card-mid {
        height: 60px;
        width: auto;
    }

    &--carousel-nav {
        width: 30px;
        height: 30px;
    }

    &--header {
        width: 30px;
        height: 30px;

        @include media-breakpoint-down(sm) {
            width: 20px;
            height: 15px;
        }
    }

    &--nav-close {
        width: 22px;
        height: 22px;
    }

    &--testmelogo,
    &--testmelogo svg {
        width: 150px;
        height: auto;
    }

    &--nav-arrow {
        width: 13px;
        height: 13px;
    }

    &--checkbox-tooltip {
        width: 27px;
        height: 27px;
    }

    &--checkout-step {
        width: 10px;
        height: 10px;
    }

    &--dropdown-arrow {
        width: 14px;
        height: 14px;
    }

    &--result {
        width: 40px;
        height: 40px;
    }

    &--order-status {
        width: 29px;
        height: 29px;
    }

    &--experience {
        width: 46px;
        height: 46px;
    }
}
