.page-header {
    padding-top: 56px;

    &--subtitle {
        font-size: 23px;
        line-height: 32px;
    }

    &--product &--subtitle {
        margin-top: 0;
        color: map-get($colours, purple2);
    }

    .page-title {
        margin-top: 0;
    }

    @include media-breakpoint-up(md) {
        &--product {
            border-bottom: none;
        }
    }
}
