.product-grid {
    &__card {
        transition: all .2s;

        .h1, h1 {
            margin-top: 0;
        }

        &:hover {
            transform: scale(1.015);
        }

        &-description {
            line-height: map-get($line-heights, base);
        }
    }
}
