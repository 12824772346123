.reset-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.reset-button {
  padding: 0;
  border-width: 0;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
  text-align: inherit;

  &:focus {
    outline: none;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}