section.block-item.threeimage {
    margin-top: 1.875rem;

    .row {
        margin-right: 0 !important;
        margin-left: 0 !important;

        h2 {
            margin: 0;
            border-radius: 10px 10px 0px 0px;
        }

        h3 {
            margin: -5px 0 0 0;
            padding: 15px 0 20px 0;
            background-color: white;
        }

        h5 {
            margin: 0;
            padding-bottom: 20px;
            background-color: white;
            border-radius: 0px 0px 10px 10px;
        }

        .card-image {
            position: relative;
            text-align: center;

            img {
                max-height: 15.625rem !important;
                min-height: 15.625rem !important;
            }

            .cta {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .card {
            &-image {
                margin: 0.5rem -0.875rem 0.5rem -0.875rem;

                img {
                }
            }
        }

        .curved-shadow {
            border-radius: 10px 10px 10px 10px;
            box-shadow: 0 0 2.5rem 0px #28293047;
        }
    }

    @media (max-width: 768px) {
        .container {
            padding: 2px;
        }

        .card {
            &-image {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}
