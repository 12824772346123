.your-results-mean{
    h2, .h2{
        margin-top:0;
    }

    &__grid{
        &item{
            display:flex;
            border-bottom:1px solid map-get($colours, grey4);
            padding:28px 0;

            &__icon{
                flex: 0 0 40px;
                margin-right:1.25rem;
            }

            &__content{
                p{
                    margin:.8em 0 0;
                }
            }
        }
    }

    @include media-breakpoint-up(md){
        &__grid{
            &item{
                padding:1.25rem 0;
            }
        }
    }
}