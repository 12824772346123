.order-status-list{
    &__item{
        margin-bottom:30px;
        display:flex;
        align-items:center;

        &:last-child{
            margin-bottom:0;
        }

        svg{
            flex:0 0 29px;
            margin-right:1.25rem;
        }

        &__date{
            color:map-get($colours, grey3);
            display:block;
            font-size:16px;
        }

        &__title{
            display:block;
            color:map-get($colours, black);
            font-size:16px;
        }
    }
}