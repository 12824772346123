@mixin font-size($size) {
    $font-size-pixels: map-get($font-sizes, standard);

    @if map-has-key($font-sizes, $size) {
        $font-size-pixels: map-get($font-sizes, $size);
    }

    font-size: $font-size-pixels;
}

@mixin visually-hidden {
    position: absolute;
    left: -99999em;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}