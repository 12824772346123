.accordion {
    &__item {
        border-bottom: 1px solid map-get($colours, grey3);

        &__name {
            color: map-get($colours, black);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;
            padding: .75rem 0;

            svg {
                transition: all .2s;
                transform: rotateZ(90deg);
            }

            &.active {
                svg {
                    transform: rotateZ(-90deg);
                }
            }

            &:hover {
                color: map-get($colours, black);
            }
        }

        &__content {
            display: none;
        }
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .button--accordion-nav {
            flex: 0 0 100%;
        }
    }

    @include media-breakpoint-up(md) {
        &__item {
            &__name {
                padding: 1rem 0;
            }
        }

        &__nav {
            display: block;
        }
    }
}
