.numbered-list {
    list-style-type: none;
    padding:0;
    padding-left:10px;

    li {
        font-size: 16px;
        line-height: 25px;
        position: relative;
        display: flex;
        margin-bottom: 30px;
    }

    &__index {
        color: map-get($colours, purple);
        font-size: 36px;
        margin-right: 30px;
        line-height: 2.5rem;
    }
}
