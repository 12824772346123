﻿/* https://github.com/swordray/ispinner */
.ispinner {
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0px auto;
}

    .ispinner .ispinner-blade {
        position: absolute;
        left: 44.5%;
        top: 37%;
        width: 10%;
        height: 25%;
        border-radius: 50%/20%;
        -webkit-animation: ispinner-fade 1s linear infinite;
        animation: ispinner-fade 1s linear infinite;
        will-change: opacity;
        -webkit-animation-play-state: paused;
        animation-play-state: paused
    }

        .ispinner .ispinner-blade:nth-child(1) {
            -webkit-animation-delay: -1.66667s;
            animation-delay: -1.66667s;
            -webkit-transform: rotate(30deg) translate(0,-150%);
            transform: rotate(30deg) translate(0,-150%)
        }

        .ispinner .ispinner-blade:nth-child(2) {
            -webkit-animation-delay: -1.58333s;
            animation-delay: -1.58333s;
            -webkit-transform: rotate(60deg) translate(0,-150%);
            transform: rotate(60deg) translate(0,-150%)
        }

        .ispinner .ispinner-blade:nth-child(3) {
            -webkit-animation-delay: -1.5s;
            animation-delay: -1.5s;
            -webkit-transform: rotate(90deg) translate(0,-150%);
            transform: rotate(90deg) translate(0,-150%)
        }

        .ispinner .ispinner-blade:nth-child(4) {
            -webkit-animation-delay: -1.41667s;
            animation-delay: -1.41667s;
            -webkit-transform: rotate(120deg) translate(0,-150%);
            transform: rotate(120deg) translate(0,-150%)
        }

        .ispinner .ispinner-blade:nth-child(5) {
            -webkit-animation-delay: -1.33333s;
            animation-delay: -1.33333s;
            -webkit-transform: rotate(150deg) translate(0,-150%);
            transform: rotate(150deg) translate(0,-150%)
        }

        .ispinner .ispinner-blade:nth-child(6) {
            -webkit-animation-delay: -1.25s;
            animation-delay: -1.25s;
            -webkit-transform: rotate(180deg) translate(0,-150%);
            transform: rotate(180deg) translate(0,-150%)
        }

        .ispinner .ispinner-blade:nth-child(7) {
            -webkit-animation-delay: -1.16667s;
            animation-delay: -1.16667s;
            -webkit-transform: rotate(210deg) translate(0,-150%);
            transform: rotate(210deg) translate(0,-150%)
        }

        .ispinner .ispinner-blade:nth-child(8) {
            -webkit-animation-delay: -1.08333s;
            animation-delay: -1.08333s;
            -webkit-transform: rotate(240deg) translate(0,-150%);
            transform: rotate(240deg) translate(0,-150%)
        }

        .ispinner .ispinner-blade:nth-child(9) {
            -webkit-animation-delay: -1s;
            animation-delay: -1s;
            -webkit-transform: rotate(270deg) translate(0,-150%);
            transform: rotate(270deg) translate(0,-150%)
        }

        .ispinner .ispinner-blade:nth-child(10) {
            -webkit-animation-delay: -.91667s;
            animation-delay: -.91667s;
            -webkit-transform: rotate(300deg) translate(0,-150%);
            transform: rotate(300deg) translate(0,-150%)
        }

        .ispinner .ispinner-blade:nth-child(11) {
            -webkit-animation-delay: -.83333s;
            animation-delay: -.83333s;
            -webkit-transform: rotate(330deg) translate(0,-150%);
            transform: rotate(330deg) translate(0,-150%)
        }

        .ispinner .ispinner-blade:nth-child(12) {
            -webkit-animation-delay: -.75s;
            animation-delay: -.75s;
            -webkit-transform: rotate(360deg) translate(0,-150%);
            transform: rotate(360deg) translate(0,-150%)
        }

    .ispinner.animating .ispinner-blade {
        -webkit-animation-play-state: running;
        animation-play-state: running
    }

    .ispinner.white .ispinner-blade {
        background-color: #fff
    }

    .ispinner.gray .ispinner-blade {
        background-color: #8c8c8c
    }

    .ispinner.large {
        width: 30px;
        height: 30px;  
    }

        .ispinner.large .ispinner-blade {
            width: 8.57143%;
            height: 25.71429%;
            border-radius: 50%/16.67%
        }

@-webkit-keyframes ispinner-fade {
    0% {
        opacity: .85
    }

    50% {
        opacity: .25
    }

    100% {
        opacity: .25
    }
}

@keyframes ispinner-fade {
    0% {
        opacity: .85
    }

    50% {
        opacity: .25
    }

    100% {
        opacity: .25
    }
}