.card {
    padding: 22px;
    border-radius: 10px;
    transition: .2s;
    position: relative;
    box-shadow: 0px 10px 15px rgba(212, 217, 232, 0.4);

    &-image {
        margin: -22px -22px 20px -22px;

        img {
            border-radius: 10px 10px 0 0;
        }
    }

    &.animate-fade-in {
        //animation based on
        //https://css-tricks.com/different-approaches-for-creating-a-staggered-animation/
        //style='animation
        &.animated {
            animation-name: animateInCard;
            animation-duration: 350ms;
            animation-delay: calc(var(--animation-order) * 100ms);
            animation-fill-mode: both;
            animation-timing-function: ease-in-out;
        }
    }
    /*&:hover {
        box-shadow: 0px 40px 50px rgba(212, 217, 232, 0.6);
    }*/

    &-tag {
        font-size: 16px;
        line-height: 32px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        right: 15px;
        top: 0;
        transform: translateY(-100%);
        position: absolute;
        padding: 0 10px;
        font-weight: 300;
    }

    &-freetext {
        p {
            margin: 0;
        }
    }

    &-number {
        background: map-get($colours, purple);
        color: map-get($colours, white);
        border-radius: 50%;
        display: inline-block;
        font-size: 48px;
        width: 62px;
        height: 62px;
        line-height: 62px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(15px, -50%);

        @include media-breakpoint-up(md) {
            position: static;
            margin: 0 auto;
            top: unset;
            right: unset;
            transform: unset;
            margin-bottom: 20px;
        }
    }

    &-price {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 32px;
        white-space: nowrap;
        
        span {
            font-size: 30px;
            line-height: 49px;
            padding-right: 8px;
            font-weight: 400;
        }
    }

    @include media-breakpoint-up(md) {
        box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.4);
    }
}
@keyframes animateInCard {
    0% {
        opacity: 0.6;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
    }
}