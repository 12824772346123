.tabs {
    &__tab {
        font-size: 23px;
        line-height: 35px;
        font-weight: 400;
        font-family: $heading-font-family;
        border-bottom: 1px solid map-get($colours, grey4);
        padding: 17px 0;
        width: 100%;
        -webkit-user-select: none; /* Safari */
        -webkit-touch-callout: none; /* iOS Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -ms-user-select: none; /* Internet Explorer/Edge */
        -moz-user-select: none; /* Old versions of Firefox */
        user-select: none; /* Non-prefixed version*/
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;

        .openhouse & {
            font-size: 18px;
            padding: 10px 0;
            line-height: 22px;
        }

        svg {
            transition: all .4s;
            transform: rotateZ(90deg);
            fill: map-get($colours, purple);

            .openhouse & {
                fill: map-get($colours, red2);
            }
        }

        &:hover {
            cursor: pointer;
            color: map-get($colours, purple2);

            .openhouse & {
                color: map-get($colours, red2);
            }
        }

        &.active {
            color: map-get($colours, purple);
            border: none;

            .openhouse & {
                color: map-get($colours, red);
            }

            &:hover {
                color: map-get($colours, purple2);

                .openhouse & {
                    color: map-get($colours, red2);
                }
            }

            svg {
                transform: rotateZ(-90deg);
            }
        }
    }

    &__content {
        display: none;
        border-bottom: 1px solid map-get($colours, grey3);

        &.active {
            &.mobile {
                display: block;
            }

            &.desktop {
                display: none;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__tab {
            font-size: map-get($font-sizes, h3);
            border: 1px solid transparent;
            border-bottom: none;
            justify-content: center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            height: 100%;

            .openhouse & {
                font-size: map-get($font-sizes, h5);
            }

            svg {
                display: none;
            }

            &.open-on-load {
                color: map-get($colours, purple);
                border: none;

                .openhouse & {
                    color: map-get($colours, red2);
                }

                &:hover {
                    color: map-get($colours, purple2);

                    .openhouse & {
                        color: map-get($colours, red);
                    }
                }

                svg {
                    transform: rotateZ(-90deg);
                }
            }

            &.active, &.open-on-load {
                border: 1px solid;
                border-bottom: none !important;
            }
        }

        &__tabsWrap {
            border-bottom: 1px solid map-get($colours, grey4);
        }

        &__content {
            border-bottom: none;
            padding: calc(40px - 1rem) 0;

            &.open-on-load {
                &.mobile {
                    display: block;
                }

                &.desktop {
                    display: none;
                }
            }

            &.active, &.open-on-load {
                &.mobile {
                    display: none;
                }

                &.desktop {
                    display: block;
                }
            }
        }
    }
}
