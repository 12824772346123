.basket-block {
    padding: 1.25rem;

    @include media-breakpoint-down(xs) {
        &__popout {
            position: fixed;
            bottom: 0;
            width: 100%;
            padding: 0;
            left: 0;
            z-index: 200;
        }

        &__popout &__content {
            display: none;
        }

        &__popout.open {
            box-shadow: 0.75rem 0.25rem 0.75rem 0.75rem rgba(0, 0, 0, 0.25);
        }

        &__popout.open &__content {
            display: flex;
        }

        &__popout.open > &__overlay {
            height: 100%;
            opacity: 1;
        }

        &__total {
            padding-top: .75rem;
            padding-bottom: .75rem;

            &:hover {
                cursor: pointer;
            }
        }

        &:not(&__popout) {
            margin-left: -1.25rem;
            margin-right: -1.25rem;
        }

        &__overlay {
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            background: red;
            position: fixed;
            z-index: -1;
            background: rgba(244, 245, 247, 0.8);
            opacity: 0;
            transition: all .1s;
        }
    }

    &__title {
        padding-top: 10px;
        padding-bottom: 10px;

        .h2 {
            margin: 0;
        }
    }

    &__lines {
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 1px solid map-get($colours, grey4);

        &:last-of-type {
            border-bottom: 1px solid map-get($colours, grey4);
        }
    }

    &__line {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;

        span {
            font-size: 17px;
            line-height: 20px;
            font-weight: 400;
            font-family: $font-family;
            padding-right: 0.25rem;
        }
    }

    &__promo {
        color: map-get($colours, black);
        text-decoration: underline;

        &-field {
            width: 100%;

            &.form-field {
                display: none;

                &.active {
                    display: block;
                }
            }
        }

        &-button.button {
            width: 100%;
            display: none;
            font-size: 1rem;
            line-height: 0.75rem;

            &.active {
                display: block;
            }
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__total {
        display: flex;
        justify-content: space-between;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;

        .h2 {
            margin: 0;
        }

        svg {
            transform: rotateZ(-90deg);
        }
    }

    @include media-breakpoint-up(md) {
        &__total {
            padding: 1.25rem 0 0;
            justify-content: space-between;
        }

        &__title {
            padding: 0 0 1.25rem 0;
        }

        &__lines {
            padding: 1.25rem 0;
        }
    }
}
