﻿//to prevent jankyness on scrolling nav bar
header, body, nav {
    transition: none;
}

body.nav-open {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .nav {
        height: 100%;
        max-height: 100%;
        position: absolute;
        width: 100%;
        top: 0;

        &--overlay {
            display: block;
        }
    }

    @include media-breakpoint-up(md) {
        .nav {
            height: auto;

            &__menu {
                &--overlay {
                    display: block;
                    z-index: -1;
                }
            }
        }
    }
}

.openhouse {
    .nav-open {
        .nav__menu--link-dropdown.active {
            svg {
                transform: rotateZ(-180deg);
            }
        }
    }
}

.testme {
    .nav-open {
        .nav__menu {
            &--link {
                &-dropdown {
                    svg {
                        transform: rotateZ(-180deg);
                    }
                }
            }
        }
    }
}


.openhouse {
    .nav {
        &__cart {
            &-link {

                &.has-shopping {
                    &::after {
                        background: map-get($colours, red);
                    }
                }
            }
        }
    }
}
.nav {
    position: relative;
    z-index: 100;

    &__opener, &__closer {
        display: flex;
    }

    &__link {
        font-size: 18px;

        &:hover {
            text-decoration: none;
        }

        .openhouse & {
            font-size: 1rem;
        }
    }

    &__cart {
        &-link {
            display: flex;
            align-items: center;

            &.has-shopping {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: map-get($colours, purple);
                    top: 0;
                    right: 0;
                    transform: translateX(50%);
                }
            }
        }
    }

    .mobile-cart {
        &.has-shopping {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: map-get($colours, red);
                top: 0;
                right: 0;
                transform: translateX(50%);
            }
        }
    }

    .js-nav-close {
        display: none;
    }

    &-open {
        .js-nav-open-mob {
            display: none;
        }

        .js-nav-close {
            display: flex;
        }
    }

    &__mobile-wrap {
        padding: 22.5px 0;
        z-index: 100;
        background: map-get($colours, white);
        height: 75px;
        border-bottom: 1px solid #bdbdbd;
    }

    &__desktop-wrap {
        padding: 1rem 0;
        background: map-get($colours, white);
        border-bottom: 1px solid #bdbdbd;
    }

    &__menu {
        width: 100%;
        position: fixed;
        pointer-events: none;
        height: auto;
        transition: top 0.4s, opacity 0.4s;
        overflow: hidden scroll;
        max-height: 100%;
        opacity: 0;
        top: -100%;
        background: map-get($colours, white);

        &.active {
            pointer-events: auto;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            opacity: 1;
            top: 75px;
            height: calc(100% - 75px);
        }

        &--overlay {
            position: fixed;
            height: 100vh;
            top: 0;
            width: 100%;
            background: rgba(244, 245, 247, 0.8);
            display: none;
        }

        &--links {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            overflow: hidden;
        }

        &--group {
            width: 100%;
            margin: 0 auto;
            max-width: 33.75rem;
        }

        &--link {
            &-dropdown {
                display: flex;
                align-items: center;
                color: map-get($colours, black);

                svg {
                    margin-left: 5px;
                    color: map-get($colours, purple);
                    transition: all .4s;
                }
            }

            &:hover {
                text-decoration: none;
            }
        }

        &--title {
            font-weight: 500;
            font-size: 23px;
            line-height: 35px;
            margin-bottom: 30px;
            font-family: $heading-font-family;
        }

        &--col1 {
            background: map-get($colours, white);
        }

        &--col2 {
            background: map-get($colours, purple3);
        }

        &--extra-nav-items {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }

        &--mobile {
            .nav__subnav {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: 100%;
            }

            &__item {
                border-bottom: 1px solid #bdbdbd;

                &-link {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: map-get($colours, black);
                    padding: 1rem 1.25rem;
                    font-size: 23px;

                    &:hover {
                        text-decoration: none;
                    }
                }

                &-links {
                    height: 0;
                    transition: height .2s;
                    overflow: hidden;

                    &--inner {
                        padding: 1rem 1.25rem;
                    }

                    &--title {
                        font-weight: 500;
                        font-size: 23px;
                        line-height: 35px;
                        margin-bottom: .75rem;
                        font-family: $heading-font-family;
                    }

                    &--link {
                    }
                }

                &-link.open + &-links {
                    height: auto;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        &__menu {
            overflow: hidden;
            position: absolute;
            left: 0;
            max-height: none;
            background: linear-gradient(90deg, map-get($colours, white) 50%, map-get($colours, purple3) 50%);
            z-index: -1;

            .openhouse & {
                background: linear-gradient(90deg, map-get($colours, white) 50%, map-get($colours, red3) 50%);
            }

            &--links {
                max-height: 225px;
            }

            &.active {
                height: auto;
                top: calc(100% + 1px);
            }

            &--col1, &--col2 {
                background: none;
            }

            &--group {
                max-width: unset;
            }

            &--container {
                max-width: 45rem;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__menu {
            &--container {
                max-width: 60rem;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__menu {
            &--container {
                max-width: 75rem;
            }
        }
    }
}
