.basket-popup {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: none;
    z-index: 999;

    &.active {
        display: block;
    }

    &__close {
        position: absolute;
        right: 0;
        top: calc(-1.25rem - 10px);
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 999;
        background: rgba(244, 245, 247, 0.8);
        opacity: 0;
        transition: all .2s;

        &.active {
            opacity: 1;
        }
    }

    &__window {
        position: absolute;
        top: calc(50% + 10px);
        left: 50%;
        width: 100%;
        z-index: 999;
        width: 100%;
        max-width: 90%;
        background: map-get($colours, white);
        border-radius: 10px;
        transition: all .2s;
        transform: translate(-50%, -60%);
        opacity: 0;

        &.active {
            opacity: 1;
            transform: translate(-50%, -50%);
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: map-get($colours, purple);
    }

    @include media-breakpoint-up(md) {
        &__title {
            font-size: 31px;
            line-height: 41px;
        }

        &__window {
            max-width: 90%;
        }

        &__row {
            color: map-get($colours, black);

            .h2 {
                font-size: 23px;
            }
        }

        &__buttons {
            display: flex;
            justify-content: space-between;

            > * {
                flex-basis: 48%;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__window {
            max-width: 945px;
        }
    }
}
