.footer {
    &-main {
        &--text {
            font-family: $other-font-family;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            line-height: 24px;
        }

        &--social {
            padding-right: 11px;
        }

        &--text {
            margin: 0;
            font-weight: 300;
            font-family: $font-family;
            font-size: 14px;
            color: map-get($colours, footer-alt);
            line-height: map-get($line-heights, base);

            p {
                font-weight: 300;
                font-family: $font-family;
                font-size: 14px;
                color: map-get($colours, footer-alt);
                line-height: map-get($line-heights, base);
            }
        }

        &--contact {
            color: map-get($colours, white);

            a {
                color: map-get($colours, white);
            }

            &:hover, a:hover {
                text-decoration: none;
                color: map-get($colours, footer-link-rollover);
            }
        }
    }


    &__link {
        color: map-get($colours, white);

        a {
            color: map-get($colours, white);
        }

        &:hover, a:hover {
            text-decoration: none;
            color: map-get($colours, footer-link-rollover);
        }
    }

    &__logo {
        max-height: 60px;
    }

    &-sub {
        &--link, &--copyright {
            font-family: $heading-font-family;
            font-weight: 400;
            font-style: normal;
            font-size: 17px;
            line-height: 30px;

            & + span {
                line-height: 30px;
            }
        }

        &--link {
            color: map-get($colours, footer-alt);

            a {
                color: map-get($colours, footer-alt);
            }

            &:hover, a:hover {
                text-decoration: none;
                color: map-get($colours, footer-link-rollover);
            }

            .openhouse & {
                color: map-get($colours, footer-alt);

                a {
                    color: map-get($colours, footer-alt);
                }

                &:hover, a:hover {
                    text-decoration: none;
                    color: map-get($colours, footer-link-rollover-red);
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        &-main {
            &--link {
                width: 25%;
            }

            &--contact {
                margin-bottom: 5px
            }
        }

        &-sub {
            &--link, &--copyright {
                font-family: $other-font-family;
                font-size: 14px;
                line-height: 24px;

                & + span {
                    line-height: 24px;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &-main {
            &--link {
                max-width: 20%;
            }
        }
    }
}
