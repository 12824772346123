.logo {
    position: relative;
    font-family: $heading-font-family;
    font-size: 17px;

    span {
        padding-left: 11px;
    }
    /*svg {
        width: 18px;
        height: 18px;
    }*/

    .openhouse & {
        img {
            max-width: 150px;
        }
    }

    &__mobile {
        &-nav {
            padding-top: 15px;

            .openhouse & {
                padding-top: 0;
                img {
                    max-width: 120px;
                }
            }
        }
    }
}
