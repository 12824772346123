﻿.hero-banner-full {
    display: block;
    height: 200px;

    &--medium {
        height: fit-content;
    }

    &--tall {
        height: fit-content;
    }


    .js-hero-carousel {
        height: 100%;

        > div {
            height: 100%;

            > div {
                height: 100%;
            }
        }
    }

    .hero-button {
        position: absolute;
        z-index: 200;
        top: 50%;
        transform: translateY(-100%);
        background-color: rgba(map-get($colours, white),0.5);
        width: 100px;
        height: 150px;
        color: #222;
        border: none;
        cursor: pointer;
    }

    .hero-button:hover {
        background-color: rgba(map-get($colours, red),.1);
    }

    .hero-button.next {
        right: 0;
    }

    .hero-button.prev {
        transform: rotate(180deg) translateY(100%);
    }

    .hero-item {
        width: 100%;
        height: 100%;

        .hero-banner-full-column {
            height: 100%;

            .hero-banner-full-content {
                width: 100%;
                height: 100%;
            }
        }

        .hero-bg-image {
            position: absolute;
            width: 100vw;
            height: 100%;
            top: 0;
            z-index: -1;
        }
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 1.5rem;
    }
}
