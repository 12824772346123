.checkout-steps {
	border-bottom: 1px solid map-get($colours, grey4);

	&__step {
		width: 100%;
		align-items: center;
		display: flex;
		font-weight: 400;
		color: map-get($colours, grey3);

		svg {
			transform: rotateZ(90deg);
		}

		a {
			font-family: $font-family;
			color: inherit;
			font-weight: inherit;
		}

		&.visited {
			color: map-get($colours, black);
		}

		&.active {
			color: map-get($colours, purple);

			a {
				pointer-events: none;
				color: inherit;
			}

			svg {
				fill: map-get($colours, purple);
			}
		}
	}

	&:not(.open) &__step {
		display: none;

		&.active {
			display: flex;
		}
	}

	&.open &__step {
		display: block;

		svg {
			transform: rotateZ(-90deg);
		}
	}

	@include media-breakpoint-up(md) {
		margin: 0;
		margin-bottom: 1.5rem;
		display: flex;
		justify-content: space-between;

		&__step {
			text-align: center;
			font-size: 16px;
			line-height: 20px;
			align-items: center;
			display: flex;
			justify-content: center;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}

			&.active {
				font-weight: 500;
			}
		}

		&:not(.open) &__step {
			height: auto;
			display: flex;
		}

		&.open &__step {
			display: flex;
		}
	}

	@include media-breakpoint-down(xs) {
		&.open {
			width: 100%;
			position: absolute;
			background: map-get($colours, white);
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			border-bottom: none;
			box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
			z-index: 10;
		}
	}
}

.checkout {
	&__accountwithus {
		a {
			font-weight: bold;
		}

		&--wrap {
			border-bottom: 1px solid map-get($colours, grey4);
		}
	}

	&__checkoutasguest {
		&--wrap {
			background: map-get($colours, purple3);
		}
	}

	&__referral_block {
		background: map-get($colours, purple);
		color: map-get($colours, white);

		svg {
			width: 24px;
			height: 24px;
			color: map-get($colours, white);
		}
	}

	@include media-breakpoint-up(md) {
		&__checkoutasguest {
			&--wrap {
				background: none;
			}
		}

		&__accountwithus {
			border-bottom: 1px solid map-get($colours, grey4);

			&--wrap {
				border: none;
			}
		}
	}
}

.stripe-wrapper {
	form {
		width: 30vw;
		min-width: 500px;
		align-self: center;
		box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
		border-radius: 7px;
		padding: 40px;
	}

	input {
		border-radius: 6px;
		margin-bottom: 6px;
		padding: 12px;
		border: 1px solid rgba(50, 50, 93, 0.1);
		height: 44px;
		font-size: 16px;
		width: 100%;
		background: white;
	}

	.result-message {
		line-height: 22px;
		font-size: 16px;
	}

	.result-message a {
		color: rgb(89, 111, 214);
		font-weight: 600;
		text-decoration: none;
	}


	#card-error {
		color: map-get($colours, error-red);
		line-height: 17px;
		margin-top: 12px;
	}

	#card-element {
		border-radius: 4px 4px 0 0;
		padding: 12px;
		border: 1px solid rgba(50, 50, 93, 0.1);
		height: 44px;
		width: 100%;
		background: white;
	}

	#payment-request-button {
		margin-bottom: 32px;
	}

	@media only screen and (max-width: 600px) {
		form {
			width: 80vw;
		}
	}
}


.button {

	&--stripepayment {
		cursor: pointer;
		transition: all 0.2s ease;
		box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
		width: 100%;

		&:hover {
			filter: contrast(115%);
		}

		&:disabled {
			opacity: 0.5;
			cursor: default;
		}

		@-webkit-keyframes loading {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}

			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}

		@keyframes loading {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}

			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
	}
}
