.account-subnav {
    &__item {
        display: block;
        color: map-get($colours, black);
        font-size:21px;
        line-height:31px;
        margin-bottom:1.25rem;

        &:hover, &.active {
            color: map-get($colours, purple2);
        }
    }
}
