.openhouse {
    .button {
        &--accordion-nav {
            background: map-get($colours, red2);
            color: map-get($colours, white);
            border-color: map-get($colours, red2);

            &:hover {
                background: map-get($colours, red2);
                color: map-get($colours, white);
                border-color: map-get($colours, red2);
            }

            &.active {
                background: map-get($colours, red2);
                color: map-get($colours, white);
                border-color: map-get($colours, red2);

                &:hover {
                    background: map-get($colours, red2);
                    color: map-get($colours, white);
                    border-color: map-get($colours, red2);
                }
            }
        }
    }
} 

.button {
    display: block;
    text-decoration: none;
    font-family: $heading-font-family;
    font-size: map-get($font-sizes, button);
    line-height: map-get($line-heights, button);
    background: map-get($colours, purple);
    border: 1px solid map-get($colours, purple);
    color: map-get($colours, white);
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    border-radius: 8px;
    padding: 14px 2rem;

    .openhouse & {
        background: map-get($colours, red2);
        border: 1px solid map-get($colours, red2);
        color: map-get($colours, white);
    }

    &:hover {
        text-decoration: none;
        color: $white;
        background: darken(map-get($colours, purple), 10%);

        .openhouse & {
            background: darken(map-get($colours, red2), 10%);
        }

        svg {
            fill: $white;
        }
    }

    &--white {
        background: map-get($colours, white);
        color: map-get($colours, purple);

        &_alt {
            background: map-get($colours, white);
            color: map-get($colours, purple);

            &:hover {
                color: map-get($colours, white);
                background: map-get($colours, purple);
                border: 1px solid;
            }
        }
    }

    &--nav {
        padding: 6px 40px;
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
    }

    &--mobilenav {
        width: 50%;
        border-radius: 0;
        font-size: 18px;
        font-weight: 400;
        padding: 17px .25rem;
    }

    &--inline {
        display: inline-block;
    }

    &--large {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
            padding: 0 100px;
        }
    }

    &--accordion-nav {
        background: map-get($colours, grey);
        color: map-get($colours, black);
        border-color: map-get($colours, grey);
        text-align: center;
        padding: 13px 2px;
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .2s;

        &:hover {
            background: map-get($colours, purple2);
            color: map-get($colours, white);
            border-color: map-get($colours, purple2);
            transform: scale(1.015);
        }

        &.active {
            background: map-get($colours, purple);
            color: map-get($colours, white);
            border-color: map-get($colours, purple);

            &:hover {
                background: map-get($colours, purple2);
                color: map-get($colours, white);
                border-color: map-get($colours, purple2);
            }
        }

        @include media-breakpoint-up(md) {
            padding: 16px 8px;
            margin-bottom: 12px;
        }
    }

    &.reduced-padding {
        padding: .5rem;

        &-x {
            padding-left: .5rem;
            padding-right: .5rem;
        }

        &-y {
            padding-top: .5rem;
            padding-bottom: .5rem;
        }
    }
}

a.arrow {
    svg {
        transition: all 200ms ease-in-out;
        top: 5px;
        margin-left: 12px;
        position: relative;
    }

    &:hover {
        svg {
            transform: translateX(12px);
        }
    }
}

.hyperlink {
    &--underline {
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }

    &--block{
        display:block;
    }

    &--black {
        color: map-get($colours, black);
        font-family:$font-family;
    }

    &--bolder{
        font-weight:500;
    }
}