section.block-item.offerbanner {
    .row {
        margin-right: 0;
        margin-left: 0;
    }

    a {
        text-decoration: none;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        .container {
            padding: 0;
        }
    }
}
