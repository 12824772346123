﻿/*Desktop only container. This container is full width until the md breakpoint*/
/*after that, it follows the same max-widths as the bootstrap container*/
.responsive-container {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    margin-right: auto;
    margin-left: auto;
    /*responsive-container__column must be added to cols*/

    &__column {
        max-width: 33.75rem;
        margin: 0 auto;
    }

    @include media-breakpoint-up(md) {
        &__column {
            max-width: unset;
        }

        max-width: map-get($container-max-widths, md);
    }

    @include media-breakpoint-up(lg) {
        max-width: map-get($container-max-widths, lg);
    }

    @include media-breakpoint-up(xl) {
        max-width: map-get($container-max-widths, xl);
    }
}

/*block*/
.block {
    padding: 1.25rem;

    h1, .h1, h2, .h2, h3, .h3, h4, .h4,
    h5, .h5, h6, .h6{
        margin-top: 0;
    }

    ul {
        padding: 0;
        margin: 0 0 1.25rem 1em;
    }

    @include media-breakpoint-up(md) {
        padding: 30px;

        ul {
            margin: 0 0 30px 1em;
        }
    }
}

.loading {
    display: none;
    position: relative;
    width: 20px;
    height: 20px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 21px;
        height: 21px;
        border: 3px solid currentColor;
        border-radius: 50%;
        animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: currentColor transparent transparent transparent;

        &:nth-child(1) { animation-delay: -0.45s; }
        &:nth-child(2) { animation-delay: -0.3s; }
        &:nth-child(3) { animation-delay: -0.15s; }
    }
}

.is-loading {
    > * {
        display: none;
    }

    > .loading {
        display: inline-block;
    }
}

@keyframes loading-ring {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}