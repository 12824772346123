.help-card-carousel {
    & > .container {
        overflow: hidden;
    }

    &--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__prev, &__next {
        color: map-get($colours, purple2);
        display: none;

        svg {
            color: map-get($colours, purple2);
            fill: map-get($colours, purple2);
            stroke: map-get($colours, purple2);
        }
    }

    &__prev {
        transform: rotateZ(180deg);
    }
    /*&__next {
        transform: rotateZ(-90deg);
    }*/

    &--carousel {
        height: 100%;
        overflow: visible !important;

        & > div {
            height: 100%;

            & > div {
                height: 100%;
            }
        }

        .card {
            margin: 0 1.3rem;

            &:hover {
                transform: scale(1.015);
            }
        }
    }

    @include media-breakpoint-up(md) {
        min-height: 673px;

        &__prev, &__next {
            display: block;
        }
    }
}
